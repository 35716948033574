import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CachedIcon from '@mui/icons-material/Cached';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import clsx from 'clsx';
import { ConversationItem, LLMResponse } from 'components/molecules';
import Loader from 'components/molecules/loader/Loader';
import { CitationsManager, Message, TagsManager } from 'components/organisms';
import EvaluationsFilterOptions from 'components/organisms/evaluator/filter/EvaluationsFilterOption';
import FAQModal from 'components/organisms/modals/faq-modal/FAQModal';
import { format } from 'date-fns';
import { useDebounce, useFAQStatusService, useRegex } from 'hooks';
import { useUser } from 'hooks/reducers';
import useEvaluationsService from 'hooks/services/evaluations/use-evaluations.service';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RangeKeyDict } from 'react-date-range';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import 'react18-json-view/src/style.css';
import { EvaluationsFilter } from 'types';
import { FAQ } from 'types/models';
import { Tables } from 'types/models/db.type';
import {
  LLMResponse as LLMResponseType,
  Message as MessageType,
  SimilarityScore,
  Tag
} from 'types/models/evaluations.model';

type Tags = Tables<'tags'>;

const EvaluatorPage = (): React.ReactElement => {
  const { debounce } = useDebounce();
  const { user } = useUser();
  const { getConversationMessages, updateLLMResponse, getConversations, simulate } =
    useEvaluationsService();

  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const [responseActive, setResponseActive] = useState<boolean>(false);
  const [selectedConversation, setSelectedConversation] = useState<LLMResponseType>();
  const [conversationMessages, setConversationMessages] = useState<MessageType[]>();
  const [conversationTags, setConversationTags] = useState<Tag[]>();
  const [faq, setFaq] = useState<FAQ>();

  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSimulationLoading, setIsSimulationLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>('');

  const [evaluationsFilter, setEvaluationsFilter] = useState<EvaluationsFilter>({
    tags: searchParams.get('tags') ? searchParams.get('tags')?.split(',') : [],
    score: searchParams.get('score') || 'all',
    inbox: searchParams.get('inbox') || 'all',
    organizationId: user?.user_metadata.organizationId,
    sort: searchParams.get('sort') || 'desc',
    period: searchParams.get('period') ?? undefined,
    startDate: searchParams.get('startDate') ?? undefined,
    endDate: searchParams.get('endDate') ?? undefined,
    reviewed: searchParams.get('reviewed') || 'all',
    page: parseInt(searchParams.get('page') || '0'),
    limit: parseInt(searchParams.get('limit') || '10')
  });

  const { data: evaluationsList } = useQuery(['evaluations', evaluationsFilter], getConversations);
  const { getMany: getFAQStatusList } = useFAQStatusService();
  const { data: faqStatusList } = useQuery('faqStatusList', getFAQStatusList);

  const handleFilterChange = (field: keyof EvaluationsFilter, value: string | string[]) => {
    if (evaluationsList) {
      queryClient.invalidateQueries(['evaluations', evaluationsFilter]);
    }

    if (value === 'all') {
      setSearchParams((params) => {
        params.delete(field);
        return params;
      });
    } else {
      setSearchParams((params) => {
        params.set('page', '1');
        params.set(field, typeof value === 'string' ? value : value.join(','));
        return params;
      });
    }

    setEvaluationsFilter((prevState: EvaluationsFilter) => ({
      ...prevState,
      page: 1,
      [field]: value
    }));
  };

  const handleFilterChangeDebounced = useMemo(
    () =>
      debounce((field: keyof EvaluationsFilter, value: string) => {
        setEvaluationsFilter((prevState) => ({
          ...prevState,
          [field]: value
        }));
      }, 300),
    [debounce]
  );

  const updateLLMResponseMutation = useMutation({
    mutationKey: 'updateLLMResponse',
    mutationFn: (llmResponse: LLMResponseType) => updateLLMResponse(llmResponse),
    onSuccess: (data: void | LLMResponseType[] | undefined) => {
      if (data) {
        queryClient.invalidateQueries(['evaluations', evaluationsFilter]);
      }
    }
  });

  const handleDateRangeChange = useCallback(
    (range: RangeKeyDict | undefined) => {
      if (!range?.selection) {
        setEvaluationsFilter((prevState) => ({
          ...prevState,
          startDate: undefined,
          endDate: undefined
        }));

        setSearchParams((params) => {
          params.delete('startDate');
          params.delete('endDate');
          return params;
        });
      } else {
        const { startDate, endDate } = range.selection;

        if (!startDate || !endDate) {
          return;
        }

        setEvaluationsFilter((prevState) => ({
          ...prevState,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }));
        setSearchParams((params) => {
          params.set('startDate', startDate.toISOString());
          params.set('endDate', endDate.toISOString());
          return params;
        });
      }
    },
    [setSearchParams]
  );

  const handlePeriodChange = useCallback(
    (value: string | undefined) => {
      setEvaluationsFilter((prevState) => ({
        ...prevState,
        period: value
      }));

      if (!value || value === 'all') {
        setSearchParams((params) => {
          params.delete('period');
          return params;
        });
      } else {
        setSearchParams((params) => {
          params.set('period', value);
          return params;
        });
      }
    },
    [setSearchParams]
  );

  const handleConversationChange = useCallback(
    (agentResponseId: string) => {
      try {
        if (!evaluationsList) return;

        setIsLoading(true);
        const conversation = evaluationsList?.data?.find(
          (x) => x.agentResponseId === agentResponseId
        );
        if (!conversation) {
          setSearchParams((params) => {
            params.set('id', '');
            return params;
          });

          setResponseActive(false);
          setSelectedConversation(undefined);
          setIsLoading(false);
          return;
        }

        const getConversationData = async () => {
          const data = await getConversationMessages({
            conversationId: conversation.conversationId
          });

          setConversationMessages(data);
          console.log('messages', data);
          setResponseActive(true);
          setSelectedConversation(conversation);
          console.log('conversation', conversation);
          setConversationTags(conversation.llmResponseJson.tags);
          setFaq({
            link: '',
            additionalInformation: '',
            organizationId: user?.user_metadata.organizationId,
            statusId: faqStatusList ? faqStatusList[0].id : undefined
          } as FAQ);

          setIsLoading(false);
        };

        getConversationData();
      } catch (error) {
        setIsLoading(false);
      }
    },
    [evaluationsList, faqStatusList, user?.user_metadata.organizationId, setSearchParams]
  );

  const handleScoreChange = useCallback(
    (score: SimilarityScore) => {
      if (!selectedConversation) return;

      const responseToUpdate: LLMResponseType = {
        conversationId: selectedConversation?.conversationId,
        organizationId: selectedConversation?.organizationId,
        customerMessageId: selectedConversation?.customerMessageId,
        agentResponseId: selectedConversation?.agentResponseId,
        isAutomaticResponse: selectedConversation?.isAutomaticResponse,
        llmResponseJson: selectedConversation?.llmResponseJson,
        similarityScore: score === 'no-score' ? 'no-score' : score,
        customerMessageDate: selectedConversation?.customerMessageDate,
        llmResponseCreatedAt: selectedConversation?.llmResponseCreatedAt,
        createdAt: selectedConversation?.createdAt
      };

      updateLLMResponseMutation.mutate({
        ...responseToUpdate
      });

      setSelectedConversation((prevValue) => ({
        ...prevValue!,
        similarityScore: responseToUpdate.similarityScore
      }));
      setResponseActive(true);
    },
    [selectedConversation, updateLLMResponseMutation]
  );

  const handleResponseToggle = () => {
    setResponseActive(!responseActive);
  };

  const handleTagUpdated = useCallback(
    (updatedTag: Tags) => {
      if (!selectedConversation) return;

      const updatedTags = selectedConversation.llmResponseJson.tags.map((item) => {
        if (item.id === updatedTag.id) {
          const updated: Tag = {
            id: item.id,
            name: updatedTag.name ?? item.name,
            slug: updatedTag.slug ?? item.slug,
            tier: updatedTag.tier ?? item.tier,
            examples: updatedTag.examples ?? item.examples,
            llmName: item.llmName,
            description: updatedTag.description ?? item.description,
            userStatus: updatedTag.user_status ?? item.userStatus,
            priorityOrder: updatedTag.priority_order ?? item.priorityOrder,
            organizationId: updatedTag.organization_id ?? item.organizationId,
            showDraftAuto: updatedTag.show_draft_auto ?? item.showDraftAuto,
            canBeDeflected: updatedTag.can_be_deflected ?? item.canBeDeflected,
            isMutuallyExclusive: updatedTag.is_mutually_exclusive ?? item.isMutuallyExclusive,
            llmAdditionalInstructions:
              updatedTag.llm_additional_instructions ?? item.llmAdditionalInstructions
          };
          return updated;
        }

        return item;
      });

      const llmResponseJsonUpdated = {
        ...selectedConversation.llmResponseJson,
        tags: updatedTags
      };

      const responseToUpdate: LLMResponseType = {
        conversationId: selectedConversation?.conversationId,
        organizationId: selectedConversation?.organizationId,
        customerMessageId: selectedConversation?.customerMessageId,
        agentResponseId: selectedConversation?.agentResponseId,
        isAutomaticResponse: selectedConversation?.isAutomaticResponse,
        similarityScore: selectedConversation.similarityScore,
        customerMessageDate: selectedConversation?.customerMessageDate,
        llmResponseCreatedAt: selectedConversation?.llmResponseCreatedAt,
        createdAt: selectedConversation?.createdAt,
        llmResponseJson: llmResponseJsonUpdated
      };

      updateLLMResponseMutation.mutate({
        ...responseToUpdate
      });

      setConversationTags(updatedTags);
      setResponseActive(true);
    },
    [selectedConversation, updateLLMResponseMutation]
  );

  const handleTagCreated = useCallback(
    (createdTag: Tags) => {
      if (!selectedConversation || !createdTag) return;

      const newTag: Tag = {
        id: createdTag.id,
        name: createdTag.name!,
        slug: createdTag.slug!,
        tier: createdTag.tier!,
        examples: createdTag.examples!,
        llmName: '',
        description: createdTag.description!,
        userStatus: createdTag.user_status!,
        priorityOrder: createdTag.priority_order!,
        organizationId: createdTag.organization_id!,
        showDraftAuto: createdTag.show_draft_auto!,
        canBeDeflected: createdTag.can_be_deflected!,
        isMutuallyExclusive: createdTag.is_mutually_exclusive!,
        llmAdditionalInstructions: createdTag.llm_additional_instructions!
      };

      const updatedTags = [...selectedConversation.llmResponseJson.tags, newTag];

      const llmResponseJsonUpdated = {
        ...selectedConversation.llmResponseJson,
        tags: updatedTags
      };

      const responseToUpdate: LLMResponseType = {
        conversationId: selectedConversation?.conversationId,
        organizationId: selectedConversation?.organizationId,
        customerMessageId: selectedConversation?.customerMessageId,
        agentResponseId: selectedConversation?.agentResponseId,
        isAutomaticResponse: selectedConversation?.isAutomaticResponse,
        similarityScore: selectedConversation.similarityScore,
        customerMessageDate: selectedConversation?.customerMessageDate,
        llmResponseCreatedAt: selectedConversation?.llmResponseCreatedAt,
        createdAt: selectedConversation?.createdAt,
        llmResponseJson: llmResponseJsonUpdated
      };

      updateLLMResponseMutation.mutate({
        ...responseToUpdate
      });

      setConversationTags(updatedTags);
      setResponseActive(true);
    },
    [selectedConversation, updateLLMResponseMutation]
  );

  const handleSimulate = useCallback(async () => {
    const lastCustomerMessage =
      conversationMessages && conversationMessages[conversationMessages?.length - 1];

    if (lastCustomerMessage && selectedConversation) {
      setIsSimulationLoading(true);
      const simulatedResponse = await simulate({
        conversationId: selectedConversation?.conversationId,
        lastCustomerMessageId: lastCustomerMessage?.messageId
      });

      if (simulatedResponse) {
        const llmResponseJson = selectedConversation.llmResponseJson;
        const updatedLlmResponseJson = {
          ...llmResponseJson,
          tags: simulatedResponse.llm_response_json.tags,
          text: simulatedResponse.llm_response_json.text
        };

        setSelectedConversation((prevValue) => ({
          ...prevValue!!,
          llmResponseJson: updatedLlmResponseJson
        }));
      }

      console.log('simulated response', simulatedResponse);
      setIsSimulationLoading(false);
    }
  }, [simulate, conversationMessages, selectedConversation]);

  useEffect(() => {
    if (!evaluationsList) {
      return;
    }

    const conversationId = searchParams.get('id');
    const firstConversation = evaluationsList?.data && evaluationsList?.data[0];

    if (!conversationId && firstConversation) {
      setSearchParams((params) => {
        params.set('id', firstConversation.agentResponseId?.toString() ?? '');
        return params;
      });

      handleConversationChange(firstConversation.agentResponseId);
    } else {
      handleConversationChange(searchParams.get('id')!);
    }
  }, [evaluationsList, handleConversationChange, searchParams, setSearchParams]);

  return (
    <Box>
      <Grid container className="h-full gap-3">
        <Grid item xs={2.5} className="flex flex-col">
          <Paper className="p-0 min-w-[17.5rem] h-[calc(100vh_-_2rem)]">
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                gap: 0,
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                maxHeight: '100vh',
                bgcolor: 'background.paper',
                borderRadius: '1rem'
              }}
            >
              <ListSubheader className="flex pt-5 pl-5 pr-5 pb-2 ">
                <Stack className="w-full">
                  <Stack direction="row" className="w-full items-center justify-between">
                    <Typography variant="h5">Agent Responses</Typography>
                    <EvaluationsFilterOptions
                      filter={evaluationsFilter}
                      handleFilterChange={handleFilterChange}
                      handleDateChange={handleDateRangeChange}
                      handlePeriodChange={handlePeriodChange}
                    />
                  </Stack>
                  <OutlinedInput
                    startAdornment={<SearchIcon color="disabled" />}
                    className="mt-3"
                    placeholder="Search by ID or text"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      setSearchParams((params) => {
                        params.set('text', e.target.value);
                        return params;
                      });
                      handleFilterChangeDebounced('text', e.target.value);
                    }}
                  />
                  <Pagination
                    page={evaluationsFilter.page || 1}
                    count={Math.ceil(evaluationsList?.count! / evaluationsFilter?.limit!)}
                    onChange={(e, value) => {
                      setSearchParams((params) => {
                        params.set('page', value.toString());
                        return params;
                      });
                      setEvaluationsFilter({ ...evaluationsFilter, page: value });
                    }}
                    color="primary"
                    className="mt-2"
                    size="small"
                  />
                </Stack>
              </ListSubheader>

              {evaluationsList?.data?.map((item) => (
                <ConversationItem
                  {...item}
                  key={item.agentResponseId}
                  active={Boolean(item.agentResponseId?.toString() === searchParams.get('id'))}
                  onClick={() => {
                    setSearchParams((params) => {
                      params.set('id', item.agentResponseId);
                      return params;
                    });
                    handleConversationChange(item.agentResponseId);
                  }}
                />
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={9.2} className="flex flex-col h-[calc(100vh_-_2rem)]">
          <Card className="flex flex-col h-full pl-0 pr-0">
            <Typography variant="h5" className="ml-5">
              Conversation
            </Typography>
            <Typography variant="body2" className="ml-5 text-gray8">
              {format(
                selectedConversation?.customerMessageDate
                  ? new Date(selectedConversation.customerMessageDate.toString())
                  : new Date(),
                'dd MMM, yyyy'
              )}
            </Typography>
            <Divider className="ml-[-1.5rem] mr-[-1.5rem] mt-3" />
            <CardContent className="flex overflow-auto h-full">
              <Stack
                direction="column"
                className="flex flex-1 border-r-1 border-t-0 border-b-0 border-l-0 border-solid border-gray10 pr-5 mr-2"
              >
                {conversationMessages?.map((message, idx) => (
                  <>
                    <Message
                      key={message.messageId}
                      url={message.messageUrl}
                      type={message.messageAuthorType as 'COMPANY_AGENT' | 'CUSTOMER'}
                      sender={message.messageAuthorHandle}
                      timestamp={message.messageDate}
                      text={message.text}
                    />
                    {selectedConversation &&
                      selectedConversation.customerMessageId === message.messageId && (
                        <Box className="w-full mt-3 pl-12">
                          <LLMResponse
                            similarity={
                              selectedConversation.similarityScore as 'high' | 'medium' | 'low'
                            }
                            used={true}
                            text={selectedConversation.llmResponseJson.text}
                            timestamp={selectedConversation.customerMessageDate}
                            onClick={handleResponseToggle}
                            loading={isSimulationLoading}
                          />
                        </Box>
                      )}
                  </>
                ))}
              </Stack>
              {responseActive && (
                <Stack direction="column" className="h-full mt-[-1.5rem] flex w-[18.75rem]">
                  <Stack className="h-full pt-6 pr-0 pb-6 pl-4">
                    <Stack direction="row" className="w-full justify-between items-center">
                      <Typography variant="subtitle1">LLM Response Settings</Typography>
                      <IconButton onClick={handleResponseToggle}>
                        <ClearIcon />
                      </IconButton>
                    </Stack>
                    <Divider className="ml-[-1.5 rem] mr-[-1rem] mb-5 mt-5" />
                    <Stack direction="row" className="flex items-center">
                      <Typography variant="subtitle1" className="mr-2">
                        Score
                      </Typography>
                      {/* TODO: review implementation of "Used" state */}
                      <Chip
                        className={clsx(
                          true && 'bg-green4 text-green5',
                          false && 'bg-pink4 text-red3'
                        )}
                        label={true ? 'Used' : 'Not used'}
                      />
                    </Stack>
                    <Select
                      placeholder="Select"
                      value={selectedConversation?.similarityScore ?? 'no-score'}
                      displayEmpty
                      onChange={(e) => handleScoreChange(e.target.value as SimilarityScore)}
                      className="w-full mt-3"
                    >
                      {Object.keys(SimilarityScore).map((key) => (
                        <MenuItem
                          key={key}
                          value={SimilarityScore[key as keyof typeof SimilarityScore]}
                        >
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <Divider className="ml-[-.5rem] mr-[-1rem] mb-5" />
                  <TagsManager
                    initialTags={conversationTags}
                    onTagUpdated={handleTagUpdated}
                    onTagCreated={handleTagCreated}
                    loading={isSimulationLoading}
                  />
                  <Divider className="ml-[-.5rem] mr-[-1rem] mb-5" />
                  <CitationsManager citations={selectedConversation?.llmResponseJson.citations} />
                  <Divider className="ml-[-.5rem] mr-[-1rem] mb-5" />
                  <Stack direction="column" className="flex items-start p-4">
                    <Typography variant="subtitle1" className="mr-2 mb-2">
                      Internal Notes
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={8}
                      value={''}
                      placeholder={'Add notes'}
                      onChange={() => {}}
                    />
                  </Stack>
                  <Stack direction="row" className="flex justify-between p-4">
                    <Button
                      variant="outlined"
                      color="inherit"
                      startIcon={<AddCircleOutlineIcon />}
                      className="capitalize bg-white text-black p-4"
                      size="small"
                      onClick={() => setShowDialog((prevValue) => !prevValue)}
                    >
                      Add FAQ
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<CachedIcon />}
                      className="capitalize p-4"
                      size="small"
                      onClick={handleSimulate}
                    >
                      Simulate
                    </Button>
                  </Stack>
                </Stack>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Loader isLoading={isLoading} />
      </Grid>
      {showDialog && <FAQModal isOpen={showDialog} setIsOpen={setShowDialog} currentFAQ={faq} />}
    </Box>
  );
};

export default EvaluatorPage;
