import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useCallback } from 'react';
import { Category } from 'types/models';
import { Tables } from 'types/models/db.type';
import { APIService } from 'types/services';
import { BaseAdapter } from '../adapters';
import { QueryFunctionContext } from 'react-query';
import { useToast } from 'contexts/ToastContext';

type CategoryService = Omit<APIService<Category>, 'get'> & {
  deleteCategory: (id: number) => Promise<void>;
};

type GetCategoriesParams = QueryFunctionContext<['qnas', { organizationId: string }]>;

const useCategoriesService = (): CategoryService => {
  const { adapt, reverse } = BaseAdapter<Tables<'categories'>, Category>();

  const { showToast } = useToast();

  const { CATEGORY_TABLE } = KBB_SCHEMA;

  const getMany = useCallback(
    async (params: GetCategoriesParams) => {
      const [, organizationId] = params.queryKey;

      if (!organizationId) return;

      let query = supabaseClient
        .from(CATEGORY_TABLE)
        .select('*, categories(*)')
        .is('parent_id', null)
        .eq('organization_id', organizationId);

      const { data, error } = await query.returns<Tables<'categories'>[]>();

      if (error) console.log(error);

      const flattenCategories = data
        ?.map((item) => adapt(item))
        .reduce((acc: Category[], category: Category) => {
          if (category.categories) {
            acc.push(category);
            acc.push(
              ...category.categories.map((subCategory) => ({
                ...subCategory,
                parentName: category.category
              }))
            );
          }
          return acc;
        }, []);

      return flattenCategories;
    },
    [adapt, CATEGORY_TABLE]
  );

  const post = useCallback(
    async (data: any) => {
      try {
        if (!data.organizationId) return;
        if (data.parentId === null) delete data.parentId;
        delete data.parentName;
        delete data.id;
        const categoryData = reverse(data);
        const { data: postData, error } = await supabaseClient
          .from(CATEGORY_TABLE)
          .insert([categoryData])
          .single();

        console.log(postData);
        if (error) throw new Error('Error inserting category');
        showToast('Category inserted successfully', 'success');
      } catch (error) {
        showToast('Error inserting category', 'error');
        console.error(error);
      }
    },
    [CATEGORY_TABLE, reverse, showToast]
  );

  const deleteCategory = useCallback(
    async (id: number) => {
      try {
        await supabaseClient.from(CATEGORY_TABLE).delete().eq('parent_id', id);
        const { error } = await supabaseClient.from(CATEGORY_TABLE).delete().eq('id', id);

        if (error) throw new Error('Error deleting category');
        showToast('Category deleted successfully', 'success');
      } catch (error) {
        showToast('Error deleting category', 'error');
        console.error(error);
      }
    },
    [CATEGORY_TABLE, showToast]
  );

  const put = useCallback(
    async (data: any) => {
      try {
        if (data.parentId === null) delete data.parentId;
        delete data.parentName;
        const categoryData = reverse(data);
        const { error } = await supabaseClient
          .from(CATEGORY_TABLE)
          .update([categoryData])
          .eq('id', data.id)
          .single();

        if (error) throw new Error('Error updating category');
        showToast('Category updated successfully', 'success');
      } catch (error) {
        showToast('Error updating with category', 'error');
        console.error(error);
      }
    },
    [CATEGORY_TABLE, reverse, showToast]
  );

  return { getMany, post, put, deleteCategory };
};

export default useCategoriesService;
