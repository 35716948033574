import { Box, Divider, List, ListSubheader } from '@mui/material';
import NavItem from './NavItem';
import { NavItemProps } from './NavItem.props';
import VerticalNavProps from './VerticalNav.props';
import { useUser } from 'hooks/reducers';

export default function VerticalNav({
  navConfig,
  expanded = true,
  bottomItems,
  ...other
}: Readonly<VerticalNavProps>) {
  const { user } = useUser();

  return (
    <Box {...other} data-testid="vertical-nav">
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {!expanded && (
              <ListSubheader className="font-semibold text-black h-9">{subheader}</ListSubheader>
            )}
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} isShow={expanded} />
            ))}
          </List>
        );
      })}
      <Box className="absolute bottom-0 w-full">
        {user && !expanded && (
          <>
            <Divider />
            <p className="text-sm h-3 text-gray w-full flex justify-start items-center ml-7">
              {`${user?.user_metadata?.organizations?.name}`}
            </p>
          </>
        )}
        {bottomItems?.map((item, idx) => (
          <div key={idx}>
            <Divider />
            <NavItem key={item.title} item={item} isShow={expanded} />
          </div>
        ))}
      </Box>
    </Box>
  );
}
