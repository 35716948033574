import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Drawer, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Paragraph } from 'components/atoms';
import { CategorySelect, StatusSelect } from 'components/molecules';
import { CollapsibleList } from 'components/organisms';
import { format, formatDistanceToNow } from 'date-fns';
import { useFAQService } from 'hooks';
import { useUser } from 'hooks/reducers';
import { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Markdown from 'react-markdown';
import { useMutation } from 'react-query';
import { FAQ } from 'types/models';
import FAQModalProps from './FAQModal.props';

const FAQModal = ({ isOpen, setIsOpen, currentFAQ, onAfterSubmitted }: FAQModalProps) => {
  const editMode = currentFAQ?.id !== undefined;
  const { user } = useUser();
  const { post, put } = useFAQService();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FAQ>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      id: currentFAQ?.id ?? undefined,
      authorEmail: currentFAQ?.authorEmail ?? '',
      source: currentFAQ?.source ?? '',
      createdAt: currentFAQ?.createdAt ?? new Date().toISOString(),
      statusId: currentFAQ?.statusId ?? undefined,
      categoryId: currentFAQ?.categoryId ?? undefined,
      question: currentFAQ?.question ?? '',
      answer: currentFAQ?.answer ?? '',
      link: currentFAQ?.link ?? '',
      additionalInformation: currentFAQ?.additionalInformation ?? '',
      organizationId: user?.user_metadata.organizationId
    }
  });

  const clearForm = useCallback(() => {
    reset();
  }, [reset]);

  const handleShowDialog = useCallback(() => {
    clearForm();
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen, clearForm]);

  const onSubmitted = useCallback(() => {
    handleShowDialog();
    if (currentFAQ) {
      clearForm();
    }
  }, [handleShowDialog, currentFAQ, clearForm]);

  const similarFAQs: Pick<FAQ, 'question' | 'answer'>[] = [
    {
      question: 'Similar FAQ 1',
      answer: 'answer of Similar FAQ 1'
    },
    {
      question: 'Similar FAQ 2',
      answer: 'answer of Similar FAQ 2'
    },
    {
      question: 'Similar FAQ 3',
      answer: 'answer of Similar FAQ 3'
    }
  ];

  const postMutation = useMutation({
    mutationKey: 'postFAQ',
    mutationFn: (qna: FAQ) => post(qna),
    onSuccess: (data: void | FAQ[] | undefined) => {
      reset();
      onSubmitted();
      onAfterSubmitted && onAfterSubmitted();
    }
  });

  const editMutation = useMutation({
    mutationKey: 'editFAQ',
    mutationFn: (qna: FAQ) => put(qna),
    onSuccess: (data: void | FAQ[] | undefined) => {
      if (data) {
        reset(data[0]);
        onSubmitted();
        onAfterSubmitted && onAfterSubmitted();
      }
    }
  });

  const onSubmit: SubmitHandler<FAQ> = useCallback(
    (data) => {
      try {
        const formData = { ...data };
        if (editMode) {
          editMutation.mutate(formData);
        } else {
          postMutation.mutate(formData);
        }
        handleShowDialog();
      } catch (error) {
        console.error(error);
      }
    },
    [editMode, editMutation, postMutation]
  );

  return (
    <Drawer
      className="z-[1000]"
      anchor="right"
      open={isOpen}
      onClose={() => {
        handleSubmit(onSubmit)();
      }}
      PaperProps={{ className: 'min-w-[30rem] rounded-none', style: { maxWidth: '30%' } }}
      sx={{ img: { maxWidth: '100%' } }} // resize markdown images
    >
      <Stack className="p-4">
        <Grid container className="w-full">
          <Grid item xs={6}>
            <Typography variant="h5" className="font-semibold">
              FAQ
            </Typography>
          </Grid>
          <Grid item xs={6} className="text-right">
            <IconButton
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container gap="1rem">
            <Grid item xs={12} className="flex justify-end gap-4">
              <Grid item xs={3} className="flex justify-start">
                {editMode && (
                  <Stack>
                    <Typography variant="body1" className="text-gray">
                      {formatDistanceToNow(new Date(currentFAQ?.createdAt ?? new Date()), {
                        addSuffix: true
                      })}
                    </Typography>
                    <Typography variant="body1" className="text-gray">
                      From {currentFAQ?.source}
                    </Typography>
                    <Typography variant="body1" className="text-gray">
                      By {currentFAQ?.authorEmail}
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Controller
                  name="statusId"
                  control={control}
                  rules={{
                    required: true
                  }}
                  render={({ field }) => (
                    <StatusSelect
                      variant="outlined"
                      fullWidth
                      allowEmptyValue={!editMode}
                      showLabel
                      {...field}
                    />
                  )}
                />
                {errors.statusId && <span className="text-red2">Status is required</span>}
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="categoryId"
                  control={control}
                  rules={{
                    required: false
                  }}
                  render={({ field }) => (
                    <CategorySelect
                      variant="outlined"
                      fullWidth
                      allowEmptyValue
                      emptyValue="None"
                      showLabel
                      {...field}
                    />
                  )}
                />
                {errors.categoryId && <span className="text-red2">Category is required</span>}
              </Grid>
            </Grid>

            {!editMode && (
              <Grid item xs={12}>
                <Controller
                  name="createdAt"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <TextField
                      fullWidth
                      name={name}
                      value={value ? format(new Date(value), 'MMMM do, yyyy') : ''}
                      placeholder={'Creation Date'}
                      disabled={editMode}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />

                {errors.source && <span className="text-red2">Source is required</span>}
              </Grid>
            )}
            {!editMode && (
              <Grid item xs={12}>
                <Controller
                  name="source"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <TextField
                      fullWidth
                      disabled={editMode}
                      name={name}
                      value={value || ''}
                      placeholder={'Source'}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />

                {errors.source && <span className="text-red2">Source is required</span>}
              </Grid>
            )}
            {!editMode && (
              <Grid item xs={12}>
                <Controller
                  name="authorEmail"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <TextField
                      fullWidth
                      name={name}
                      disabled={editMode}
                      value={value || ''}
                      placeholder={"Author's Email"}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />

                {errors.authorEmail && (
                  <span className="text-red2">Author's Email is required</span>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="question"
                control={control}
                rules={{ required: true }}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={8}
                    name={name}
                    value={value || ''}
                    placeholder={'Question'}
                    onChange={onChange}
                    ref={ref}
                  />
                )}
              />

              {errors.question && <span className="text-red2">Question is required</span>}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="answer"
                control={control}
                rules={{ required: true }}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={8}
                    name={name}
                    value={value || ''}
                    placeholder={'Answer'}
                    onChange={onChange}
                    ref={ref}
                  />
                )}
              />
              {errors.answer && <span className="text-red2">Answer is required</span>}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="link"
                control={control}
                rules={{ required: false }}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    fullWidth
                    placeholder="Link (optional)"
                    disabled={editMode}
                    name={name}
                    value={value || ''}
                    onChange={onChange}
                    ref={ref}
                    InputProps={{
                      endAdornment: editMode && value && value !== 'direct_message' && (
                        <IconButton
                          onClick={() => {
                            value && window.open(value, '_blank');
                          }}
                        >
                          <LinkIcon className="cursor-pointer" />
                        </IconButton>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {editMode ? (
                <Box className="h-[30vh] overflow-y-scroll border border-solid border-gray3 rounded-lg p-4">
                  <Markdown components={{ p: Paragraph }}>
                    {currentFAQ?.additionalInformation}
                  </Markdown>
                </Box>
              ) : (
                <Controller
                  name="additionalInformation"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <TextField
                      fullWidth
                      multiline
                      rows={16}
                      placeholder="Additional Context (optional)"
                      name={name}
                      value={value || ''}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />
              )}
            </Grid>

            {editMode && (
              <Grid item xs={12}>
                <CollapsibleList
                  items={similarFAQs.map((item) => ({
                    title: item.question ?? '',
                    description: item.answer ?? ''
                  }))}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </Stack>
    </Drawer>
  );
};

export default FAQModal;
