import { Theme } from '@mui/material/styles';
import { baseColors } from 'theme/palette';

const Button = (theme: Theme) => {
  const overrides = {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: '.5rem',
          fontSize: '.875rem!important',
          fontWeight: 700,
          height: '2rem',
          lineHeight: '24px',
          textAlign: 'center',
          textTransform: 'capitalize'
        },
        outlinedInherit: {
          borderColor: baseColors.gray14
        }
      }
    }
  };

  return overrides;
};

export default Button;
