import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { CollapseDrawerProvider } from 'contexts/CollapseDrawerContext';
import React from 'react';
import ReactDOM from 'react-dom/client';
import createDefaultTheme from 'theme';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { UserContextProvider } from 'contexts/UserContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfirmationDialogProvider } from 'contexts/ConfirmationDialogContext';
import { ContentDialogProvider } from 'contexts/ContentDialogContext';
import { ToastContainer } from 'react-toastify';
import { ToastProvider } from 'contexts/ToastContext';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

const cache = createCache({
  key: 'css',
  prepend: true
});

const defaultTheme = createDefaultTheme(rootElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={cache}>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <ThemeProvider theme={defaultTheme}>
              <ContentDialogProvider>
                <ConfirmationDialogProvider>
                  <CollapseDrawerProvider>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                    <UserContextProvider>
                      <ToastProvider>
                        <App />
                        <ToastContainer />
                      </ToastProvider>
                    </UserContextProvider>
                    {/* </LocalizationProvider> */}
                  </CollapseDrawerProvider>
                </ConfirmationDialogProvider>
              </ContentDialogProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </CacheProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
