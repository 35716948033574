import { Add, Delete, EditOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Badge,
  Button,
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  TextField
} from '@mui/material';
import { InnerPageTemplate } from 'components/templates';
import { useCategoriesService } from 'hooks';
import { useUser } from 'hooks/reducers';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Category } from 'types/models';
import { useConfirmationDialog } from 'hooks';

const CategoriesPage = () => {
  const { user } = useUser();
  const [categories, setCategories] = useState<Category[]>([]);
  const [openCategories, setOpenCategories] = useState<{ [key: string]: boolean }>({});
  const [selectedCategory, setSelectedCategory] = useState<{
    id?: number | null;
    parentId: number | null;
    parentName: string | null;
    category: string;
    description?: string;
  }>({
    id: null,
    parentId: null,
    parentName: null,
    category: '',
    description: ''
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { openDialog } = useConfirmationDialog();

  const handleClick = (categoryId: string) => {
    setOpenCategories((prevOpenCategories) => ({
      ...prevOpenCategories,
      [categoryId]: !prevOpenCategories[categoryId]
    }));
  };
  const {
    getMany: getCategories,
    post: postCategory,
    deleteCategory,
    put
  } = useCategoriesService();

  const onAddCategory = (categoryId: number, category: string) => {
    setSelectedCategory((prev) => ({
      ...prev,
      parentId: categoryId,
      parentName: category
    }));
    setIsDrawerOpen(true);
  };

  const query = useQuery(['categories', user?.user_metadata.organizationId], getCategories);
  useEffect(() => {
    if (!query.isLoading && query.data?.length) {
      setCategories(query.data);
    }
  }, [query]);

  const onCategorySubmit = (e: any) => {
    e.preventDefault();
    if (selectedCategory.id) {
      put(selectedCategory as Category).then(() => query.refetch());
      onDrawerClose();
      return;
    }
    postCategory({
      ...selectedCategory,
      organizationId: user?.user_metadata.organizationId
    } as Category).then(() => {
      query.refetch();
    });
    onDrawerClose();
  };

  const organizeData = (data: Category[]): { [key: number]: Category[] } => {
    const organized: { [key: number]: Category[] } = {};

    data.forEach((item) => {
      if (item.parentId === null) {
        organized[item.id!] = [];
      }
      if (!organized[item.parentId!]) {
        organized[item.parentId!] = [];
      }
      organized[item.parentId!].push(item);
    });

    return organized;
  };

  const handleDelete = useCallback(
    (id: string, isSubCategory?: boolean) => {
      openDialog(
        'Delete Category',
        `Are you sure to delete this Category? ${
          isSubCategory
            ? 'Deleting a subcategory can imply on changes'
            : 'Deleting a category implies in deliting also all subcategories'
        }`,
        () => deleteCategory(parseInt(id)).then(() => query.refetch())
      );
    },
    [deleteCategory, openDialog, query]
  );

  const onEditClick = (id: string) => {
    const category = categories.find((item) => item.id === parseInt(id));
    setSelectedCategory({
      id: category?.id,
      parentId: category?.parentId ?? null,
      parentName: category?.parentName ?? 'root',
      category: category?.category ?? '',
      description: category?.description ?? ''
    });
    setIsDrawerOpen(true);
  };

  const getBadge = (id: string) => {
    const category = categories.find((item) => item.id === parseInt(id));
    return (
      <Badge
        badgeContent={categoriesData[parseInt(id)]?.length || '0'}
        slotProps={{
          badge: { className: 'bg-gray6 text-gray7 mt-[10px] mr-[-1.5rem]' }
        }}
        color="primary"
      >
        {category?.category}
      </Badge>
    );
  };

  const renderCategory = (categoryId: string) => {
    const category = categories.find((item) => item.id === parseInt(categoryId));
    if (!category) return;

    return (
      <div key={category.id} className="bg-white rounded">
        <ListItemButton className="flex items-center gap-8 cursor-default">
          <div className="cursor-pointer" onClick={() => handleClick(categoryId)}>
            {openCategories[categoryId] ? <ExpandLess /> : <ExpandMore />}
          </div>
          <ListItemText className="w-1" primary={getBadge(categoryId)} />
          <ListItemText className="w-96" primary={category.description} />
          <div className="flex gap-2">
            <Add
              className="hover:text-orange text-gray cursor-pointer"
              fontSize="medium"
              onClick={() => onAddCategory(category.id!, category.category!)}
            />
            <EditOutlined
              className="hover:text-orange text-gray cursor-pointer"
              fontSize="medium"
              onClick={() => onEditClick(category.id!.toString())}
            />
            <Delete
              className="hover:text-orange text-gray cursor-pointer"
              fontSize="medium"
              onClick={() =>
                handleDelete(category.id!.toString(), selectedCategory.parentId !== null)
              }
            />
          </div>
        </ListItemButton>
        <Collapse in={openCategories[categoryId]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {categoriesData[parseInt(categoryId)]?.map((child) => (
              <ListItemButton key={child.id} className="ml-16 cursor-default">
                {child.children && renderCategory(child.id!.toString())}
                <ListItemText primary={child.category} className="w-1" />
                <ListItemText className="w-96 pl-4" primary={child.description} />
                <div className="flex gap-2 self">
                  <EditOutlined
                    className="hover:text-orange text-gray cursor-pointer"
                    fontSize="medium"
                    onClick={() => onEditClick(child.id!.toString())}
                  />
                  <Delete
                    className="hover:text-orange text-gray cursor-pointer"
                    fontSize="medium"
                    onClick={() => handleDelete(child.id!.toString(), true)}
                  />
                </div>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </div>
    );
  };

  const categoriesData = organizeData(categories);

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedCategory({
      id: null,
      parentId: null,
      parentName: 'root',
      category: '',
      description: ''
    });
  };

  return (
    <InnerPageTemplate header="Categories">
      <div className="max-h-[90vh] overflow-auto flex flex-col gap-4 px-16">
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="flex self-end w-32 "
          onClick={() => setIsDrawerOpen(true)}
        >
          Add Category
        </Button>
        <List className="flex flex-col ">
          {Object.keys(categoriesData).map((key) => renderCategory(key))}
        </List>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={onDrawerClose}
        PaperProps={{ className: 'w-[30rem] p-4', style: { maxWidth: '20%' } }}
        sx={{ img: { maxWidth: '100%' } }}
      >
        <div className="flex flex-col gap-4">
          <h1 className="text-xl font-bold">Add Category</h1>
          <form onSubmit={(e) => onCategorySubmit(e)}>
            <div className="flex flex-col gap-4">
              {selectedCategory.parentName && selectedCategory.parentName !== 'root' && (
                <TextField
                  id="parentName"
                  disabled
                  variant="outlined"
                  label="Parent Category"
                  value={selectedCategory?.parentName}
                  required
                  className="w-full"
                />
              )}
              <TextField
                id="category"
                label="Category Name"
                variant="outlined"
                required
                className="w-full"
                value={selectedCategory.category ?? ''}
                onChange={(e) =>
                  setSelectedCategory({ ...selectedCategory, category: e.target.value })
                }
              />
              <TextField
                id="description"
                label="Description"
                variant="outlined"
                value={selectedCategory.description ?? ''}
                className="w-full"
                onChange={(e) =>
                  setSelectedCategory({ ...selectedCategory, description: e.target.value })
                }
              />
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Drawer>
    </InnerPageTemplate>
  );
};

export default CategoriesPage;
