import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { supabaseClient } from 'api/supabaseClient';
import { LogoIcon } from 'components/atoms';
import { UserActionTypes } from 'contexts/reducers';
import { useUserDispatch } from 'hooks/reducers';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ROUTE_PATHS from 'routes/paths';

const EmailConfirmationPage = () => {
  const search = useLocation().hash;
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const [tokenDetected, setTokenDetected] = useState<boolean>(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  useEffect(() => {
    const executeSignUpFlow = async () => {
      const { data } = await supabaseClient.auth.getSession();
      if (!data.session) return;

      const response = await supabaseClient.functions.invoke('sign-up-flow', {
        body: {
          userId: data.session?.user?.id,
          email: data.session?.user?.email
        }
      });

      if (response.data?.statusCode === 200) {
        const redirectUrl = response?.data?.data?.organization?.signup_redirect_url;
        if (redirectUrl && redirectUrl !== '') {
          window.location.href = redirectUrl;
        } else {
          setEmailConfirmed(true);
        }
      } else {
        setInvalidEmail(true);
      }
    };

    tokenDetected && executeSignUpFlow();
  }, [tokenDetected, dispatch]);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    if (urlParams.has('#access_token')) {
      setTokenDetected(true);
    } else {
      // navigate(ROUTE_PATHS.AUTH);
    }
  }, [navigate, search]);

  return (
    <Container component="main" maxWidth="lg">
      <Grid container>
        <Grid item xs={12} md={4} lg={4}></Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Grid container>
            <Box className="w-full text-center mt-[25vh]">
              <LogoIcon sx={{ width: '10rem', height: '3rem' }} />
            </Box>
            {!emailConfirmed && (
              <Grid xs={12} className="flex justify-center">
                <CircularProgress />
              </Grid>
            )}
            <Grid xs={12} className="flex justify-center">
              {emailConfirmed && (
                <Grid container>
                  <Grid xs={12} className="flex justify-center">
                    <Typography variant="h1" className="font-manrope text-black text-2xl mt-4">
                      Congratulations!!
                    </Typography>
                  </Grid>
                  <Grid xs={12} className="flex justify-center">
                    <Typography variant="body1" className="mt-4 mb-4">
                      Your Prosper account has been created successfully
                    </Typography>
                  </Grid>
                  <Grid xs={12} className="flex justify-center">
                    <Link to="/auth" className="mt-4 mb-4">
                      Sign in
                    </Link>
                  </Grid>
                </Grid>
              )}

              {invalidEmail && (
                <Grid container>
                  <Grid xs={12} className="flex justify-center">
                    <Typography variant="h1" className="font-manrope text-black text-2xl mt-4">
                      Invalid email
                    </Typography>
                  </Grid>
                  <Grid xs={12} className="flex justify-center">
                    <Typography variant="body1" className="mt-4 mb-4">
                      Unfortunately, we can't create an account for you as you used a public email
                      address. Please sign up with an organization email address.
                    </Typography>
                  </Grid>
                  <Grid xs={12} className="flex justify-center">
                    <Link to="/auth" className="mt-4 mb-4">
                      Sign up with a different email address
                    </Link>
                  </Grid>
                </Grid>
              )}

              {!invalidEmail && !emailConfirmed && (
                <Typography variant="body1" className="mt-4 mb-4">
                  We're setting up your account. Please wait...
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid xs={12}></Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmailConfirmationPage;
