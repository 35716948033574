import { toHtml } from 'hast-util-to-html';
import { useCallback } from 'react';
import rehypeParse from 'rehype-parse';
import rehypeRemark from 'rehype-remark';
import { remark } from 'remark';
import remarkHtml from 'remark-html';
import remarkStringify from 'remark-stringify';
import strip from 'strip-markdown';
import { unified } from 'unified';

const useMarkdownConverter = () => {
  const markdownToHtml = useCallback((markDowText: string): string => {
    const data = remark().use(remarkHtml, { sanitize: false }).processSync(markDowText);

    return String(data);
  }, []);

  // convert html to markdown and preserve span,strong html elements
  const htmlToMarkdown = useCallback((htmlText: string): string => {
    const data = unified()
      .use(rehypeParse, { fragment: true })
      .use(rehypeRemark, {
        handlers: {
          span(state, node) {
            /** @type {Html} */
            const result = { type: 'html' as any, value: toHtml(node) };
            state.patch(node, result);
            return result;
          },
          strong(state, node) {
            /** @type {Html} */
            const result = { type: 'html' as any, value: toHtml(node) };
            state.patch(node, result);
            return result;
          }
        }
      })
      .use(remarkStringify)
      .processSync(htmlText);

    return String(data);
  }, []);

  const htmlToPlainMarkdown = useCallback((htmlText: string): string => {
    const data = remark()
      .use(rehypeParse)
      .use(rehypeRemark)
      .use(remarkStringify)
      .processSync(htmlText);

    return String(data);
  }, []);

  const markdownToString = useCallback((markdownText: string) => {
    const data = remark().use(strip).processSync(markdownText);

    return String(data);
  }, []);

  return { markdownToHtml, htmlToMarkdown, htmlToPlainMarkdown, markdownToString };
};

export default useMarkdownConverter;
