export enum Sources {
  'Google Drive' = 'google-drive',
  'ClickUp' = 'clickup',
  Confluence = 'confluence',
  Jira = 'jira',
  'Salesforce - Conversations' = 'salesforce-conversations',
  'Salesforce - Articles' = 'salesforce-articles',
  'Intercom - Conversations' = 'intercom-conversations',
  'Intercom - Articles' = 'intercom-articles',
  'PDF' = 'pdf'
}
