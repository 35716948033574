import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useCallback } from 'react';
import { useToast } from 'contexts/ToastContext';
import { Database } from 'types/models/db.type';
import { QueryFunctionContext } from 'react-query';
import saveAs from 'file-saver';

type CallLog = Database['public']['Tables']['v_call_logs']['Row'];
type CallLogFilter = {
  filter: string;
  organizationId: string;
  page: number | undefined;
  limit?: number | undefined;
  to: string | undefined;
  from: string | undefined;
  outcome: string | undefined;
  endedBy: string | undefined;
};

type GetManyParams = QueryFunctionContext<['get_call_logs', CallLogFilter]>;
type GetCallLogParam = QueryFunctionContext<['get_call_log', { id: string }]>;
type GetEndedParams = QueryFunctionContext<['call_log_ended_by', { organizationId: string }]>;
type GetFromParams = QueryFunctionContext<['call_log_from', { organizationId: string }]>;
type GetOutcomeParams = QueryFunctionContext<['call_log_outcome', { organizationId: string }]>;
type GetToParams = QueryFunctionContext<['call_log_to', { organizationId: string }]>;

const useCallLogService = () => {
  const {
    CALL_LOG_TABLE,
    CALL_LOG_ENDED_BY_VIEW,
    CALL_LOG_FROM_VIEW,
    CALL_LOG_OUTCOME_VIEW,
    CALL_LOG_TO_VIEW
  } = KBB_SCHEMA;

  const { showToast } = useToast();

  const get = useCallback(
    async (params: GetCallLogParam) => {
      const [, queryParams] = params.queryKey;
      const { id } = queryParams;

      const { data, error } = await supabaseClient
        .from(CALL_LOG_TABLE)
        .select('*')
        .eq('id', id)
        .single();

      if (error) console.error(error);

      if (!data) return;

      return data;
    },
    [CALL_LOG_TABLE]
  );

  const getMany = useCallback(
    async (params: GetManyParams) => {
      const [, queryParams] = params.queryKey;
      const { filter, organizationId, page, limit, from, to, outcome, endedBy } = queryParams;

      if (!organizationId) return;

      let query = supabaseClient
        .from(CALL_LOG_TABLE)
        .select('*, v_pathways(agent_id)', { count: 'exact' })
        .order('created_at', { ascending: false })
        .eq('organization_id', organizationId);

      if (filter) {
        query = query.or(`agent_name.like.%${filter}%`);
      }

      if (from && from !== 'all') {
        query = query.like('from', `%${from}%`);
      }

      if (to && to !== 'all') {
        query = query.like('to', `%${to}%`);
      }

      if (outcome && outcome !== 'all') {
        query = query.like('call_ended_reason', `%${outcome}%`);
      }

      if (endedBy && endedBy !== 'all') {
        query = query.like('call_ended_by', `%${endedBy}%`);
      }

      if (page) {
        if (page === 0) {
          query = query.range(0, limit ?? 10);
        } else query = query.range((page - 1) * (limit ?? 10), page * (limit ?? 10));
      }

      let { data, count, error } = await query.order('id');

      if (error) console.log(error);

      return { data, count };
    },
    [CALL_LOG_TABLE]
  );

  const exportFiltered = useCallback(
    async (queryParams: CallLogFilter) => {
      const { filter, organizationId, page, limit, from, to, outcome, endedBy } = queryParams;

      if (!organizationId) return;

      let query = supabaseClient
        .from(CALL_LOG_TABLE)
        .select('*, v_pathways(agent_id)', { count: 'exact' })
        .order('created_at', { ascending: false })
        .eq('organization_id', organizationId);

      if (filter) {
        query = query.or(`agent_name.like.%${filter}%`);
      }

      if (from && from !== 'all') {
        query = query.like('from', `%${from}%`);
      }

      if (to && to !== 'all') {
        query = query.like('to', `%${to}%`);
      }

      if (outcome && outcome !== 'all') {
        query = query.like('call_ended_reason', `%${outcome}%`);
      }

      if (endedBy && endedBy !== 'all') {
        query = query.like('call_ended_by', `%${endedBy}%`);
      }

      let { data, count, error } = await query.order('id').csv();

      if (error) console.log(error);

      const blob = new Blob([data!], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'call-logs-data.csv');
    },
    [CALL_LOG_TABLE]
  );

  const put = useCallback(
    async ({ ...callLog }: CallLog) => {
      try {
        const { data, error } = await supabaseClient
          .from(CALL_LOG_TABLE)
          .update({ ...callLog })
          .eq('id', callLog.id)
          .single();

        if (error) console.error(error);

        return data;
      } catch (error) {
        showToast('Error updating callLog', 'error');
      }
    },
    [CALL_LOG_TABLE, showToast]
  );

  const deleteItem = useCallback(
    async (id: string) => {
      try {
        const { error } = await supabaseClient.from(CALL_LOG_TABLE).delete().eq('id', id);

        if (error) console.error(error);

        showToast('Call log successfully deleted', 'success');
      } catch (error) {
        showToast('Error deleting call log', 'error');
      }
    },
    [CALL_LOG_TABLE, showToast]
  );

  const post = useCallback(
    async (item: any) => {
      try {
        const { data, error } = await supabaseClient.from(CALL_LOG_TABLE).insert(item).select();

        if (error) console.error(error);

        if (!data) return;

        showToast('Call log successfully created', 'success');
        return data;
      } catch (error) {
        showToast('Error creating call log', 'error');
      }
    },
    [CALL_LOG_TABLE, showToast]
  );

  const getEndedBy = useCallback(
    async (params: GetEndedParams) => {
      const [, queryParams] = params.queryKey;
      const { organizationId } = queryParams;

      if (!organizationId) return;

      let query = supabaseClient
        .from(CALL_LOG_ENDED_BY_VIEW)
        .select(`*`, { count: 'exact' })
        .eq('organization_id', organizationId);

      const { data, count } = await query.returns<any>();

      return { data, count };
    },
    [CALL_LOG_ENDED_BY_VIEW]
  );

  const getFrom = useCallback(
    async (params: GetFromParams) => {
      const [, queryParams] = params.queryKey;
      const { organizationId } = queryParams;

      if (!organizationId) return;

      let query = supabaseClient
        .from(CALL_LOG_FROM_VIEW)
        .select(`*`, { count: 'exact' })
        .eq('organization_id', organizationId);

      const { data, count } = await query.returns<any>();

      return { data, count };
    },
    [CALL_LOG_FROM_VIEW]
  );

  const getOutcome = useCallback(
    async (params: GetOutcomeParams) => {
      const [, queryParams] = params.queryKey;
      const { organizationId } = queryParams;

      if (!organizationId) return;

      let query = supabaseClient
        .from(CALL_LOG_OUTCOME_VIEW)
        .select(`*`, { count: 'exact' })
        .eq('organization_id', organizationId);

      const { data, count } = await query.returns<any>();

      return { data, count };
    },
    [CALL_LOG_OUTCOME_VIEW]
  );

  const getTo = useCallback(
    async (params: GetToParams) => {
      const [, queryParams] = params.queryKey;
      const { organizationId } = queryParams;

      if (!organizationId) return;

      let query = supabaseClient
        .from(CALL_LOG_TO_VIEW)
        .select(`*`, { count: 'exact' })
        .eq('organization_id', organizationId);

      const { data, count } = await query.returns<any>();

      return { data, count };
    },
    [CALL_LOG_TO_VIEW]
  );

  return {
    deleteItem,
    exportFiltered,
    get,
    getMany,
    post,
    put,
    getEndedBy,
    getFrom,
    getOutcome,
    getTo
  };
};

export default useCallLogService;
