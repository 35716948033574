import { ArrowBackIos, DeleteOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import clsx from 'clsx';
import { UpTransition } from 'components/atoms';
import { TabPanel } from 'components/molecules';
import { StepOptionList } from 'components/organisms';
import { customAlphabet } from 'nanoid';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Guide } from 'types/models';
import { GuideStep, GuideStepOption } from 'types/models/guide.model';
import GuideFormProps from './GuideForm.props';
const nanoid = customAlphabet('1234567890', 10);

function GuideForm({
  initialGuide,
  open,
  handleClose,
  handleSubmit
}: Readonly<GuideFormProps>): React.ReactElement {
  const sanitizeData = useCallback((guide: Guide): Guide => {
    const sanitizedSteps: GuideStep[] = guide.guideContentJson.steps.map((step, idx) => ({
      ...step,
      expanded: idx === 0,
      type: step.type,
      options: step.options.map((option) =>
        option.optionId
          ? option
          : {
              ...option,
              optionId: nanoid(),
              type: option.referenceId ? 'another_step' : 'instruction'
            }
      )
    }));

    const sanitizedGuide = {
      ...guide,
      guideContentJson: { ...guide.guideContentJson, steps: sanitizedSteps }
    };
    return sanitizedGuide;
  }, []);

  const sanitizedData = initialGuide ? sanitizeData(initialGuide) : undefined;
  const initialStep = sanitizedData?.guideContentJson?.steps?.length
    ? sanitizedData?.guideContentJson?.steps[0]
    : ({} as GuideStep);

  const [guide, setGuide] = useState<Guide>(sanitizedData || ({} as Guide));
  const [tabValue, setTabValue] = useState(0);
  const [guideNameEditable, setGuideNameEditable] = useState<boolean>(false);
  const [newStepName, setNewStepName] = useState<string>('');
  const [activeStep, setActiveStep] = useState<GuideStep>(initialStep);
  const [steps, setSteps] = useState<GuideStep[]>(sanitizedData?.guideContentJson.steps ?? []); // TODO: replace with guide.steps

  function a11yProps(index: number, ariaControlId: string) {
    return {
      id: `tab-${index}`,
      'aria-controls': `${ariaControlId}-${index}`
    };
  }

  // #region GUIDE
  const handleGuideFieldChange = useCallback(
    (
      field: keyof Guide,
      value: any // TODO: replace any with type for value
    ) => {
      setGuide((prevState) => ({
        ...prevState,
        [field]: value
      }));
    },
    []
  );

  const clear = useCallback(() => {
    setGuide({} as Guide);
    setSteps([]);
    setActiveStep({} as GuideStep);
  }, []);

  const saveOrUpdate = useCallback(() => {
    handleSubmit({
      ...guide,
      guideContentJson: {
        steps: steps,
        description: '',
        notes: [],
        title: ''
      }
    });
    clear();
    handleClose();
  }, [steps, guide, handleSubmit, handleClose, clear]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  // #endregion

  // #region STEPS
  const createOptionMock = useCallback((optionId: string): GuideStepOption => {
    return {
      optionId,
      type: 'instruction',
      description: '',
      label: '',
      referenceId: ''
    };
  }, []);

  const createNewStepMock = useCallback(
    (stepId: string, title: string = 'Start Point', expanded: boolean = true): GuideStep => {
      return {
        instruction: '',
        options: [createOptionMock(nanoid())],
        stepId,
        title,
        type: 'crm_check',
        expanded
      };
    },
    [createOptionMock]
  );

  const addStep = useCallback(
    (title: string): GuideStep => {
      const newStep = createNewStepMock(nanoid(), title, false);
      setSteps((prevSteps) => [...prevSteps, newStep]);

      return newStep;
    },
    [createNewStepMock]
  );

  const handleAddInitialStep = useCallback(() => {
    const initialStep = createNewStepMock(nanoid(), 'Start Point');

    setSteps((prevSteps) => [...prevSteps, initialStep]);
    setActiveStep(initialStep);
  }, [setActiveStep, createNewStepMock]);

  const handleAddStepKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        addStep(newStepName);
        setNewStepName('');
      }
      if (e.key === 'Escape') {
        setNewStepName('');
      }
    },
    [newStepName, setNewStepName, addStep]
  );

  const handleNewStepNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNewStepName(e.target.value);
  }, []);

  const handleUpdateActiveStep = useCallback(
    (
      id: string,
      field: keyof GuideStep,
      value: any //TODO: replace value: any
    ) => {
      setActiveStep((prevState) => {
        if (!prevState) return {} as GuideStep;
        return { ...prevState, [field]: value };
      });

      setSteps((prevSteps) => {
        return prevSteps.map((step) => (step.stepId === id ? { ...step, [field]: value } : step));
      });
    },
    []
  );

  const handleStepClick = useCallback(
    (stepId: string) => {
      const active = steps.find((s) => s.stepId === stepId);
      active && setActiveStep({ ...active, expanded: true });

      setSteps((prevSteps) => {
        return prevSteps.map((step) =>
          step.stepId === stepId ? { ...step, expanded: true } : { ...step, expanded: false }
        );
      });
    },
    [steps]
  );

  const handleDeleteStep = useCallback(() => {
    if (!activeStep) return;

    const updatedSteps = steps
      .filter((step) => step.stepId !== activeStep.stepId)
      .map((step) => ({
        ...step,
        options: step.options.map((option) =>
          option.referenceId && option.referenceId === activeStep.stepId
            ? { ...option, referenceId: '' }
            : option
        )
      }));

    setGuide((prevState) => ({
      ...prevState,
      guideContentJson: { ...prevState.guideContentJson, steps: updatedSteps }
    }));
    setSteps(updatedSteps);
    setActiveStep({} as GuideStep);
  }, [activeStep, steps]);
  // #endregion

  // #region OPTIONS
  const handleAddOption = useCallback(() => {
    const newOption: GuideStepOption = createOptionMock(nanoid());
    setActiveStep((prevStep) =>
      prevStep ? { ...prevStep, options: [...prevStep.options, newOption] } : ({} as GuideStep)
    );
  }, [createOptionMock]);

  const handleUpdateOption = useCallback(
    (id: string, field: keyof GuideStepOption, value: string | number) => {
      const updatedOptions = activeStep?.options.map((option) => {
        if (option.optionId === id) {
          return { ...option, [field]: value };
        }
        return option;
      });

      updatedOptions &&
        setActiveStep((prevStep) =>
          prevStep ? { ...prevStep, options: updatedOptions } : ({} as GuideStep)
        );

      if (activeStep && updatedOptions) {
        setSteps((prevSteps) => {
          return prevSteps.map((step) =>
            step.stepId === activeStep.stepId ? { ...activeStep, options: updatedOptions } : step
          );
        });
      }
    },
    [activeStep]
  );

  const handleCreateReferenceStep = useCallback(
    (optionId: string, stepName: string) => {
      const newStep = addStep(stepName);
      handleUpdateOption(optionId, 'referenceId', newStep.stepId);
    },
    [addStep, handleUpdateOption]
  );

  const handleGetStepByReferenceId = useCallback(
    (refId: string): string => {
      const stepTitle = steps.find((s) => s.stepId === refId)?.title ?? '';
      return stepTitle;
    },
    [steps]
  );

  //#endregion

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={UpTransition}>
      <Box className="pt-6 pb-6 pl-11 pr-11 h-full bg-light-gray">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <ArrowBackIos />
          </IconButton>
          {!guideNameEditable && (
            <Typography
              variant="h4"
              className="text-4xl flex-1 ml-4"
              onClick={() => setGuideNameEditable(true)}
            >
              {guide.name || 'Journey Name'}
            </Typography>
          )}
          {guideNameEditable && (
            <TextField
              fullWidth
              placeholder="Journey name"
              name="name"
              value={guide.name || ''}
              onBlur={() => setGuideNameEditable(false)}
              onChange={(e) => handleGuideFieldChange('name', e.target.value)}
            />
          )}
          <Box className="min-w-[184px] mr-4">
            <FormControl fullWidth>
              <InputLabel id="guide-version-label">Current Version</InputLabel>
              <Select
                labelId="guide-version-label"
                id="guide-version"
                value={1} // TODO: check what needs to be added for guide version field
                label="Current Version"
                onChange={(event: SelectChangeEvent<number>) =>
                  setGuide({ ...guide, version: event.target.value as number })
                }
              >
                <MenuItem value={1}>1.0</MenuItem>
                <MenuItem value={2}>2.0</MenuItem>
                <MenuItem value={3}>3.0</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button
            type="button"
            color="primary"
            size="large"
            variant="contained"
            className="float-right capitalize rounded-lg text-base h-[50px] max-w-[25%]"
            onClick={saveOrUpdate}
          >
            Publish
          </Button>
        </Toolbar>

        {/* //BODY */}
        <Box className="h-[calc(100%_-_96px)]">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="Journey tabs">
              <Tab label="Editor" {...a11yProps(0, 'guide-tab-panel')} />
              <Tab label="Insights" {...a11yProps(1, 'guide-tab-panel')} />
            </Tabs>
          </Box>
          <Box className="h-full">
            <TabPanel id="guide-tab-panel" value={tabValue} index={0} className="h-full">
              <Grid container spacing={4} className="p-4 h-full">
                {/* --------------- INDEX --------------- */}
                <Grid item xs={12} md={4}>
                  <Paper
                    elevation={0}
                    className="bg-white h-full relative max-h-[calc(100vh_-_11rem)]"
                  >
                    <Toolbar className="border-solid border-t-0 border-l-0 border-r-0 border-b-1 border-gray5">
                      <Typography variant="body1" className="text-lg leading-4 font-semibold">
                        Index
                      </Typography>
                    </Toolbar>

                    <Box id="step-index" className="overflow-scroll max-h-[calc(100vh_-_15rem)]">
                      {steps?.length > 0 && (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          className="m-4 mt-5"
                        >
                          <Typography variant="body1" className="text-lg leading-4 font-semibold">
                            Journey Name
                          </Typography>
                          <Button>Expand all</Button>
                        </Box>
                      )}

                      {/* //TODO: Move to StepSummary component */}
                      {steps.map((step) => (
                        <Box
                          key={step.stepId}
                          className={clsx(
                            'border-solid border-1  h-13 p-4 m-6 rounded-md ',
                            step.expanded && 'border-orange bg-pink2'
                          )}
                          onClick={() => handleStepClick(step.stepId)}
                        >
                          <Typography variant="body1" className="text-sm font-inter font-medium">
                            {step.title}
                          </Typography>
                          {step.instruction && (
                            <Box
                              flexDirection="column"
                              className={clsx(step.type === 'content' && 'p-2 bg-purple')}
                            >
                              {step.type === 'content' && (
                                <Typography className="font-bold font-inter">[INTERNAL]</Typography>
                              )}
                              <Typography
                                variant="body2"
                                className="font-inter font-normal whitespace-pre-line pt-3 pb-3 text-sm"
                              >
                                {step.instruction}
                              </Typography>
                            </Box>
                          )}
                          {step.type === 'crm_check' &&
                            step.options?.map((option, idx) => (
                              <Box key={`${option.optionId}-${step.stepId}`}>
                                <>
                                  {option.description.length > 0 && (
                                    <Typography
                                      key={option.optionId}
                                      variant="body2"
                                      className="text-gray  font-inter font-light text-sm"
                                    >
                                      {idx + 1}. {option.description}
                                    </Typography>
                                  )}
                                </>
                                {option.referenceId && (
                                  <Typography
                                    variant="body2"
                                    className=" text-gray font-inter font-light text-sm ml-4"
                                  >
                                    a. {handleGetStepByReferenceId(option.referenceId)}
                                  </Typography>
                                )}
                              </Box>
                            ))}
                        </Box>
                      ))}
                      {steps?.length === 0 && (
                        <Box className="w-full absolute top-1/2 left-0 right-0 text-center">
                          <Typography variant="body2">There are no steps yet</Typography>
                          <Button onClick={handleAddInitialStep}>+ Add Initial Step</Button>
                        </Box>
                      )}

                      {steps?.length > 0 && (
                        <Box className="m-4 ml-6 mr-6">
                          <TextField
                            fullWidth
                            placeholder="+ Add Step"
                            value={newStepName}
                            onKeyDown={handleAddStepKeyDown}
                            onChange={handleNewStepNameChange}
                          />
                        </Box>
                      )}
                    </Box>
                  </Paper>
                </Grid>
                {/* --------------- CONTENT --------------- */}
                <Grid item xs={12} md={4}>
                  <Paper
                    elevation={0}
                    className="bg-white h-full relative max-h-[calc(100vh_-_11rem)]"
                  >
                    <Toolbar className="border-solid border-t-0 border-l-0 border-r-0 border-b-1 border-gray5">
                      <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="body1"
                        className="text-base text-range"
                      >
                        Content
                      </Typography>
                      <Tooltip title="Delete Step" placement="top">
                        <IconButton onClick={handleDeleteStep}>
                          <DeleteOutlined />
                        </IconButton>
                      </Tooltip>
                    </Toolbar>

                    <Box id="step-content" className="overflow-scroll max-h-[calc(100vh_-_15rem)]">
                      {activeStep?.options && (
                        <Box className="m-4">
                          <FormControl fullWidth>
                            <TextField
                              id="step-title"
                              name="title"
                              variant="outlined"
                              fullWidth
                              value={activeStep?.title}
                              onChange={(e) =>
                                handleUpdateActiveStep(
                                  activeStep.stepId,
                                  e.target.name as keyof GuideStep,
                                  e.target.value
                                )
                              }
                            />
                          </FormControl>
                          <FormControl fullWidth className="mt-3">
                            {/* // TODO: check if value is always Question (1) */}
                            <InputLabel id="step-question-label">Step Type</InputLabel>
                            <Select
                              labelId="step-type-label"
                              id="step-type"
                              label="Step Type"
                              value={activeStep.type as any} //marking as any because MUI select "only" allows numbers https://github.com/mui/material-ui/issues/14286
                              name="type"
                              onChange={(e: SelectChangeEvent<number>) =>
                                handleUpdateActiveStep(
                                  activeStep.stepId,
                                  e.target.name as keyof GuideStep,
                                  e.target.value
                                )
                              }
                            >
                              <MenuItem value={'crm_check'}>Question</MenuItem>
                              <MenuItem value={'content'}>Action</MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl fullWidth className="mt-3">
                            <TextField
                              id="step-instruction"
                              name="instruction"
                              value={activeStep.instruction}
                              multiline
                              rows={4}
                              onChange={(e) =>
                                handleUpdateActiveStep(
                                  activeStep.stepId,
                                  e.target.name as keyof GuideStep,
                                  e.target.value
                                )
                              }
                            />
                          </FormControl>
                        </Box>
                      )}
                      {activeStep?.options && activeStep.type === 'crm_check' && (
                        <>
                          {/* TODO: create a context to prevent props drilling at StepOptionList*/}
                          <StepOptionList
                            allSteps={steps}
                            options={activeStep.options}
                            handleUpdateOption={handleUpdateOption}
                            handleCreateReferenceStep={handleCreateReferenceStep}
                            handleGetStepByReferenceId={handleGetStepByReferenceId}
                          />
                          <Box className="m-4">
                            <Button onClick={handleAddOption}>+Add Option</Button>
                          </Box>
                        </>
                      )}
                      {!activeStep?.options && (
                        <Box className="w-full absolute top-1/2 left-0 right-0 text-center">
                          <Typography variant="body2">
                            Create first step or select one from the Index to add content
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Paper>
                </Grid>
                {/* --------------- PREVIEW --------------- */}
                <Grid item xs={12} md={4}>
                  <Paper elevation={0} className="bg-white relative h-full">
                    <Toolbar style={{ borderBottom: '1px solid #E7E4E1' }}>
                      <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="body1"
                        className="text-base text-range"
                      >
                        Preview
                      </Typography>
                    </Toolbar>
                    <Box className="w-full absolute top-1/2 left-0 right-0 text-center">
                      <Typography variant="body2">There would be showed steps preview</Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              Insigths
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default GuideForm;
