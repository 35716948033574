import dagre from '@dagrejs/dagre';
import { Button, Stack } from '@mui/material';
import { StickyNoteIcon } from 'components/atoms';
import { CustomEdge } from 'components/molecules';
import { DefaultNode } from 'components/molecules/nodes/default-node/DefaultNode';
import { EndCallNode } from 'components/molecules/nodes/end-call-node/EndCallNode';
import { FunctionCallNode } from 'components/molecules/nodes/function-call-node/FunctionCallNode';
import { KnowledgeBaseNode } from 'components/molecules/nodes/knowledge-base-node/KnowledgeBaseNode';
import { TransferCallNode } from 'components/molecules/nodes/transfer-call-node/TransferCallNode';
import { TransferPathwayNode } from 'components/molecules/nodes/transfer-pathway-node/TransferPathwayNode';
import { WaitForResponseNode } from 'components/molecules/nodes/wait-for-response-node/WaitForResponseNode';
import { GeneralInstructionsModal, NodeDialog } from 'components/organisms';
import { useToast } from 'contexts/ToastContext';
import { useAgentsService } from 'hooks';
import { customAlphabet } from 'nanoid';
import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import AddIcon from '@mui/icons-material/Add';
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  MiniMap,
  Node,
  OnConnectStartParams,
  Panel,
  useEdgesState,
  useNodesState,
  useReactFlow
} from 'reactflow';
import 'reactflow/dist/style.css';

const nodeTypes = {
  Default: DefaultNode,
  'Knowledge Base': KnowledgeBaseNode,
  'Transfer Call': TransferCallNode,
  'Wait For Response': WaitForResponseNode,
  'End Call': EndCallNode,
  'Function Call': FunctionCallNode,
  DTMF: DefaultNode,
  'Transfer Pathway Node': TransferPathwayNode
};

const edgeTypes = {
  default: CustomEdge
};

const defaultEdgeOptions = { animated: true };

interface PathwayBuilderProps {
  initialPathway: any;
  onProcessing: (value: boolean) => void;
  onSaved: VoidFunction;
  onSavedFailed: VoidFunction;
}

const nanoid = customAlphabet('123456789', 10);

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const PathwayBuilder = ({
  initialPathway,
  onProcessing,
  onSaved,
  onSavedFailed
}: PathwayBuilderProps) => {
  const { screenToFlowPosition } = useReactFlow();
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [selectedNode, setSelectedNode] = useState<Node>();
  const [open, setOpen] = useState<boolean>(false);
  const [generalInstructionsOpen, setGeneralInstructionsOpen] = useState<boolean>(false);
  const [pathway, setPathway] = useState<any>(initialPathway);
  const connectingNodeId = useRef<string | null>(null);

  const {
    addNode,
    addEdge: createEdge,
    updateNode,
    updateAllNodesPosition,
    updatePathway
  } = useAgentsService();

  const getNodeType = (type: string) => {
    const nodeTypes = {
      default: 'Default',
      function_call: 'Function Call',
      transfer_call: 'Transfer Call',
      end_call: 'End Call',
      wait_for_response: 'Wait For Response',
      knowledge_base: 'Knowledge Base',
      dtmf: 'DTMF',
      transfer_pathway: 'Transfer Pathway Node'
    };

    const value = nodeTypes[type as keyof typeof nodeTypes] || nodeTypes['default'];

    return value;
  };

  const processPathway = useCallback(
    (data: any) => {
      const { pathway, pathway_ui } = data;

      const getLayoutElements = (nodes: any, edges: any, direction = 'TB') => {
        const nodeWidth = 320;
        const nodeHeight = 300;
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });

        nodes.forEach((node: any) => {
          dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((edge: any) => {
          dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);

        nodes.forEach((node: any) => {
          const nodeWithPosition = dagreGraph.node(node.id);
          node.targetPosition = isHorizontal ? 'left' : 'top';
          node.sourcePosition = isHorizontal ? 'right' : 'bottom';

          // We are shifting the dagre node position (anchor=center center) to the top left
          // so it matches the React Flow node anchor point (top left).
          if (node.position === undefined) {
            node.position = {
              x: nodeWithPosition.x - nodeWidth / 2,
              y: nodeWithPosition.y - nodeHeight / 2
            };
          }

          return node;
        });

        return { nodes, edges };
      };

      const getNodePosition = (id: number) =>
        pathway_ui?.nodes?.find((x: any) => x.id === id)?.position;

      const processedNodes = pathway.nodes?.map((node: any, idx: number) => ({
        id: node.id.toString(),
        x: idx,
        y: idx,
        position: getNodePosition(node.id),
        data: {
          name: node.node_name,
          label: node.static_text ? node.static_text : node.prompt,
          text: node.static_text ? node.static_text : node.prompt,
          isGlobal: node.is_global,
          isStaticText: node.is_static_text,
          staticText: node.static_text,
          prompt: node.prompt,
          isStart: idx === 0,
          nodeEnterCondition: node.node_enter_condition,
          userData: node.user_data,
          functionName: node.function_name,
          transferCallNumber: node.transfer_call_number,
          agentId: node.agent_id
        },
        type: getNodeType(node.node_type)
      }));

      const processedEdges = pathway.edges?.map((edge: any) => ({
        id: edge.id?.toString(),
        label: edge.label,
        source: edge.source?.toString(),
        target: edge.target?.toString(),
        type: 'default',
        animated: true,
        selected: false,
        sourceHandle: null,
        targetHandle: null
      }));

      const { nodes: layoutNodes, edges: layoutEdges } = getLayoutElements(
        processedNodes,
        processedEdges
      );

      setNodes(layoutNodes);
      setEdges(layoutEdges);
    },
    [setEdges, setNodes]
  );

  const getNodeInstance = ({
    id,
    x,
    y,
    name = `Node ${id}`,
    label = `Node ${id}`,
    isStaticText,
    text,
    prompt,
    isGlobal,
    isStart,
    nodeEnterCondition,
    type = 'Default',
    userData,
    functionName,
    transferCallNumber,
    agentId
  }: {
    id: string;
    x: number;
    y: number;
    name?: string;
    label?: string;
    text?: string;
    isStaticText?: boolean;
    prompt?: string;
    isGlobal?: boolean;
    isStart?: boolean;
    type?: string;
    nodeEnterCondition?: string;
    userData?: any[];
    functionName?: string;
    transferCallNumber?: string;
    agentId?: string;
  }) => ({
    id: id.toString(),
    x,
    y,
    position: {
      x,
      y
    },
    data: {
      name,
      functionName,
      label,
      text,
      isGlobal,
      isStaticText,
      prompt,
      isStart,
      nodeEnterCondition,
      userData,
      transferCallNumber,
      agentId
    },
    type: type
  });

  const addNodeMutation = useMutation({
    mutationKey: 'add_agent_pathway_node',
    mutationFn: ({ pathwayId, node, edge }: { pathwayId: string; node: Node; edge?: Edge }) =>
      addNode({ pathwayId, node, edge }),
    onMutate: () => {
      onProcessing(true);
    },
    onSuccess: (data) => {
      if (pathway?.pathway?.nodes?.length + 1 !== data?.data[0].pathway?.nodes?.length) {
        onSavedFailed();
      }
      console.log('current pathway', pathway);
      console.log('updated pathway', data);
      onProcessing(false);
      onSaved();
    }
  });

  const addEdgeMutation = useMutation({
    mutationKey: 'add_agent_pathway_edge',
    mutationFn: ({ pathwayId, edge }: { pathwayId: string; edge: Edge }) =>
      createEdge({ pathwayId, edge }),
    onMutate: () => {
      onProcessing(true);
    },
    onSuccess: (data) => {
      if (pathway?.pathway?.edges?.length + 1 !== data?.data[0].pathway?.edges?.length) {
        onSavedFailed();
      }
      console.log('current pathway', pathway);
      console.log('updated pathway', data);
      onProcessing(false);
      onSaved();
    }
  });

  const updateNodeMutation = useMutation({
    mutationKey: 'update_agents_pathway_node_edge',
    mutationFn: ({ pathwayId, node }: { pathwayId: string; node?: Node }) =>
      updateNode({ pathwayId, node }),
    onSuccess: (data: any) => {
      onSaved();
    }
  });

  const updateAllNodesPositionMutation = useMutation({
    mutationKey: 'update_agents_pathway_node_edge_positions',
    mutationFn: ({ pathwayId, nodes }: { pathwayId: string; nodes: Node[] }) =>
      updateAllNodesPosition({ pathwayId, nodes }),
    onMutate: () => {
      onProcessing(true);
    },
    onSuccess: (data: any) => {
      onProcessing(false);
      onSaved();
    }
  });

  const handleConnect = useCallback(
    async (connection: Edge | Connection) => {
      if (pathway?.id && connection.source && connection.target) {
        const newEdge: Edge = {
          id: nanoid(),
          source: connection.source,
          target: connection.target
        };

        addEdgeMutation.mutate({ pathwayId: pathway?.id, edge: newEdge });
        setEdges((eds) => addEdge(newEdge as Edge, eds));
      }
    },
    [setEdges, addEdgeMutation, pathway?.id]
  );

  const handleConnectStart = useCallback((event: any, params: OnConnectStartParams) => {
    connectingNodeId.current = params.nodeId;
  }, []);

  const handleConnectEnd = useCallback(
    async (event: any) => {
      if (!connectingNodeId.current) return;
      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const nodeId = nanoid();
        const edgeId = nanoid();
        const newNode = getNodeInstance({
          id: nodeId.toString(),
          x: event.clientX,
          y: event.clientY
        });

        newNode.position = screenToFlowPosition({
          x: event.clientX,
          y: event.clientY
        });

        const newEdge = {
          id: edgeId.toString(),
          source: connectingNodeId.current,
          target: nodeId.toString()
        };

        if (pathway?.id && newNode && newEdge) {
          addNodeMutation.mutate({ pathwayId: pathway?.id, node: newNode, edge: newEdge });

          onSaved();
          setNodes((nds) => nds.concat(newNode));
          setEdges((eds) => eds.concat(newEdge));
        }
      }
    },
    [setEdges, setNodes, screenToFlowPosition, addNodeMutation, pathway?.id]
  );

  const handleNodePositionUpdate = useCallback(
    async (event: ReactMouseEvent, node: Node) => {
      if (pathway?.id) {
        updateAllNodesPositionMutation.mutate({ pathwayId: pathway?.id, nodes });
      }
    },
    [pathway?.id, updateAllNodesPositionMutation, nodes]
  );

  const handleDoubleClick = (event: any, node: any) => {
    setSelectedNode(node);
    setOpen(true);
  };

  const handleAddGeneralInstructions = useCallback(() => {
    setGeneralInstructionsOpen((prevValue) => !prevValue);
  }, []);

  const handleAddNewNode = useCallback(async () => {
    const globals = nodes?.filter((node: any) => node.data.isStart);

    if (globals.length === 0) {
      globals.push(nodes[0]);
    }

    const maxX = Math.max(...globals.map((node: any) => node.position.x));
    const maxY = Math.max(...globals.map((node: any) => node.position.y));

    const maxId = nanoid();

    const newNode = getNodeInstance({
      id: maxId.toString(),
      x: maxX + 400,
      y: maxY
    });

    if (pathway?.id) {
      addNodeMutation.mutate({ pathwayId: pathway?.id, node: newNode });
    }

    setNodes((nds) => nds.concat(newNode));
  }, [nodes, setNodes, addNodeMutation, pathway?.id]);

  useEffect(() => {
    processPathway(initialPathway);
  }, [initialPathway]);

  return (
    <ReactFlow
      onEdgesChange={onEdgesChange}
      onEdgesDelete={() => {}}
      onConnect={handleConnect}
      onConnectStart={handleConnectStart}
      onConnectEnd={handleConnectEnd}
      onNodesChange={onNodesChange}
      onNodeClick={(event: ReactMouseEvent, node: Node) => {
        setNodes((prev) => prev.map((n) => (n.id === node.id ? { ...n, selected: true } : n)));
      }}
      onNodesDelete={() => {}}
      onNodeDragStop={handleNodePositionUpdate}
      onNodeDoubleClick={handleDoubleClick}
      connectionRadius={0}
      edges={edges}
      nodes={nodes}
      deleteKeyCode={[]}
      defaultEdgeOptions={defaultEdgeOptions}
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      fitView
    >
      <Panel position={'top-left'}>
        <Stack direction="column" className="gap-4">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleAddNewNode}
            disableElevation
          >
            <AddIcon fontSize="small" className="mr-2" />
            New Node
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className="bg-white"
            onClick={handleAddGeneralInstructions}
          >
            <StickyNoteIcon fontSize="small" className="h-4 mr-2" />
            General Instructions
          </Button>
        </Stack>
      </Panel>
      <MiniMap pannable zoomable />
      <Controls />
      <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      {selectedNode && open && (
        <NodeDialog
          open={open}
          nodeProps={selectedNode}
          onClose={() => setOpen(false)}
          onSubmit={async (updated) => {
            const { data } = updated;

            setOpen(false);
            setSelectedNode(undefined);
            const updatedNodes = nodes.map((node) => {
              if (node.id === updated.id) {
                const userDataVars = data.userData.filter(
                  (x: any) => x.name !== '' && x.data_type !== '' && x.description !== ''
                );

                const updatedNode = getNodeInstance({
                  id: updated.id,
                  x: updated.position.x,
                  y: updated.position.y,
                  name: data.name,
                  label: data.label,
                  text: data.text,
                  isGlobal: data.isGlobal,
                  isStaticText: data.isStaticText,
                  prompt: data.prompt,
                  isStart: data.isStart,
                  nodeEnterCondition: data.nodeEnterCondition,
                  type: updated.type,
                  userData: userDataVars?.length > 0 ? userDataVars : undefined,
                  functionName: data.functionName,
                  transferCallNumber: data.transferCallNumber,
                  agentId: data.agentId
                });
                return updatedNode;
              } else {
                return node;
              }
            });

            if (pathway?.id) {
              updateNodeMutation.mutate({ pathwayId: pathway?.id, node: updated });
            }

            setNodes(updatedNodes);
          }}
        />
      )}

      {generalInstructionsOpen && pathway && (
        <GeneralInstructionsModal
          value={pathway.pathway.general_instructions}
          open={generalInstructionsOpen}
          onClose={(value: string) => {
            setGeneralInstructionsOpen(false);
            const updatedPathway = {
              ...pathway,
              pathway: { ...pathway.pathway, general_instructions: value }
            };

            updatePathway(updatedPathway);
            setPathway(updatedPathway);
          }}
        />
      )}
    </ReactFlow>
  );
};

export default PathwayBuilder;
