import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Typography(theme: Theme): object {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'inherit'
        },
        caption: {
          fontWeight: 400,
          fontSize: '.75rem',
          lineHeight: '18px'
        },
        paragraph: {
          marginBottom: theme.spacing(2)
        },
        gutterBottom: {
          marginBottom: theme.spacing(1)
        },
        body1: {
          ...theme.typography.body1
        },
        body2: {
          color: '#000',
          fontSize: '.875rem',
          fontWeight: 400,
          lineHeight: '22px'
        },
        h5: {
          color: '#212B36',
          fontWeight: 700,
          fontSize: '1.5rem',
          lineHeight: '30px'
        },
        subtitle1: {
          color: '#212B36',
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: '24px'
        }
      }
    }
  };
}
