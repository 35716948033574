import dagre from '@dagrejs/dagre';
import BarChartIcon from '@mui/icons-material/BarChart';
import CallIcon from '@mui/icons-material/Call';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PublishIcon from '@mui/icons-material/Publish';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';
import clsx from 'clsx';
import { WarningIcon } from 'components/atoms';
import { Call } from 'components/molecules';
import { AgentSettingsModal, PathwayBuilder } from 'components/organisms';
import { ToolbarTemplate } from 'components/templates';
import { useToast } from 'contexts/ToastContext';
import { format } from 'date-fns';
import { useAgentsService, useConfirmationDialog } from 'hooks';
import { useUser } from 'hooks/reducers';
import { MouseEvent as ReactMouseEvent, useCallback, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import ROUTE_PATHS from 'routes/paths';
import { Database } from 'types/models/db.type';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

type Agent = Database['public']['Tables']['v_agents']['Row'];
type Pathway = Database['public']['Tables']['v_pathways']['Row'];

const PathwayPage = () => {
  const {
    deletePathway,
    deleteItem,
    get,
    getPathway,
    getPathwayVersions,
    publishPathwayVersion,
    put: updateAgent,
    duplicateAgent,
    overrideDraftWithVersion
  } = useAgentsService();

  const { user } = useUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  // const [, setNodes] = useNodesState([]);
  // const [, setEdges] = useEdgesState([]);

  const reactFlowWrapper = useRef(null);
  const [agentId, setAgentId] = useState<string | null | undefined>(searchParams.get('id'));
  const [agent, setAgent] = useState<Agent>();
  const [pathway, setPathway] = useState<any>();
  const [versions, setVersions] = useState<Pathway[]>([]);
  const [lastSavedAt, setLastSavedAt] = useState<Date>();
  const [savedFailed, setSavedFailed] = useState<boolean>(true);
  const [versionsOpen, setVersionsOpen] = useState<boolean>(false);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [callUrl, setCallUrl] = useState<string>();
  const [processingCallUrl, setProcessingCallUrl] = useState<boolean>(false);
  const { openDialog } = useConfirmationDialog();
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const iconButtonRef = useRef<HTMLButtonElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const { showToast } = useToast();

  const agentQuery = useQuery({
    queryKey: ['v_agents', { id: agentId!! }],
    queryFn: get,
    enabled: !!user?.user_metadata.organizationId,
    onSuccess: (data: any) => {
      if (data) {
        setAgent(data);
      }
    }
  });

  const agentPathwayQuery = useQuery({
    queryKey: ['v_agents_pathflow', { id: agentId!! }],
    queryFn: getPathway,
    enabled: !!user?.user_metadata.organizationId,
    onSuccess: (draft: any) => {
      if (draft && !pathway) {
        const versionId = searchParams.get('versionId');

        if (!versionId) {
          setSearchParams((params) => {
            params.set('versionId', draft.id.toString());
            return params;
          });
        }

        setPathway(draft);
        setLastSavedAt(draft.updated_at);
        setSavedFailed(false);
      }
    },
    onError: () => setSavedFailed(true)
  });

  const versionsQuery = useQuery({
    queryKey: ['v_agents_pathways', { agentId: agentId!! }],
    queryFn: getPathwayVersions,
    enabled: !!user?.user_metadata.organizationId,
    onSuccess: (data: Pathway[]) => {
      if (data && data.length > 0) {
        setVersions(data);
      } else {
        setVersions([]);
      }
    }
  });

  const publishVersionMutation = useMutation({
    mutationKey: 'publishVersion',
    mutationFn: ({ agentId, pathwayId }: { agentId: string; pathwayId: string }) =>
      publishPathwayVersion({ agentId, pathwayId }),
    onSuccess: (data: any) => {
      if (data) {
        setLastSavedAt(new Date());
        setSavedFailed(false);
        queryClient.invalidateQueries({ queryKey: ['v_agents'] });
        queryClient.invalidateQueries({ queryKey: ['v_agents_pathflow'] });
        queryClient.invalidateQueries({ queryKey: ['v_agents_pathways'] });

        agentQuery.refetch();
        agentPathwayQuery.refetch();
        versionsQuery.refetch();
      }
    },
    onError: () => setSavedFailed(true)
  });

  const overrideDraftMutation = useMutation({
    mutationKey: 'overrideDraft',
    mutationFn: ({ agentId, pathwayId }: { agentId: string; pathwayId: string }) =>
      overrideDraftWithVersion({ agentId, pathwayId }),
    onMutate: () => {
      setProcessing(true);
    },
    onSuccess: (data: any) => {
      if (data) {
        setProcessing(false);
        setLastSavedAt(new Date());
        setSavedFailed(false);

        queryClient.invalidateQueries({ queryKey: ['v_agents'] });
        queryClient.invalidateQueries({ queryKey: ['v_agents_pathflow'] });
        queryClient.invalidateQueries({ queryKey: ['v_agents_pathways'] });

        agentQuery.refetch();
        agentPathwayQuery.refetch();
        versionsQuery.refetch();
      }
    },
    onError: () => setSavedFailed(true)
  });

  const deleteMutation = useMutation({
    mutationKey: 'deleteAgent',
    mutationFn: (id: string) => deleteItem(id),
    onSuccess: (data: void | Agent[] | undefined) => {
      navigate(ROUTE_PATHS.AGENTS);
    }
  });

  const deleteVersionMutation = useMutation({
    mutationKey: 'deleteAgent',
    mutationFn: (id: string) => deletePathway(id),
    onSuccess: (data: void | Agent[] | undefined) => {
      setProcessing(false);
      setLastSavedAt(new Date());
      setSavedFailed(false);
      queryClient.invalidateQueries({ queryKey: ['v_agents_pathways'] });
      versionsQuery.refetch();
    },
    onError: () => setSavedFailed(true)
  });

  const duplicateMutation = useMutation({
    mutationKey: 'duplicateAgent',
    mutationFn: (agent: Agent) => duplicateAgent(agent),
    onSuccess: (data: any) => {
      if (!data?.agent_pathway || data?.agent_pathway.length === 0) return;

      const newPathway = data.agent_pathway;
      const newAgent = data.agent;
      setAgentId(newAgent.id.toString());

      setSearchParams((params) => {
        params.set('id', newAgent.id.toString());
        params.set('versionId', newPathway.id.toString());
        return params;
      });

      setLastSavedAt(new Date(newPathway.updated_at));
      setSavedFailed(false);
      setPathway(newPathway);
      queryClient.invalidateQueries({ queryKey: ['v_agents'] });
      queryClient.invalidateQueries({ queryKey: ['v_agents_pathflow'] });
      agentQuery.refetch();
      agentPathwayQuery.refetch();
    },
    onError: () => setSavedFailed(true)
  });

  const extractAgentSettings = useCallback(() => {
    let settingParams = '';
    if (!agent?.settings) {
      return '';
    }

    const { is_hipaa, language, enable_recording, org, bot_starts_speaking } =
      agent?.settings as any;

    if (is_hipaa !== undefined) {
      settingParams += `&is_hipaa=${is_hipaa}`;
    }

    if (language !== undefined) {
      settingParams += `&language=${language}`;
    }

    if (enable_recording !== undefined) {
      settingParams += `&enable_recording=${enable_recording}`;
    }

    if (org !== undefined) {
      settingParams += `&org=${org}`;
    }

    if (bot_starts_speaking !== undefined) {
      settingParams += `&bot_starts_speaking=${bot_starts_speaking}`;
    }

    return settingParams;
  }, [agent?.settings]);

  const handleClickToCall = useCallback(async () => {
    try {
      setProcessingCallUrl(true);
      if (!pathway || !agent) return;

      const res = await fetch(
        `${process.env.REACT_APP_VOICE_SERVICE_URL}/start?pathway_id=${
          pathway.id
        }&is_json=true${extractAgentSettings()}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'authorization, x-client-info, apikey, content-type',
            'Content-Type': 'application/json',
            'Content-Security-Policy': 'upgrade-insecure-requests'
          }
        }
      );

      if (res.ok) {
        const data = await res.json();
        setCallUrl(data.room_url);
        setProcessingCallUrl(false);
      }
    } catch (error) {
      setProcessingCallUrl(false);
      showToast('Unable to perform click to call, please try again later', 'error');
    }
  }, [pathway, agent, showToast, extractAgentSettings]);

  const handleDelete = useCallback(() => {
    if (!agent) return;
    openDialog(
      'Delete Agent',
      'Are you sure to delete this agent?',
      () => deleteMutation.mutate(agent.id.toString()),
      <WarningIcon className="mr-4" />
    );
  }, [deleteMutation, openDialog, agent]);

  const handleDuplicate = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { v_phone_numbers, ...newAgent } = agent as any;

    duplicateMutation.mutate({
      ...newAgent,
      agent_name: `${agent?.agent_name} (copy)`
    });
  }, [agent, duplicateMutation]);

  const handleToggleOptions = () => {
    setOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleToggleVersions = () => {
    setVersionsOpen((prevOpen) => !prevOpen);
  };

  const publishNewVersion = useCallback(
    (pathwayId: string) => {
      console.log('new version published');

      const publish = () =>
        agent?.id && publishVersionMutation.mutate({ agentId: agent.id.toString(), pathwayId });

      openDialog(
        'Publish Version',
        'Are you sure you want to publish to production a new version?',
        publish
      );
    },
    [agent, openDialog, publishVersionMutation]
  );

  const overrideDraft = useCallback(
    (version: Pathway) => {
      const publish = () => {
        if (agent?.id) {
          overrideDraftMutation.mutate({ agentId: agent.id.toString(), pathwayId: version.id });

          setPathway((prev: any) => ({
            ...prev,
            pathway: version.pathway,
            pathway_ui: version.pathway_ui
          }));
        }
      };

      openDialog(
        'Override draft with version',
        `Are you sure you want to override the current changes with version ${pathway.version_ts}?`,
        publish,
        <WarningIcon className="mr-4" />
      );
    },
    [agent, pathway, openDialog, setPathway, overrideDraftMutation]
  );

  const handleCopyLink = useCallback(
    (published: boolean, value: string) => {
      const url = published
        ? `${
            process.env.REACT_APP_VOICE_SERVICE_URL
          }/start?agent_id=${agentId}${extractAgentSettings()}`
        : `${
            process.env.REACT_APP_VOICE_SERVICE_URL
          }/start?pathway_id=${value}${extractAgentSettings()}`;

      navigator.clipboard.writeText(url);
      showToast('Agent version URL copied to your clipboard', 'success');
    },
    [agentId, showToast, extractAgentSettings]
  );

  const handleDeleteVersion = useCallback(
    (id: string) => {
      openDialog(
        'Delete Agent',
        'Are you sure to delete this agent version?',
        () => deleteVersionMutation.mutate(id),
        <WarningIcon className="mr-4" />
      );
    },
    [deleteVersionMutation, openDialog]
  );

  if (!agent) return <></>;

  return (
    <ToolbarTemplate
      backEnabled
      header={`${agent?.agent_name ?? ''}`}
      headerLeftComponent={
        <Box className="flex items-center">
          <IconButton
            size="small"
            aria-controls={optionsOpen ? 'split-button-menu' : undefined}
            aria-expanded={optionsOpen ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggleOptions}
            ref={iconButtonRef}
            aria-label="Agents options"
          >
            <MoreVertIcon />
          </IconButton>
          <Button variant="text" color="inherit">
            {lastSavedAt && !savedFailed && (
              <CheckCircleOutlineIcon
                color="success"
                fontSize="small"
                className={clsx(processing && `animate-spin`, 'mr-2')}
              />
            )}
            {savedFailed && <HighlightOffIcon color="error" fontSize="small" />}
            {processing ? 'Saving...' : lastSavedAt && !savedFailed ? 'Saved' : 'Not saved'}
          </Button>

          <Popper
            sx={{
              zIndex: 1
            }}
            open={optionsOpen}
            anchorEl={iconButtonRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  {/* <ClickAwayListener onClickAway={handleClose}> */}
                  <MenuList id="split-button-menu" autoFocusItem>
                    <MenuItem key={'copy'} onClick={() => handleCopyLink(true, '')}>
                      <LinkIcon fontSize="small" className="text-gray8 mr-2" />
                      Copy Agent link
                    </MenuItem>

                    <MenuItem key={'duplicate'} onClick={handleDuplicate}>
                      <ContentCopyIcon fontSize="small" className="text-gray8 mr-2" />
                      Duplicate
                    </MenuItem>
                    <MenuItem key={'delete'} onClick={handleDelete} className="text-orange">
                      <DeleteIcon fontSize="small" className="text-orange mr-2" />
                      Delete
                    </MenuItem>
                  </MenuList>
                  {/* </ClickAwayListener> */}
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      }
      headerRightComponent={
        <Box className="relative w-full flex items-end justify-end gap-4">
          {versions && versions.find((v) => v.version_status === 'PUBLISHED') !== undefined && (
            <Chip color="success" label="Published" />
          )}
          <ButtonGroup
            variant="text"
            color="inherit"
            ref={anchorRef}
            disableElevation
            aria-label="agent versions"
          >
            <Button variant="text" size="small" onClick={handleToggleVersions}>
              <HistoryIcon fontSize="small" className="mr-2" />
              Versions
              {!versionsOpen && <ExpandMoreIcon fontSize="small" className="ml-2" />}
              {versionsOpen && <ExpandLessIcon fontSize="small" className="ml-2" />}
            </Button>
          </ButtonGroup>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() => pathway && publishNewVersion(pathway?.id.toString())}
          >
            <PublishIcon fontSize="small" className="mr-1" />
            Publish
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() => agent && navigate(ROUTE_PATHS.AGENT_PERFORMANCE(agent?.id))}
          >
            <BarChartIcon fontSize="small" className="mr-1" />
            Performance
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() => setSettingsOpen((prev) => !prev)}
          >
            <TuneIcon fontSize="small" className="mr-1" />
            Settings
          </Button>
          <Popper
            sx={{
              zIndex: 1
            }}
            open={versionsOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <Box width="100%">
                    <Typography variant="subtitle2">Previous Versions</Typography>
                    <MenuList id="versions-menu" autoFocusItem>
                      <MenuItem key={pathway.id} onClick={() => {}} className="min-w-[20rem]">
                        <Stack direction="column" className="w-full">
                          <Typography className="font-bold capitalize flex flex-row items-center">
                            <Box
                              className={clsx(
                                'w-2 h-2 rounded-full mr-2',
                                pathway.version_status?.toLowerCase() === 'draft' && 'bg-gray13'
                              )}
                            />
                            {pathway.version_status?.toLowerCase()}
                          </Typography>
                          <Stack direction="row" className="w-full flex items-center">
                            <Stack direction="row" className="w-full">
                              <Stack className="w-[50%] justify-center">
                                {format(
                                  new Date(lastSavedAt ?? pathway.updated_at),
                                  'MMM do, yyyy hh:mm:ss aaaa'
                                )}
                              </Stack>
                              <Stack
                                direction="row"
                                className="w-[50%] gap-0 justify-end items-center"
                              >
                                <IconButton disabled>
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={(e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    pathway.pathway &&
                                      navigator.clipboard.writeText(
                                        JSON.stringify(pathway.pathway)
                                      );
                                    e.stopPropagation();
                                  }}
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={(e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    pathway?.id && handleCopyLink(false, pathway.id);
                                    e.stopPropagation();
                                  }}
                                >
                                  <LinkIcon fontSize="small" />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </MenuItem>
                      {versions?.length > 0 &&
                        versions?.map((version) => (
                          <MenuItem
                            key={version.id}
                            onClick={() => overrideDraft(version)}
                            className="min-w-[20rem]"
                          >
                            <Stack direction="column" className="w-full">
                              <Typography className="font-bold capitalize flex flex-row items-center">
                                <Box
                                  className={clsx(
                                    'w-2 h-2 rounded-full mr-2',
                                    version.version_status?.toLowerCase() === 'archived' &&
                                      'bg-yellow6',
                                    version.version_status?.toLowerCase() === 'published' &&
                                      'bg-green7'
                                  )}
                                />
                                {version.version_status?.toLowerCase()}
                              </Typography>
                              <Stack direction="row" className="w-full flex items-center">
                                <Stack direction="row" className="w-full">
                                  <Stack className="w-[50%] justify-center">
                                    {version.version_ts &&
                                      format(
                                        new Date(version.version_ts),
                                        'MMM dd, yyyy hh:mm:ss aaaa'
                                      )}
                                  </Stack>
                                  <Stack
                                    direction="row"
                                    className="w-[50%] gap-0 justify-end items-center"
                                  >
                                    <IconButton
                                      onClick={(
                                        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
                                      ) => {
                                        handleDeleteVersion(version.id);
                                        e.stopPropagation();
                                      }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                      onClick={(
                                        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
                                      ) => {
                                        version.pathway &&
                                          navigator.clipboard.writeText(
                                            JSON.stringify(version.pathway)
                                          );
                                        e.stopPropagation();
                                      }}
                                    >
                                      <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                      onClick={(
                                        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
                                      ) => {
                                        handleCopyLink(false, version.id);
                                        e.stopPropagation();
                                      }}
                                    >
                                      <LinkIcon fontSize="small" />
                                    </IconButton>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Stack>
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Box>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickToCall}
            disabled={processingCallUrl}
            className="min-w-[7rem]"
          >
            <CallIcon fontSize="small" />
            Test Call
          </Button>
          <Box className="absolute top-12 z-[10000]">
            {callUrl && <Call roomUrl={callUrl} onLeftMeeting={() => setCallUrl(undefined)} />}
          </Box>
        </Box>
      }
    >
      <div className="w-[calc(100vw_-_7rem)] h-[calc(100vh_-_6rem)]" ref={reactFlowWrapper}>
        {pathway && (
          <PathwayBuilder
            initialPathway={pathway}
            onProcessing={(value: boolean) => setProcessing(value)}
            onSaved={() => {
              setLastSavedAt(new Date());
              setSavedFailed(false);
            }}
            onSavedFailed={() => setSavedFailed(true)}
          />
        )}
        {agentId && pathway && settingsOpen && (
          <AgentSettingsModal
            open={settingsOpen}
            settings={{ ...(agent?.settings as any), agent_id: agent?.id }}
            onClose={() => {
              setSettingsOpen(false);
            }}
            onSubmit={(updatedSettings: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { v_phone_numbers, ...agentProps } = agent as any;
              const updatedAgent = {
                ...agentProps,
                settings: updatedSettings
              };
              updateAgent(updatedAgent);
              setAgent((prev) => ({ ...prev!!, settings: updatedSettings }));
            }}
          />
        )}
      </div>
    </ToolbarTemplate>
  );
};

const PathWayPageWithProvider = () => (
  <ReactFlowProvider>
    <PathwayPage />
  </ReactFlowProvider>
);

export default PathWayPageWithProvider;
