import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useCallback } from 'react';
import { useToast } from 'contexts/ToastContext';
import { Database } from 'types/models/db.type';
import { QueryFunctionContext } from 'react-query';

type Tags = Database['public']['Tables']['tags']['Row'];
type GetManyParams = QueryFunctionContext<['tags', { filter: string; organizationId: string }]>;
type GetStatusParams = QueryFunctionContext<['tag_statuses', { organizationId: string }]>;

const useTagsService = () => {
  const { TAGS_TABLE, TAG_STATUS_VIEW } = KBB_SCHEMA;

  const { showToast } = useToast();

  const get = useCallback(
    async (filter: { id: string }) => {
      const { data, error } = await supabaseClient
        .from(TAGS_TABLE)
        .select('*')
        .eq('id', filter.id)
        .single();

      if (error) console.error(error);

      if (!data) return;

      return data;
    },
    [TAGS_TABLE]
  );

  const getMany = useCallback(
    async (params: GetManyParams) => {
      const [, queryParams] = params.queryKey;
      const { filter, organizationId } = queryParams;

      if (!organizationId) return;

      let query = supabaseClient.from(TAGS_TABLE).select('*').eq('organization_id', organizationId);

      if (filter) {
        query = query.or(
          `name.like.%${filter}%,description.like.%${filter}%,examples.like.%${filter}%,llm_additional_instructions.like.%${filter}%`
        );
      }

      let { data, error } = await query.order('id');

      if (error) console.log(error);

      return data;
    },
    [TAGS_TABLE]
  );

  const getUserStatuses = useCallback(
    async (params: GetStatusParams) => {
      const [, queryParams] = params.queryKey;
      const { organizationId } = queryParams;

      if (!organizationId) return;

      let query = supabaseClient
        .from(TAG_STATUS_VIEW)
        .select(`*`, { count: 'exact' })
        .eq('organization_id', organizationId);

      const { data, count } = await query.returns<any>();

      return { data, count };
    },
    [TAG_STATUS_VIEW]
  );

  const put = useCallback(
    async ({ ...tag }: Tags) => {
      try {
        const { data, error } = await supabaseClient
          .from(TAGS_TABLE)
          .update({ ...tag })
          .eq('id', tag.id)
          .single();

        if (error) console.error(error);

        showToast('Tag successfully updated', 'success');

        return data;
      } catch (error) {
        showToast('Error updating tag', 'error');
      }
    },
    [TAGS_TABLE, showToast]
  );

  const post = useCallback(
    async (tag: any) => {
      try {
        const { data, error } = await supabaseClient.from(TAGS_TABLE).insert(tag).select();

        if (error) console.error(error);

        if (error) throw Error('Error adding tag');

        showToast('Tag successfully added', 'success');
        return data[0];
      } catch (error) {
        showToast('Error adding tag', 'error');
      }
    },
    [TAGS_TABLE, showToast]
  );

  const deleteItem = useCallback(
    async (id: string) => {
      try {
        const { error } = await supabaseClient.from(TAGS_TABLE).delete().eq('id', id);

        if (error) console.error(error);

        showToast('Tag successfully deleted', 'success');
      } catch (error) {
        showToast('Error deleting tag', 'error');
      }
    },
    [TAGS_TABLE, showToast]
  );

  return { get, getMany, getUserStatuses, put, post, deleteItem };
};

export default useTagsService;
