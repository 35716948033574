import { Session, User } from '@supabase/supabase-js';
import { Dispatch, ReactNode, createContext, useReducer } from 'react';
import { UserActions, userReducer } from './reducers';

export interface UserContextType {
  user?: User;
  session?: Session;
}

export type UserDispatchContextType = Dispatch<UserActions>;

interface UserContextProviderProps {
  children: ReactNode;
}

const getSessionFromLocal = () =>
  localStorage.getItem('kbbSession')
    ? (JSON.parse(localStorage.getItem('kbbSession') ?? '') as Session)
    : undefined;

const initialState: UserContextType = {
  session: getSessionFromLocal(),
  user: getSessionFromLocal()?.user
};

const initialDispatchState: UserDispatchContextType = () => {};

export const UserContext = createContext<UserContextType>(initialState);
export const UserDispatchContext = createContext<UserDispatchContextType>(initialDispatchState);

const mainReducer = ({ user, session }: UserContextType, action: UserActions) => ({
  ...userReducer({ user, session }, action as UserActions)
});

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <UserContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};
