export enum SimilarityScore {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  'No Score' = 'no-score',
  'Not applicable' = 'not-applicable'
}

export interface Message {
  id: number;
  conversationId: string;
  organizationId: string;
  messageId: string;
  messageUrl: string;
  messageType: string;
  messageAuthorHandle: string;
  messageAuthorType: string;
  subject: string;
  text: string;
  messageDate: string;
  messageMetadata: {
    inbox: string;
  };
}

export interface Tag {
  id: number;
  name: string;
  slug: string;
  tier: number;
  examples: string;
  llmName: string;
  description: string;
  userStatus: string;
  priorityOrder: number;
  organizationId: string;
  showDraftAuto: boolean;
  canBeDeflected: boolean;
  isMutuallyExclusive: boolean;
  llmAdditionalInstructions: string;
}

export interface Conversation extends LLMResponse {
  agentResponseAuthorHandle: string;
  agentResponseText: string;
  customerMessageAuthorHandle: string;
  customerMessageText: string;
  llmResponseJson: LLMResponseJson;
  source: string;
  count?: number;
}

export interface Citation {
  url: string;
  name: string;
  text: string;
  type: string;
  category: string;
  urlDescription: string;
  shortDescription: string;
}

export interface LLMResponseJson {
  text: string;
  tags: Tag[];
  tools: string[];
  citations: Citation[];
  confidenceScore: string;
  similarityScore: string;
}

export interface LLMResponse {
  conversationId: string;
  organizationId: string;
  customerMessageId: string;
  agentResponseId: string;
  isAutomaticResponse: boolean;
  llmResponseJson: LLMResponseJson;
  similarityScore: string;
  customerMessageDate: string;
  llmResponseCreatedAt: string;
  createdAt: string;
}

export default interface Evaluations {
  id: number;
  conversationId: string;
  organizationId: string;
  customerMessageId: string;
  agentResponseId: string;
  isAutomaticResponse: boolean;
  similarityScore: string;
  llmResponseJson: LLMResponseJson;
  rtaMessages: Message[];
  count?: number;
}
