import { BaseTemplate, CleanTemplate } from 'components/templates';
import { EvaluatorPage, Page404 } from 'pages';
import ActionsPage from 'pages/ActionsPage';
import AnalyticsPage from 'pages/AnalyticsPage';
import AuthPage from 'pages/AuthPage';
import FAQArticlePage from 'pages/FAQArticlePage';
import FAQsPage from 'pages/FAQsPage';
import SourcesPage from 'pages/SourcesPage';
import TaggerPage from 'pages/TaggerPage';
import { Navigate, useRoutes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import CategoriesPage from 'pages/CategoriesPage';
import ROUTE_PATHS from './paths';
import EmailConfirmationPage from 'pages/EmailConfirmationPage';
import PasswordResetPage from 'pages/PasswordReset';
import SlackCallback from 'components/organisms/sources-service/SlackCallback';
import SlackAuthorizedPage from 'pages/SlackAuthorizedPage';
import AgentsPage from 'pages/AgentsPage';
import { useUser } from 'hooks/reducers';
import PathwayPage from 'pages/PathwayPage';
import CallLogsPage from 'pages/CallLogsPage';
import AgentPerformancePage from 'pages/AgentPerformancePage';
import { ReactNode } from 'react';

const EvaluatorRoute = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();

  if (!user?.user_metadata?.organizations.evaluatorEnabled) {
    return <Navigate to={ROUTE_PATHS.AUTH} replace />;
  }

  return <>{children}</>;
};

const Router = (): React.ReactElement | null => {
  return useRoutes([
    {
      path: ROUTE_PATHS.PASSWORD_RESET,
      element: <PasswordResetPage />
    },
    {
      path: ROUTE_PATHS.EMAIL_CONFIRMATION,
      element: <EmailConfirmationPage />
    },
    {
      path: ROUTE_PATHS.AUTH,
      element: <AuthPage />,
      errorElement: <Page404 />
    },
    {
      path: ROUTE_PATHS.EVALUATOR,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <EvaluatorRoute>
                <EvaluatorPage />
              </EvaluatorRoute>
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.FAQ_LIST_PAGE,
      element: <BaseTemplate gaps={false} />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <FAQsPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.FAQ_DETAILS_PAGE(),
      element: <BaseTemplate gaps={false} />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <FAQsPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.SOURCES,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <SourcesPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.SOURCES_CALLBACK,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: <SlackCallback />
        }
      ]
    },
    {
      path: ROUTE_PATHS.ACTIONS,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <ActionsPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.TAGS,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <TaggerPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.FAQ_ARTICLE_PAGE(),
      element: <CleanTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <FAQArticlePage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.ANALYTICS,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <AnalyticsPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.CATEGORIES,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <CategoriesPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.SLACK_AUTHORIZED,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <SlackAuthorizedPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.AGENTS,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <AgentsPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.AGENT_PERFORMANCE(),
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <AgentPerformancePage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.CALL_LOGS,
      element: <BaseTemplate />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <CallLogsPage />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROUTE_PATHS.PATHWAY,
      element: <BaseTemplate horizontalGlutter={false} />,
      children: [
        {
          path: '',
          element: (
            <PrivateRoute>
              <PathwayPage />
            </PrivateRoute>
          )
        }
      ]
    }
  ]);
};

export default Router;
