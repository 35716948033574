import { Theme } from '@mui/material/styles';
import Table from './Table';
import Dialog from './Dialog';
import Select from './Select';
import Button from './Button';
import Paper from './Paper';
import Typography from './Typography';
import TextField from './TextField';
import Chip from './Chip';
import Tabs from './Tabs';

export default function ComponentsOverrides(theme: Theme, rootElement: HTMLElement): object {
  return {
    ...Button(theme),
    ...Chip(theme),
    ...Table(theme),
    ...Tabs(theme),
    // Dialog, Modals, PopOver and Tooltip require rootElement reference in order for MUI to detect tailwind css classes, more at:
    // https://github.com/mui/material-ui/issues/36390
    // https://github.com/mui/material-ui/issues/33424
    ...Dialog(theme, rootElement),
    ...Select(theme),
    ...Paper(theme),
    ...Typography(theme),
    ...TextField(theme)
  };
}
