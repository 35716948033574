import { Box, Divider, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReplyIcon } from 'components/atoms';
import { format } from 'date-fns';
import { Conversation } from 'types/models/evaluations.model';

interface ConversationItemProps extends Conversation {
  active: boolean;
  onClick: VoidFunction;
}

const ConversationItem = ({
  active,
  agentResponseId,
  agentResponseAuthorHandle,
  agentResponseText,
  customerMessageDate,
  customerMessageAuthorHandle,
  source,
  onClick
}: ConversationItemProps) => {
  return (
    <Stack
      direction="column"
      className={clsx(
        'pt-3 pr-5 pl-5 cursor-pointer',
        active && 'border-l-1 border-t-0 border-b-0 border-r-0 border-solid border-orange bg-pink'
      )}
      onClick={onClick}
    >
      <Stack direction="row" className="flex items-center pb-2">
        <Box className="w-full flex justify-between">
          <Typography variant="caption">
            {format(
              customerMessageDate ? new Date(customerMessageDate) : new Date(),
              'dd MMM, yyyy'
            )}
          </Typography>
          {source && (
            <Typography
              variant="caption"
              className="flex items-center relative before:content-[''] before:flex before:align-middle before:absolute before:left-[-1rem] before:!m-0 before:w-[3px] before:h-[3px] before:bg-gray before:rounded-full"
            >
              {source}
            </Typography>
          )}
        </Box>
      </Stack>
      {agentResponseAuthorHandle && (
        <Typography variant="subtitle1">{agentResponseAuthorHandle}</Typography>
      )}
      {customerMessageAuthorHandle && (
        <Stack direction="row">
          <ReplyIcon />
          <Typography variant="body2">{customerMessageAuthorHandle}</Typography>
        </Stack>
      )}
      {agentResponseText && (
        <Typography variant="body2" className="mt-[6px] text-wrap break-words	">
          {agentResponseText}
        </Typography>
      )}
      <Divider className="pt-5" />
    </Stack>
  );
};

export default ConversationItem;
