import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useCallback } from 'react';
import { ContentSnippet } from 'types/models';
import { Tables } from 'types/models/db.type';
import { APIService } from 'types/services';
import { BaseAdapter } from '../adapters';
import { useUser } from 'hooks/reducers';

type ContentSnippetService = Omit<APIService<ContentSnippet>, 'getMany'> & {
  generate: (
    faqId: string,
    question: string,
    answer: string,
    originalContent: string
  ) => Promise<any>;
};

const useContentSnippetService = (): ContentSnippetService => {
  const { adapt, reverse } = BaseAdapter<Tables<'kbb_content_snippets'>, ContentSnippet>();
  const { CONTENT_SNIPPET_TABLE } = KBB_SCHEMA;
  const user = useUser();

  const get = useCallback(
    async (filter: { faqId: string }) => {
      let query = supabaseClient.from(CONTENT_SNIPPET_TABLE).select('*');

      if (filter.faqId) {
        query = query.eq('faq_id', filter.faqId);
      }

      const { data, error } = await query
        .order('created_at', { ascending: false })
        .limit(1)
        .returns<Tables<'kbb_content_snippets'>[]>();

      if (error) console.error(error);

      if (!data?.length) return;

      return adapt(data[0]);
    },
    [adapt, CONTENT_SNIPPET_TABLE]
  );

  const post = useCallback(
    async (contentSnippet: ContentSnippet) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, created_at, ...item } = reverse(contentSnippet);

      const { data, error } = await supabaseClient
        .from(CONTENT_SNIPPET_TABLE)
        .insert(item)
        .select();

      if (error) console.error(error);

      if (!data) return;

      return data?.map((item) => adapt(item));
    },
    [CONTENT_SNIPPET_TABLE, adapt, reverse]
  );

  const put = useCallback(
    async (contentSnippet: ContentSnippet) => {
      const { data, error } = await supabaseClient
        .from(CONTENT_SNIPPET_TABLE)
        .update({
          content: contentSnippet.content
        })
        .eq('id', contentSnippet.id)
        .select();

      if (error) console.error(error);

      return data?.map((item) => adapt(item));
    },
    [CONTENT_SNIPPET_TABLE, adapt]
  );

  const generate = useCallback(
    async (faqId: string, question: string, answer: string, originalContent: string) => {
      const response = await fetch(
        `${process.env.REACT_APP_LLM_SERVICE_URL}/assistant/generate_content`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-User-Id': user.user?.id ?? '',
            'X-Organization': user?.user?.user_metadata?.organizations.id ?? '',
            'X-Api-Key': user?.user?.user_metadata?.organizations.apiKey ?? ''
          },
          body: JSON.stringify({
            faq_id: faqId,
            question,
            answer,
            original_content: originalContent
          })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to generate content');
      }

      const data = await response.json();
      return data;
    },
    [user]
  );

  return { generate, get, post, put };
};

export default useContentSnippetService;
