import {
  UserContext,
  UserContextType,
  UserDispatchContext,
  UserDispatchContextType
} from '../../contexts/UserContext';
import { useContext } from 'react';

export const useUser = (): UserContextType => useContext(UserContext);

export const useUserDispatch = (): UserDispatchContextType => useContext(UserDispatchContext);
