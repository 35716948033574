import camelcaseKeys from 'camelcase-keys';
import decamelizeKeys from 'decamelize-keys';
import { useCallback } from 'react';
import Adapter from 'types/adapter.type';

const BaseAdapter = <
  TInput extends Record<string, any> | readonly any[],
  TOutput extends Record<string, any> | readonly any[]
>(): Adapter<TInput, TOutput> => {
  const adapt = useCallback(
    (item: TInput): TOutput => camelcaseKeys(item, { deep: true }) as unknown as TOutput,
    []
  );

  const reverse = useCallback(
    (item: TOutput): TInput => decamelizeKeys(item) as unknown as TInput,
    []
  );
  return { adapt, reverse };
};

export default BaseAdapter;
