import { Button, Chip, CircularProgress, IconButton, Link, Popover } from '@mui/material';
import { InnerPageTemplate } from 'components/templates';
import { TableColumn } from 'types';
import DeleteIcon from '@mui/icons-material/Delete';
import { SimpleTable } from 'components/organisms';
import { useEffect, useState } from 'react';
import SourcesModal from 'components/organisms/sources-service/SourcesModal';
import useSourcesService from 'hooks/services/sources-service/sources.service';
import DeleteDialog from 'components/organisms/sources-service/DeleteDialog';
import { Autorenew, InsertLink } from '@mui/icons-material';
import { useUser } from 'hooks/reducers';
import { dateFormater } from 'utils';
import { useFeatureFlag } from 'hooks';
import Loader from 'components/molecules/loader/Loader';
import SlackSource from 'components/organisms/sources-service/SlackSource';

const SourcesPage = () => {
  const { isDisabled } = useFeatureFlag();
  const [source, setSource] = useState({ sourceId: '', providerConfigKey: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [sources, setSources] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopper, setOpenPopper] = useState<{ [key: string]: boolean }>({});
  const [showSlackSetup, setShowSlackSetup] = useState(false);

  const { deleteConnection, fetchData, triggerFlow, isLoading, checkSlackConnection } =
    useSourcesService();

  const { user } = useUser();

  const getStatusChip = (status: string) => {
    switch (status) {
      case 'running':
        return <Chip label="Running" color="primary" className="bg-yellow3 text-black" />;
      case 'failed':
        return <Chip label="Failed" color="error" />;
      case 'crashed':
        return <Chip label="Crashed" color="error" />;
      case 'pending':
        return <Chip label="Pending" color="warning" />;
      case 'scheduled':
        return <Chip label="Scheduled" color="info" />;
      case 'completed':
        return <Chip label="Completed" color="success" />;
      case 'paused':
        return <Chip label="Paused" color="info" />;
      default:
        return (
          status && (
            <Chip label={status.charAt(0).toUpperCase() + status.slice(1)} color="primary" />
          )
        );
    }
  };

  const handlePopoverOpen = (cellId: string, event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenPopper((prevState) => ({
      ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),
      [cellId]: !prevState[cellId]
    }));
  };

  const handlePopoverClose = (id: string) => {
    setAnchorEl(null);
    setOpenPopper((prevState) => ({ ...prevState, [`${id}`]: false }));
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    checkSlackConnection(user?.user_metadata.organizationId).then((isSlackConnected) => {
      setShowSlackSetup(!isSlackConnected);
    });
    fetchData(user?.user_metadata.organizationId).then((data) => setSources(data));
  }, [fetchData, user?.user_metadata.organizationId, checkSlackConnection]);

  const handleDeleteSource = async () => {
    if (!source.sourceId || !source.providerConfigKey) return;
    await deleteConnection(source.sourceId, source.providerConfigKey).then(() =>
      fetchData(user?.user_metadata.organizationId).then((data) => setSources(data))
    );
    setIsDeleteDialogOpen(false);
  };

  const onDeleteClick = (sourceId: string, providerConfigKey: string) => {
    setSource({ sourceId, providerConfigKey });
    setIsDeleteDialogOpen(true);
  };

  const onModalClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      fetchData(user?.user_metadata.organizationId).then((data) => setSources(data));
    }, 3000);
  };

  const getDetailPrefix = (sourceType: string) => {
    switch (sourceType) {
      case 'pdf':
        return 'files';
      case 'clickup':
        return 'documents';
      case 'google-drive':
        return 'folders';
      case 'confluence':
        return 'spaces';
      case 'jira':
        return 'projects';
      default:
        return '';
    }
  };

  const cleanSourceType = (sourceType: string) => {
    const splitedWords = sourceType.split('-');
    if (splitedWords.length > 1) {
      return splitedWords.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
    return sourceType.charAt(0).toUpperCase() + sourceType.slice(1);
  };

  const cols: TableColumn<{
    id: string;
    source_type: string;
    details: string[];
    updated_at: string;
    status: string;
    connection_id: string;
    deployment_id: string | null;
    num_documents: number | null;
    metadata: { [key: string]: string };
  }>[] = [
    {
      id: 'source',
      label: 'Source',

      renderer: ({ source_type }: { source_type: string }) => (
        <span>{cleanSourceType(source_type)}</span>
      )
    },
    {
      id: 'details',
      label: 'Details',
      renderer: ({
        id,
        source_type,
        metadata
      }: {
        details: string[];
        id: string;
        source_type: string;
        metadata: any;
      }) => (
        <>
          <span
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-describedby={id}
            className={metadata.buckets_count ? 'cursor-pointer underline decoration-dotted' : ''}
            onClick={(e) => handlePopoverOpen(`${id}-details`, e)}
          >
            {metadata?.buckets_count
              ? `${metadata?.buckets_count} ${getDetailPrefix(source_type)}`
              : '-'}
          </span>
          {metadata.buckets_count && (
            <Popover
              id="mouse-over-popover"
              open={openPopper[`${id}-details`]}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              onClose={() => handlePopoverClose(`${id}-details`)}
              disableRestoreFocus
            >
              <div className="overflow-auto text-xs">
                <ul className="p-2 w-100 m-5 break-all">
                  {metadata?.buckets_items?.map(
                    (item: any, index: number) =>
                      item && (
                        <li key={index} className="flex items-center">
                          <span>{item.name}</span>
                          {item.url && (
                            <Link href={item.url} target="_blank" className="">
                              <InsertLink className="ml-2 " />
                            </Link>
                          )}
                        </li>
                      )
                  )}
                </ul>
              </div>
            </Popover>
          )}
        </>
      )
    },
    {
      id: 'documents',
      label: 'Documents',
      renderer: ({
        id,
        metadata
      }: {
        details: string[];
        id: string;
        source_type: string;
        metadata: any;
      }) => (
        <>
          <span
            aria-owns={open ? 'mouse-over-popover-docs' : undefined}
            className={metadata.files_count ? 'cursor-pointer underline decoration-dotted' : ''}
            aria-describedby={id}
            onClick={(e) => handlePopoverOpen(id, e)}
          >
            {metadata?.files_count ? `${metadata?.files_count}` : '-'}
          </span>
          {metadata.files_count !== 0 && (
            <Popover
              id="mouse-over-popover-docs"
              open={openPopper[`${id}`]}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              onClose={() => handlePopoverClose(id)}
              disableRestoreFocus
            >
              <div className="overflow-auto text-xs">
                <ul className="p-2 w-100 m-5 break-all">
                  {metadata?.files_items?.map(
                    (item: any, index: number) =>
                      item && (
                        <li key={index} className="flex items-center">
                          <span>{item.name}</span>
                          {item.url && (
                            <Link href={item.url} target="_blank" className="">
                              <InsertLink className="ml-2 " />
                            </Link>
                          )}
                        </li>
                      )
                  )}
                </ul>
              </div>
            </Popover>
          )}
        </>
      )
    },
    {
      id: 'last_sync',
      label: 'Last Sync',
      renderer: ({ updated_at }: { updated_at: string }) => (
        <span>{updated_at ? dateFormater(updated_at) : '-'}</span>
      )
    },
    {
      id: 'status',
      label: 'Status',
      renderer: ({ status }: { status: string }) => (
        <span>
          {status !== 'UNKNOWN' ? getStatusChip(status ? status?.toLowerCase() : '') : '-'}
        </span>
      )
    },
    {
      id: 'actions',
      label: 'Actions',
      renderer: ({
        connection_id,
        source_type,
        status,
        deployment_id
      }: {
        connection_id: string;
        source_type: string;
        status: string;
        details: string[];
        deployment_id: string | null;
      }) => (
        <div>
          {deployment_id && source_type !== 'pdf' && (
            <IconButton
              disabled={status?.toLowerCase() === 'running' || status?.toLowerCase() === 'pending'}
              onClick={() =>
                triggerFlow(deployment_id!).then(() =>
                  fetchData(user?.user_metadata.organizationId).then((data) => setSources(data))
                )
              }
            >
              {status?.toLowerCase() === 'running' || status?.toLowerCase() === 'pending' ? (
                <CircularProgress size={24} />
              ) : (
                <Autorenew fontSize="small" />
              )}
            </IconButton>
          )}
          <IconButton
            disabled={isDisabled}
            onClick={() => {
              onDeleteClick(connection_id, source_type);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      )
    }
  ];

  return (
    <InnerPageTemplate header="Sources">
      {showSlackSetup ? (
        <SlackSource />
      ) : (
        <div className="flex flex-col gap-4">
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="flex self-end w-32"
            onClick={() => setIsOpen(true)}
          >
            Add Source
          </Button>
          <SimpleTable columns={cols} rows={sources! || []} />
          <SourcesModal isOpen={isOpen} onModalClose={onModalClose} />
        </div>
      )}

      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteSource}
      />
      <Loader isLoading={isLoading} />
    </InnerPageTemplate>
  );
};

export default SourcesPage;
