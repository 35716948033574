export enum Score {
  All = 'all',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  'No Score' = 'no-score',
  'Not Applicable' = 'not-applicable'
}

export enum Sort {
  Ascending = 'asc',
  Descending = 'desc'
}

export enum Status {
  All = 'all',
  Reviewed = 'true',
  Pending = 'false'
}

export default interface EvaluationsFilter {
  inbox?: string | undefined;
  tags?: string[] | undefined;
  score?: string | undefined;
  category?: string | undefined;
  sort?: string | undefined;
  organizationId: string | undefined;
  period?: string | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
  reviewed?: string | undefined;
  text?: string | undefined;
  page?: number | undefined;
  limit?: number | undefined;
}
