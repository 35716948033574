import { Stack, Typography } from '@mui/material';
import { Citation } from 'components/molecules';
import { Citation as CitationType } from 'types/models/evaluations.model';

interface CitationsManagerProps {
  citations?: CitationType[];
}

const CitationsManager = ({ citations }: CitationsManagerProps) => {
  return (
    <Stack className="h-full pt-6 pr-4 pb-6 pl-4">
      <Stack direction="row" className="w-full justify-between">
        <Typography variant="subtitle1">Citations</Typography>
      </Stack>
      {citations?.map((item, idx) => <Citation key={idx} citation={item} />)}
    </Stack>
  );
};

export default CitationsManager;
