import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useCallback } from 'react';
import { Tables } from 'types/models/db.type';
import { useToast } from 'contexts/ToastContext';
import { BaseAdapter } from '../adapters';
import { Action } from 'types/models';
import { ActionRequest } from 'types/models/action.model';

const useActionsService = () => {
  const { adapt, reverse } = BaseAdapter<Tables<'actions'>, Action>();
  const { ACTIONS_TABLE } = KBB_SCHEMA;

  const { showToast } = useToast();

  const get = useCallback(
    async (filter: { id: string }) => {
      let query = supabaseClient.from(ACTIONS_TABLE).select('*').eq('id', filter.id);

      const { data, error } = await query.single<Tables<'actions'>>();

      if (error) console.error(error);

      if (!data) return;

      return adapt(data);
    },
    [adapt, ACTIONS_TABLE]
  );

  const getMany = useCallback(
    async (organizationId: string) => {
      if (!organizationId) return;

      let { data, error } = await supabaseClient
        .from(ACTIONS_TABLE)
        .select('*')
        .eq('organization_id', organizationId);

      if (error) console.log(error);

      return data?.map((item) => adapt(item));
    },
    [ACTIONS_TABLE, adapt]
  );

  const put = useCallback(
    async ({ ...action }: Action) => {
      try {
        const item = reverse(action);

        const { data, error } = await supabaseClient
          .from(ACTIONS_TABLE)
          .update({ ...item })
          .eq('id', action.id)
          .select();

        if (error) console.error(error);

        if (!data) return;

        showToast('Action successfully updated', 'success');

        return data.map((item) => adapt(item));
      } catch (error) {
        showToast('Error updating action', 'error');
      }
    },
    [reverse, ACTIONS_TABLE, showToast, adapt]
  );

  const post = useCallback(
    async (action: ActionRequest) => {
      try {
        const item = reverse(action as Action);

        const { data, error } = await supabaseClient.from(ACTIONS_TABLE).insert(item).select();

        if (error) console.error(error);

        if (!data) return;

        showToast('Action successfully added', 'success');
        return data?.map((item) => adapt(item));
      } catch (error) {
        showToast('Error adding action', 'error');
      }
    },
    [reverse, ACTIONS_TABLE, showToast, adapt]
  );

  const postWithSwagger = useCallback(
    async (swaggerURL: string, organizationId: string) => {
      try {
        if (swaggerURL.endsWith('/')) {
          swaggerURL = swaggerURL.slice(0, -1);
        }
        const { data, error } = await supabaseClient.functions.invoke('post-swagger-data', {
          body: { swaggerURL, organizationId }
        });

        if (error) throw new Error(error.message);
        if (data) {
          showToast('Swagger import added successfully ', 'success');
        }
      } catch (error) {
        console.error(error);
        showToast(`Error importing Swagger`, 'error');
      }
    },
    [showToast]
  );

  const deleteItem = useCallback(
    async (id: string) => {
      try {
        const { error } = await supabaseClient.from(ACTIONS_TABLE).delete().eq('id', id);

        if (error) console.error(error);

        showToast('Action successfully deleted', 'success');
      } catch (error) {
        showToast('Error deleting action', 'error');
      }
    },
    [ACTIONS_TABLE, showToast]
  );

  return { get, getMany, put, post, deleteItem, postWithSwagger };
};

export default useActionsService;
