import DailyIframe, {
  DailyCall,
  DailyEventObjectNoPayload,
  DailyParticipantsObject
} from '@daily-co/daily-js';
import { useCallback, useEffect, useRef, useState } from 'react';

const Call = ({ roomUrl, onLeftMeeting }: { roomUrl: string; onLeftMeeting: VoidFunction }) => {
  const callFrameRef = useRef<DailyCall>();
  const [participants, setParticipants] = useState({} as DailyParticipantsObject);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (callFrameRef.current !== undefined && callFrameRef.current !== null) return;

    callFrameRef.current = DailyIframe.createFrame(
      document.getElementById('prosper-call-container')!!,
      {
        showLeaveButton: true,
        showLocalVideo: false,
        iframeStyle: {
          // position: 'absolute',
          // top: '0',
          // right: '0',
          zIndex: '10000',
          width: '30rem',
          height: '40rem'
        }
      }
    );

    callFrameRef.current.join({ url: roomUrl });

    callFrameRef.current.on('participant-joined', (event) => {
      setParticipants((prevParticipants) => ({
        ...prevParticipants,
        [event.participant.session_id]: event.participant
      }));
    });

    callFrameRef.current.on('participant-left', (event) => {
      setParticipants((prevParticipants) => {
        const updatedParticipants = { ...prevParticipants };
        delete updatedParticipants[event.participant.session_id];
        return updatedParticipants;
      });
    });

    callFrameRef.current.on('left-meeting', (event: DailyEventObjectNoPayload) => {
      onLeftMeeting();
    });

    return () => {
      callFrameRef.current?.destroy();
    };
  }, [roomUrl]);

  const handleMute = useCallback(() => {
    const newMuteState = !isMuted;
    callFrameRef.current?.setLocalAudio(newMuteState);
    setIsMuted(newMuteState);
  }, []);

  const handleLeave = () => {
    callFrameRef.current?.leave();
  };

  return <div id="prosper-call-container"></div>;
};

export default Call;
