import { EditOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { PageHeader, StatusBadge } from 'components/atoms';
import { DateRangeField, SearchField, StatusSelect } from 'components/molecules';
import { CustomTreeView, SimpleTable } from 'components/organisms';
import FAQModal from 'components/organisms/modals/faq-modal/FAQModal';
import { format } from 'date-fns';
import {
  useConfirmationDialog,
  useDebounce,
  useFAQService,
  useFAQStatusService,
  useFeatureFlag
} from 'hooks';
import { useUser } from 'hooks/reducers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RangeKeyDict } from 'react-date-range';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ROUTE_PATHS from 'routes/paths';
import { CategoryTreeItem, FAQFilter, Status, TableColumn } from 'types';
import { FAQ, FAQStatus } from 'types/models';

const FAQsPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { debounce } = useDebounce();
  const [searchParams, setSearchParams] = useSearchParams();
  const { openDialog } = useConfirmationDialog();
  const { isDisabled } = useFeatureFlag();
  const {
    get: getFAQ,
    getMany: getFAQs,
    getSources,
    getCategories,
    getUncategorizedCount,
    deleteItem,
    put: updateFAQ,
    exportFiltered
  } = useFAQService();
  const { getStatusesFAQCount } = useFAQStatusService();
  const [question, setQuestion] = useState<string>(searchParams.get('question') ?? '');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [categoriesTree, setCategoriesTree] = useState<CategoryTreeItem[]>();
  const selectedCategory = searchParams.get('category') ?? '';
  const [statuses, setStatuses] = useState<FAQStatus[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [totalNone, setTotalNone] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>();
  const { faqId } = useParams();

  const open = Boolean(anchorEl);

  const defaultCategories: CategoryTreeItem[] = useMemo(
    () => [
      {
        id: 'all',
        name: 'All',
        label: 'All',
        count: 0,
        children: []
      } as CategoryTreeItem,
      {
        id: 'none',
        name: 'None',
        label: totalNone > 0 ? `None (${totalNone})` : 'None',
        count: 0,
        children: []
      } as CategoryTreeItem
    ],
    [totalNone]
  );

  const [faq, setFAQ] = useState<FAQ>();

  const [filter, setFilter] = useState<FAQFilter>({
    unanswered: searchParams.get('unanswered') === 'true',
    question: searchParams.get('question') ?? '',
    category: searchParams.get('category') ?? 'all',
    status: searchParams.get('status') ?? 'all',
    source: searchParams.get('source') ?? '',
    period: searchParams.get('period') ?? '',
    organizationId: user?.user_metadata.organizationId,
    startDate: searchParams.get('startDate') ?? undefined,
    endDate: searchParams.get('endDate') ?? undefined
  });

  const { refetch: refetchStatuses } = useQuery({
    queryKey: ['faqs_statuses', filter],
    queryFn: getStatusesFAQCount,
    onSuccess: (data: FAQStatus[] | undefined) => {
      if (data) {
        setStatuses(data);
        const total = data.reduce((acc, item) => acc + item.kbbFaqs[0].count, 0);
        setTotal(total);
      }
    }
  });

  const { refetch } = useQuery({
    queryKey: ['faqs', filter],
    queryFn: getFAQs,
    enabled: false,
    onSuccess: (data: FAQ[] | undefined) => {
      if (data) {
        setFaqs(data);
      }
    }
  });

  const sources = useQuery(
    ['faq_unique_sources', { organizationId: user?.user_metadata.organizationId }],
    getSources
  );

  const { data: categoriesData, refetch: refetchCategories } = useQuery(
    ['faqs_categories', filter],
    getCategories
  );

  const { refetch: refechUncategorizedCount } = useQuery({
    queryKey: ['faqs_uncategorized', filter],
    queryFn: getUncategorizedCount,
    onSuccess: (data: number) => {
      setTotalNone(data);
    }
  });

  const updateMutation = useMutation({
    mutationKey: 'updateFAQCategory',
    mutationFn: (faq: FAQ) => updateFAQ(faq),
    onSuccess: (data: void | FAQ[] | undefined) => {
      if (data) {
        setFaqs((prevState) => {
          return prevState?.map((item) => {
            if (item.id === data[0].id) {
              return data[0];
            }
            return item;
          });
        });
      }
    }
  });

  const deleteMutation = useMutation({
    mutationKey: 'deleteFAQ',
    mutationFn: (id: number) => deleteItem(id),
    onSuccess: (data: void | FAQ[] | undefined) => {
      if (data) {
        refetch();
        refetchStatuses();
        refetchCategories();
        refechUncategorizedCount();
      }
    }
  });

  const updateSearchParamsAndFilter = useCallback(
    (field: keyof FAQFilter, value: string) => {
      setSearchParams((params) => {
        params.set(field, value);
        return params;
      });

      setFilter((prevState) => ({
        ...prevState,
        [field]: value
      }));
    },
    [setSearchParams]
  );

  const setSearchParamDebounced = useMemo(
    () =>
      debounce((field: keyof FAQFilter, value: string) => {
        updateSearchParamsAndFilter(field, value);
      }, 300),
    [debounce, updateSearchParamsAndFilter]
  );

  const handleFilterChange = useCallback(
    (field: keyof FAQFilter, value: any, effect: 'immediate' | 'debounced' = 'immediate') => {
      if (effect === 'immediate') {
        updateSearchParamsAndFilter(field, value);
      } else {
        setSearchParamDebounced(field, value);
      }
    },
    [setSearchParamDebounced, updateSearchParamsAndFilter]
  );

  const handlePeriodChange = useCallback(
    (value: string | undefined) => {
      setFilter((prevState) => ({
        ...prevState,
        period: value
      }));

      if (!value) {
        setSearchParams((params) => {
          params.delete('period');
          return params;
        });
      } else {
        setSearchParams((params) => {
          params.set('period', value);
          return params;
        });
      }
    },
    [setSearchParams]
  );

  const handleDateRangeChange = useCallback(
    (range: RangeKeyDict | undefined) => {
      if (!range?.selection) {
        setFilter((prevState) => ({
          ...prevState,
          startDate: undefined,
          endDate: undefined
        }));

        setSearchParams((params) => {
          params.delete('startDate');
          params.delete('endDate');
          return params;
        });
      } else {
        const { startDate, endDate } = range.selection;

        if (!startDate || !endDate) {
          return;
        }

        setFilter((prevState) => ({
          ...prevState,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }));
        setSearchParams((params) => {
          params.set('startDate', startDate.toISOString());
          params.set('endDate', endDate.toISOString());
          return params;
        });
      }
    },
    [setSearchParams]
  );

  const handleQuestionChange = useCallback((value: string) => {
    setQuestion(value);
  }, []);

  const handleDelete = useCallback(
    (faq: FAQ) => {
      openDialog('Delete FAQ', 'Are you sure to delete the FAQ?', () =>
        deleteMutation.mutate(faq.id)
      );
    },
    [deleteMutation, openDialog]
  );

  const handleEdit = useCallback((faq: FAQ) => {
    setFAQ(faq);
    setShowDialog(true);
  }, []);

  const handleArticle = useCallback(
    (faq: FAQ) => {
      navigate(ROUTE_PATHS.FAQ_ARTICLE_PAGE(faq.id.toString()));
    },
    [navigate]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cols: TableColumn<FAQ>[] = [
    {
      id: 'question',
      label: 'question',
      renderer: ({ question, answer }: FAQ) => (
        <div className="line-clamp-2 text-ellipsis">
          <div>
            <div className="font-small">
              <span className="font-extrabold">Q:</span>
              {question}
            </div>
            {answer && (
              <div className="text-gray font-small mt-2">
                <span className="font-extrabold">A:</span>
                {answer}
              </div>
            )}
          </div>
        </div>
      )
    },
    {
      id: 'createdAt',
      cellProps: { width: '8%' },
      label: 'created at',
      renderer: ({ createdAt }: FAQ) => {
        if (!createdAt) return <></>;

        const formattedDate = format(new Date(createdAt), 'MMMM do, yyyy');

        return <>{formattedDate}</>;
      }
    },
    {
      id: 'status',
      label: 'Status',
      renderer: (faq: FAQ) => (
        <StatusSelect
          id="status-select"
          variant="outlined"
          fullWidth
          allowEmptyValue={false}
          value={faq.statusId}
          onChange={(e) => updateMutation.mutate({ ...faq, statusId: e.target.value as number })}
        />
      ),
      cellProps: { width: '8%' }
    },
    {
      id: 'source',
      label: 'Source',
      renderer: ({ source }: FAQ) => <>{source}</>,
      cellProps: { width: '5%' }
    },
    // {
    //   id: 'author',
    //   label: 'Author',
    //   renderer: ({ author_email }: FAQ) => <>{author_email}</>
    // },
    // {
    //   id: 'viewsCount',
    //   label: 'Times Used'
    // },
    {
      id: 'actions',
      label: 'Actions',
      component: () => (
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            // PaperProps={{
            //   style: {
            //     width: '20ch'
            //   }
            // }}
          >
            <MenuItem
              onClick={() => {
                exportFiltered(filter);
                handleClose();
              }}
            >
              Export
            </MenuItem>
          </Menu>
        </div>
      ),
      cellProps: {
        width: '5%',
        align: 'right'
        // sx: { padding: '0 !important', justifyContent: 'right' }
      },
      renderer: (faq: FAQ) => (
        <Box className="w-full flex justify-end">
          <Tooltip title="Content Snippet" placement="top">
            <IconButton
              aria-label="Content Snippet"
              onClick={() => handleArticle(faq)}
              disabled={isDisabled}
            >
              <TextSnippetIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit FAQ" placement="top">
            <IconButton aria-label="Edit" onClick={() => handleEdit(faq)} className="p-0">
              <EditOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete FAQ" placement="top">
            <IconButton aria-label="Delete FAQ" onClick={() => handleDelete(faq)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  ];

  const handleToggle = (itemId: string) => {
    setExpandedItems((prevState) => {
      if (prevState.includes(itemId)) {
        return prevState.filter((id) => id !== itemId);
      } else {
        return [...prevState, itemId];
      }
    });
  };

  const handleModalSubmitted = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (filter) {
      refetch();
      refetchCategories();
      refechUncategorizedCount();
    }
  }, [filter, refetch, refetchCategories, refechUncategorizedCount]);

  useEffect(() => {
    const updateCategories = () => {
      const parentIds = categoriesData?.filter((item) => !item.parentId);

      const tree = parentIds?.map(
        (item) =>
          ({
            id: item.id.toString(),
            count: item.kbbFaqs[0].count,
            label: `${item.category} ${
              item.kbbFaqs[0].count > 0 ? ' (' + item.kbbFaqs[0].count + ')' : ''
            }`,
            name: item.category,
            children: categoriesData
              ?.filter((c) => c.parentId === item.id && c.id !== item.id)
              .map(
                (item) =>
                  ({
                    id: item.id.toString(),
                    name: item.category,
                    count: item.kbbFaqs[0].count,
                    label: `${item.category} ${
                      item.kbbFaqs[0].count > 0 ? ' (' + item.kbbFaqs[0].count + ')' : ''
                    }`
                  }) as CategoryTreeItem
              )
          }) as CategoryTreeItem
      );

      const treeWithSummary = tree?.map((item) => {
        const count = item.children?.reduce((acc, child) => acc + child.count, 0) ?? 0;

        const total = item.count + count;
        item.label = total > 0 ? `${item.name} (${total})` : item.name;
        item.count += count;

        return item;
      });

      setCategoriesTree([...defaultCategories, ...(treeWithSummary ?? [])]);
    };

    categoriesData && updateCategories();
  }, [defaultCategories, categoriesData]);

  useEffect(() => {
    const expanded =
      categoriesTree
        ?.filter((item) => item.children && item.children?.length > 0)
        .map((item) => item.id) ?? [];

    setExpandedItems(expanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesTree?.length]);

  useEffect(() => {
    const getFAQAndEdit = async (id: string) => {
      const faq = await getFAQ({
        id: parseInt(id),
        organizationId: user?.user_metadata.organizationId
      });

      faq && handleEdit(faq);
    };

    if (faqId) {
      getFAQAndEdit(faqId);
    }
  }, [faqId, getFAQ, handleEdit, user?.user_metadata.organizationId]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={2}>
          <Box className="bg-white pl-[4.5rem] w-full max-w-full max-h-screen overflow-y-auto overflow-x-hidden">
            <PageHeader
              title="Categories"
              fontSize="1rem"
              className="p-4 pl-8"
              style={{ borderRight: '1px solid #E0E0E0' }}
            />

            {categoriesTree?.length && (
              <CustomTreeView
                className="overflow-x-hidden text-wrap"
                key={new Date().getTime()}
                items={categoriesTree}
                expandedItems={expandedItems}
                onSelectedItemsChange={(
                  event: React.SyntheticEvent,
                  itemIds: string | string[] | null
                ) => {
                  handleToggle(itemIds as string);
                  handleFilterChange('category', itemIds as string);
                }}
                selectedItems={selectedCategory}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={10} className="">
          <Box className="w-full mt-4 flex justify-between">
            <Box className="flex items-center">
              <PageHeader title="FAQS" className="pl-5" />
              <Tooltip
                title={
                  <Stack>
                    <Typography
                      variant="body2"
                      className="font-small font-bold text-white w-full text-center"
                    >
                      How are the FAQs generated?
                    </Typography>
                    <Typography variant="body2" className="font-small text-white">
                      - Automatically generated from daily agent discussions.
                    </Typography>
                    <Typography variant="body2" className="font-small text-white">
                      - Manually generated through the Slack Improve button
                    </Typography>
                    <Typography variant="body2" className="font-small text-white">
                      - Automatically generated for those questions which were no successfully
                      answered or whose response received negative feedback.
                    </Typography>
                  </Stack>
                }
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setShowDialog(true)}
            >
              Add FAQ
            </Button>
          </Box>
          <Box className="bg-white mt-4">
            <Tabs
              value={filter.status}
              onChange={(_: React.SyntheticEvent, newValue: string) =>
                handleFilterChange('status', newValue)
              }
            >
              <Tab
                className="text-xs pl-4"
                label={total > 0 ? `All (${total})` : 'All'}
                value={'all'}
              ></Tab>
              {statuses?.map((status) => (
                <Tab
                  key={status.id}
                  className="text-xs"
                  label={
                    <Box className="w-full flex justify-around items-center gap-2">
                      <StatusBadge status={status?.status as Status} />
                      {status.status}
                      {status.kbbFaqs[0].count > 0 && <span>{`(${status.kbbFaqs[0].count})`}</span>}
                    </Box>
                  }
                  value={status.id}
                />
              ))}
            </Tabs>
          </Box>
          <Paper elevation={0} className="pt-4 rounded-t-xl rounded-b-none">
            <Grid container>
              <Grid item xs={12} md={4} lg={4} className="pr-2 pl-6">
                <SearchField
                  id="question-filter"
                  className="bg-white w-full"
                  bordered
                  placeholder="Search by name"
                  value={question}
                  onChange={(e) => {
                    handleQuestionChange(e.target.value);
                    handleFilterChange('question', e.target.value, 'debounced');
                  }}
                  onClear={() => {
                    handleQuestionChange('');
                    handleFilterChange('question', '', 'immediate');
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2} className="pl-4 pr-4">
                <DateRangeField
                  periodValue={filter.period}
                  rangeValue={
                    filter.startDate && filter.endDate
                      ? {
                          selection: {
                            startDate: filter.startDate,
                            endDate: filter.endDate,
                            key: 'selection'
                          } as RangeKeyDict
                        }
                      : undefined
                  }
                  onPeriodChange={handlePeriodChange}
                  onRangeChange={handleDateRangeChange}
                />
              </Grid>
              {/* <Grid item xs={12} md={4} lg={2} className="pl-2 pr-2">
                <FormControl fullWidth>
                  <InputLabel id="status-select-label">Status</InputLabel>
                  <StatusSelect
                    id="status-select"
                    variant="outlined"
                    defaultValue=""
                    labelId="status-select-label"
                    label="Status"
                    allowEmptyValue={true}
                    onChange={(e) => handleFilterChange('status', e.target.value as string)}
                    value={filter.status}
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={4} lg={2} className="pl-2 pr-2">
                <FormControl fullWidth>
                  <InputLabel id="source-select-label">Source</InputLabel>
                  <Select
                    id="source-select"
                    defaultValue=""
                    labelId="source-select-label"
                    label="Source"
                    onChange={(e) => handleFilterChange('source', e.target.value)}
                    value={filter.source}
                  >
                    <MenuItem value={'all'}>{'All'}</MenuItem>
                    {sources.data?.map((source, idx) => (
                      <MenuItem key={idx} value={source.source!!} className="capitalize">
                        {source.source}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={2} className="flex justify-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={filter.unanswered}
                      checked={filter.unanswered}
                      onChange={(e) => handleFilterChange('unanswered', e.target.checked!)}
                    />
                  }
                  label="Unanswered"
                />
              </Grid>
            </Grid>
          </Paper>
          <SimpleTable
            showRowNumber={false}
            columns={cols}
            rows={faqs ?? []}
            tableProps={{ stickyHeader: true }}
            tableContainerProps={{ elevation: 0, className: 'max-h-[85vh]' }}
          />
        </Grid>
      </Grid>
      {showDialog && (
        <FAQModal
          isOpen={showDialog}
          setIsOpen={setShowDialog}
          currentFAQ={faq}
          onAfterSubmitted={handleModalSubmitted}
        />
      )}
    </Box>
  );
};

export default FAQsPage;
