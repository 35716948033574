import { Session, User } from '@supabase/supabase-js';
import { ActionMap } from '../../../types';

export enum UserActionTypes {
  UpdateUser = 'UPDATE_USER',
  UpdateUserSession = 'UPDATE_SESSION',
  LogOutUser = 'LOGOUT_USER'
}

interface UserReducerPayload {
  [UserActionTypes.UpdateUser]: {
    user: User;
  };
  [UserActionTypes.UpdateUserSession]: {
    user?: User;
    session?: Session;
  };
  [UserActionTypes.LogOutUser]: {};
}

export type UserActions = ActionMap<UserReducerPayload>[keyof ActionMap<UserReducerPayload>];

export interface UserReducerState {
  user?: User;
  session?: Session;
}

export const userReducer = (state: UserReducerState, action: UserActions): UserReducerState => {
  switch (action.type) {
    case UserActionTypes.UpdateUser:
      return {
        user: action.payload.user,
        session: state.session
      };
    case UserActionTypes.UpdateUserSession:
      return {
        session: action.payload.session,
        user: action.payload.user
      };
    case UserActionTypes.LogOutUser:
      localStorage.removeItem('kbbSession');
      return {
        user: undefined,
        session: undefined
      };
    default:
      return state;
  }
};
