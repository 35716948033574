import { createClient } from '@supabase/supabase-js';

const supabaseClient = createClient(
  process.env.REACT_APP_SUPABASE_KBB_URL ?? '',
  process.env.REACT_APP_SUPABASE_KBB_ANON_KEY ?? ''
);

// TODO: remove empty tables
const KBB_SCHEMA = {
  ACTIONS_TABLE: 'actions',
  ANALYTICS_TABLE: 'analytics',
  CATEGORY_TABLE: 'categories',
  CONTENT_SNIPPET_TABLE: 'kbb_content_snippets',
  EVALUATIONS_TABLE: 'evaluations',
  EXTRACTED_QUESTIONS_VIEW: 'faqs_vw',
  EXISTING_ARTICLES_TABLE: 'kbb_existing_articles',
  ARTICLE_DRAFTS_TABLE: 'kbb_article_drafts',
  QUESTIONS_CONVERSATIONS_VIEW: '',
  FAQS_EXISTING_ARTICLES_TABLE: 'faqs_existing_articles',
  ARTICLE_DRAFT_COMMENT: 'article_draft_comments',
  PROFILES_TABLE: 'profiles',
  ORGANIZATIONS_TABLE: 'organizations',
  SOURCES_TABLE: 'sources',
  FAQ_TABLE: 'kbb_faqs',
  FAQ_STATUS_TABLE: 'kbb_faq_statuses',
  FAQ_SOURCES_VIEW: 'faq_sources_vw',
  TAGS_TABLE: 'tags',
  TAG_STATUS_VIEW: 'vw_tag_statuses',
  RTA_MESSAGES_TABLE: 'rta_messages',
  RTA_CONVERSATIONS_VIEW: 'vw_rta_conversations',
  RTA_CONVERSATION_INBOX_VIEW: 'vw_conversation_inboxes',
  AGENT_PATHWAYS_TABLE: 'v_pathways',
  AGENT_TABLE: 'v_agents',
  CALL_LOG_TABLE: 'v_call_logs',
  CALL_LOG_FROM_VIEW: 'vw_call_logs_from',
  CALL_LOG_TO_VIEW: 'vw_call_logs_to',
  CALL_LOG_OUTCOME_VIEW: 'vw_call_logs_outcome',
  CALL_LOG_ENDED_BY_VIEW: 'vw_call_logs_ended_by',
  PHONE_NUMBERS_TABLE: 'v_phone_numbers',
  LLM_RESPONSES_TABLE: 'rta_llm_responses'
};

export { KBB_SCHEMA, supabaseClient };
