import { PaletteOptions } from '@mui/material';

export const baseColors = {
  black: '#212B36',
  blue: '#0000FF',
  'sky-blue': '#00B8D9',
  'sky-blue2': '#E6F1F7',
  gray: '#6e6e6e',
  gray2: '#A1A1A1',
  gray3: '#E0E0E0',
  gray4: '#E4E2E2',
  gray5: '#E7E4E1',
  gray6: '#f3f3f3',
  gray7: '#9f9f9f',
  gray8: '#637381',
  gray9: '#C4CDD5',
  gray10: '#919EAB33',
  gray11: '#919EAB14',
  gray12: '#919EAB',
  gray13: '#D9D9D9',
  gray14: '#919EAB52',
  'light-gray': '#f8f8f8',
  green: '#CAEFCE',
  green2: '#36663B',
  green3: '#82C684',
  green4: '#22C55E29',
  green5: '#118D57',
  green6: '#22C55E14',
  green7: '#22C55E',
  orange: '#ff421c',
  orange2: 'rgb(178, 46, 19)',
  pink: '#fff0ed',
  pink2: '#FFECE8',
  pink3: '#FF421C1F',
  pink4: '#FF563029',
  purple: '#EAE7F1',
  purple2: '#D8D8FF',
  red: '#FFD6D6',
  red2: '#B84838',
  red3: '#B71D18',
  yellow: '#FCF8D4',
  yellow2: '#A79A2B',
  yellow3: '#fdd835',
  yellow4: '#FFAB0029',
  yellow5: '#B76E00',
  yellow6: '#FFAB00',
  white: '#fff',
  white2: '#F4F6F8'
};

const defaultPalette: PaletteOptions = {
  mode: 'light',
  primary: { main: baseColors.orange },
  warning: { main: baseColors.pink }
};

export default defaultPalette;
