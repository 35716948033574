import { SvgIcon, SvgIconProps } from '@mui/material';

function StartNodeIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon
      width="17"
      height="17"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_920_12896"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="17"
      >
        <rect x="0.909668" y="0.185059" width="15.9" height="15.9" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_920_12896)">
        <path
          d="M13.7884 13.7659C12.54 13.7659 11.2857 13.4756 10.0256 12.8951C8.76564 12.3145 7.60693 11.4955 6.54947 10.4382C5.49632 9.38072 4.67945 8.223 4.09888 6.96503C3.51831 5.70716 3.22803 4.45398 3.22803 3.2055C3.22803 3.00675 3.29428 2.84002 3.42678 2.70531C3.55928 2.57072 3.7249 2.50342 3.92365 2.50342H6.0844C6.25168 2.50342 6.39925 2.55802 6.52711 2.66722C6.65497 2.77631 6.7363 2.91113 6.77108 3.07168L7.15086 5.02092C7.17714 5.20178 7.17161 5.35719 7.13429 5.48715C7.09686 5.61711 7.02973 5.72626 6.93289 5.81459L5.40285 7.30406C5.64908 7.755 5.93042 8.1816 6.24687 8.58384C6.56322 8.98598 6.90573 9.37007 7.27441 9.7361C7.6379 10.0997 8.02436 10.4374 8.43379 10.7491C8.84321 11.0608 9.28532 11.3508 9.76011 11.6193L11.2468 10.1197C11.3504 10.0118 11.476 9.93617 11.6234 9.89278C11.7707 9.8495 11.9238 9.83889 12.0827 9.86098L13.9223 10.2356C14.0896 10.2798 14.2261 10.3651 14.3319 10.4917C14.4376 10.6182 14.4905 10.7618 14.4905 10.9223V13.0703C14.4905 13.269 14.4232 13.4347 14.2886 13.5672C14.1539 13.6997 13.9872 13.7659 13.7884 13.7659Z"
          fill="#919EAB"
        />
      </g>
    </SvgIcon>
  );
}

export default StartNodeIcon;
