import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import saveAs from 'file-saver';
import { useDateTime } from 'hooks';
import { useCallback } from 'react';
import { QueryFunctionContext } from 'react-query';
import { DatePeriod, FAQFilter, Status } from 'types';
import { Category, FAQ, FAQSource } from 'types/models';
import { Tables } from 'types/models/db.type';
import { APIService } from 'types/services';
import { BaseAdapter } from '../adapters';
import { useToast } from 'contexts/ToastContext';
import { useUser } from 'hooks/reducers';

interface FAQCategory extends Category {
  kbbFaqs: { count: number }[];
  categories: FAQCategory[];
}

type GetFAQsParams = QueryFunctionContext<['kbb_faqs', FAQFilter]>;
type GetFAQSourcesParams = QueryFunctionContext<['faq_unique_sources', { organizationId: string }]>;
type FAQService = APIService<FAQ> & {
  getSources: (params: GetFAQSourcesParams) => Promise<FAQSource[] | undefined>;
  getCategories: (params: GetCategoriesParams) => Promise<FAQCategory[] | undefined>;
  getCategory: (filter: { id: string; organizationId: string }) => Promise<FAQCategory | undefined>;
  deleteItem: (id: number) => Promise<void | FAQ[] | undefined>;
  getNextByStatus: (status: Status, faqId: number) => Promise<FAQ | undefined>;
  exportFiltered: (params: FAQFilter) => Promise<void>;
  getUncategorizedCount: (params: GetUncategorizedCountParams) => Promise<number | undefined>;
};
type GetCategoriesParams = QueryFunctionContext<['faqs_categories', FAQFilter]>;
type GetUncategorizedCountParams = QueryFunctionContext<['faqs_uncategorized', FAQFilter]>;

const useFAQService = (): FAQService => {
  const user = useUser();
  const { adapt, reverse } = BaseAdapter<Tables<'kbb_faqs'>, FAQ>();
  const sourceAdapter = BaseAdapter<Tables<'faq_sources_vw'>, FAQSource>();
  const categoryAdapter = BaseAdapter<Tables<'categories'>, FAQCategory>();
  const { getDateFromPeriod } = useDateTime();
  const { FAQ_TABLE, FAQ_SOURCES_VIEW, CATEGORY_TABLE } = KBB_SCHEMA;
  const { showToast } = useToast();

  const get = useCallback(
    async (filter: { id: string; organizationId: string }) => {
      let query = supabaseClient.from(FAQ_TABLE).select('*');

      if (!filter.organizationId) return;

      query = query.eq('organization_id', filter.organizationId);

      if (filter.id) {
        query = query.eq('id', filter.id).limit(1);
      }

      const { data, error } = await query.single<Tables<'kbb_faqs'>>();

      if (error) console.error(error);

      if (!data) return;

      return adapt(data);
    },
    [adapt, FAQ_TABLE]
  );

  const getNextByStatus = useCallback(
    async (status: Status, faqId: number) => {
      let query = supabaseClient.from(FAQ_TABLE).select('*, kbb_faq_statuses(*)');

      if (!status || !faqId) {
        return;
      }

      query = query
        .eq('kbb_faq_statuses.status', status)
        .neq('id', faqId)
        .gt('id', faqId)
        .order('id')
        .limit(1);

      const { data, error } = await query.returns<Tables<'kbb_faqs'>[]>();
      if (error) console.error(error);

      if (!data?.length) return;

      return adapt(data[0]);
    },
    [adapt, FAQ_TABLE]
  );

  const getCategories = useCallback(
    async (params: GetCategoriesParams) => {
      const [, filter] = params.queryKey;

      if (!filter.organizationId) return;

      let query = supabaseClient.from(CATEGORY_TABLE).select('*, kbb_faqs(count)');

      if (filter?.question && filter.question.trim().length > 0) {
        query = query.like('kbb_faqs.question', `%${filter.question.trim()}%`);
      }

      if (filter?.status && filter.status !== 'all') {
        query = query.eq('kbb_faqs.status_id', filter.status);
      }

      if (filter?.source && filter.source !== 'all') {
        query = query.eq('kbb_faqs.source', filter.source);
      }

      if (filter?.period && filter.period !== 'all' && filter.period !== 'dr') {
        const period = getDateFromPeriod(filter.period as DatePeriod);
        query = query.gte('kbb_faqs.created_at', period);
      }

      if (filter?.startDate && filter.endDate) {
        query = query
          .gte('kbb_faqs.created_at', filter.startDate)
          .lte('kbb_faqs.created_at', filter.endDate);
      }

      if (filter?.unanswered !== undefined && filter.unanswered === true) {
        query = query.eq('kbb_faqs.answer', 'NA');
      }

      const { data, error } = await query
        .order('category', { ascending: true })
        .eq('kbb_faqs.organization_id', filter.organizationId)
        .eq('organization_id', filter.organizationId);

      if (error) console.log(error);

      return data?.map((item) => categoryAdapter.adapt(item));
    },
    [categoryAdapter, getDateFromPeriod, CATEGORY_TABLE]
  );

  const getCategory = useCallback(
    async (filter: { id: string; organizationId: string }) => {
      let query = supabaseClient.from(CATEGORY_TABLE).select('*, categories(id, category)');

      if (!filter.organizationId) return;

      query = query.eq('organization_id', filter.organizationId);

      if (filter.id) {
        query = query.eq('id', filter.id).limit(1);
      }

      const { data, error } = await query.single<Tables<'categories'>>();

      if (error) console.error(error);

      if (!data) return;

      return categoryAdapter.adapt(data);
    },
    [categoryAdapter, CATEGORY_TABLE]
  );

  const getMany = useCallback(
    async (params: GetFAQsParams) => {
      const [, filter] = params.queryKey;

      if (!filter.organizationId) return;

      let query = supabaseClient
        .from(FAQ_TABLE)
        .select(`*, categories(id, category), kbb_faq_statuses(id, status)`);

      if (!filter?.organizationId) return;

      query = query.eq('organization_id', filter.organizationId);

      if (filter?.question && filter.question.trim().length > 0) {
        query = query.like('question', `%${filter.question.trim()}%`);
      }

      if (
        filter?.category &&
        typeof filter.category === 'string' &&
        filter.category.toLocaleLowerCase() !== 'all' &&
        filter.category.toLowerCase() !== 'none' &&
        filter.category !== ''
      ) {
        const category = await getCategory({
          id: filter.category,
          organizationId: filter.organizationId
        });

        if (category?.categories && category?.categories?.length > 0) {
          query = query.in('category_id', category?.categories?.map((item) => item.id) || []);
        } else {
          query = query.eq('category_id', filter.category);
        }
      }

      if (filter?.category === 'none') {
        query = query.is('category_id', null);
      }

      if (filter?.status && filter.status !== 'all') {
        query = query.eq('status_id', filter.status);
      }

      if (filter?.source && filter.source !== 'all') {
        query = query.eq('source', filter.source);
      }

      if (filter?.period && filter.period !== 'all' && filter.period !== 'dr') {
        const period = getDateFromPeriod(filter.period as DatePeriod);
        query = query.gte('created_at', period);
      }

      if (filter?.startDate && filter.endDate) {
        query = query.gte('created_at', filter.startDate).lte('created_at', filter.endDate);
      }

      if (filter?.unanswered !== undefined && filter.unanswered === true) {
        query = query.eq('answer', 'NA');
      }

      const { data, error } = await query
        .order('created_at', { ascending: false })
        .returns<Tables<'kbb_faqs'>[]>();

      if (error) console.log(error);
      return data?.map((item) => adapt(item));
    },
    [adapt, getDateFromPeriod, FAQ_TABLE, getCategory]
  );

  const getSources = useCallback(
    async (params: GetFAQSourcesParams) => {
      const [, filter] = params.queryKey;

      if (!filter?.organizationId) return;

      const query = supabaseClient
        .from(FAQ_SOURCES_VIEW)
        .select('*')
        .eq('organization_id', filter.organizationId);

      const { data, error } = await query.returns<Tables<'faq_sources_vw'>[]>();

      if (error) console.log(error);
      return data?.map((item) => sourceAdapter.adapt(item));
    },
    [sourceAdapter, FAQ_SOURCES_VIEW]
  );

  const getUncategorizedCount = useCallback(
    async (params: GetUncategorizedCountParams) => {
      const [, filter] = params.queryKey;

      if (!filter.organizationId) return;

      let query = supabaseClient
        .from(FAQ_TABLE)
        .select('id', { count: 'exact' })
        .is('category_id', null);

      if (filter?.question && filter.question.trim().length > 0) {
        query = query.like('question', `%${filter.question.trim()}%`);
      }

      if (filter?.status && filter.status !== 'all') {
        query = query.eq('status_id', filter.status);
      }

      if (filter?.source && filter.source !== 'all') {
        query = query.eq('source', filter.source);
      }

      if (filter?.period && filter.period !== 'all' && filter.period !== 'dr') {
        const period = getDateFromPeriod(filter.period as DatePeriod);
        query = query.gte('created_at', period);
      }

      if (filter?.startDate && filter.endDate) {
        query = query.gte('created_at', filter.startDate).lte('created_at', filter.endDate);
      }

      if (filter?.unanswered !== undefined && filter.unanswered === true) {
        query = query.eq('answer', 'NA');
      }

      const { error, count } = await query.eq('organization_id', filter.organizationId);

      if (error) console.log(error);

      return count ?? 0;
    },
    [getDateFromPeriod, FAQ_TABLE]
  );

  const post = useCallback(
    async (faq: Omit<FAQ, 'id'>) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_LLM_SERVICE_URL}/assistant/add_faq`, {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'authorization, x-client-info, apikey, content-type',
            'Content-Type': 'application/json',
            'X-User-Id': user.user?.id ?? '',
            'X-Organization': user?.user?.user_metadata?.organizations.id ?? '',
            'X-Api-Key': user?.user?.user_metadata?.organizations.apiKey ?? ''
          },
          body: JSON.stringify({
            faqs_list: [
              {
                additional_information: faq.additionalInformation,
                answer: faq.answer,
                author_email: faq.authorEmail,
                category_id: faq.categoryId,
                coverage: faq.coverage,
                link: faq.link,
                organization_id: user?.user?.user_metadata?.organizations.id ?? '',
                origin: 'manual_prosper_add',
                question: faq.question,
                source: faq.source,
                status_id: faq.statusId,
                views_count: faq.viewsCount ?? 0,
                created_at: new Date(),
                updated_at: new Date()
              }
            ]
          })
        });

        response.ok && showToast('FAQ successfully created', 'success');

        // return data?.map((item) => adapt(item));
      } catch (error) {
        showToast('Error creating FAQ', 'error');
      }
    },
    [FAQ_TABLE, adapt, reverse, showToast, user]
  );

  const deleteItem = useCallback(
    async (id: number) => {
      const { data, error } = await supabaseClient.from(FAQ_TABLE).delete().eq('id', id).select();

      if (error) console.error(error);

      if (!data) return;

      return data.map((item) => adapt(item));
    },
    [adapt, FAQ_TABLE]
  );

  const put = useCallback(
    async ({ categories, faqStatuses, ...faq }: FAQ) => {
      const { data, error } = await supabaseClient
        .from(FAQ_TABLE)
        .update({
          question: faq.question,
          answer: faq.answer,
          link: faq.link,
          category_id: faq.categoryId,
          status_id: faq.statusId
        })
        .eq('id', faq.id)
        .select();

      if (error) console.error(error);

      if (!data) return;

      return data.map((item) => adapt(item));
    },
    [adapt, FAQ_TABLE]
  );

  const exportFiltered = useCallback(
    async (filter: FAQFilter) => {
      if (!filter.organizationId) return;

      let query = supabaseClient
        .from(FAQ_TABLE)
        .select(
          `id, created_at, updated_at, question, answer, link, author_email, status_id, source, origin, categories(category),  kbb_faq_statuses(status)`
        );

      if (!filter?.organizationId) return;

      query = query.eq('organization_id', filter.organizationId);

      if (filter?.question && filter.question.trim().length > 0) {
        query = query.like('question', `%${filter.question.trim()}%`);
      }

      if (
        filter?.category &&
        typeof filter.category === 'string' &&
        filter.category.toLocaleLowerCase() !== 'all' &&
        filter.category.toLowerCase() !== 'none' &&
        filter.category !== ''
      ) {
        query = query.eq('category_id', filter.category);
      } else if (filter?.category && Array.isArray(filter.category)) {
        query = query.in('category_id', filter.category);
      }

      if (typeof filter.category === 'string' && filter?.category === 'none') {
        query = query.is('category_id', null);
      }

      if (filter?.status && filter.status !== 'all') {
        query = query.eq('status_id', filter.status);
      }

      if (filter?.source && filter.source !== 'all') {
        query = query.eq('source', filter.source);
      }

      if (filter?.period && filter.period !== 'all' && filter.period !== 'dr') {
        const period = getDateFromPeriod(filter.period as DatePeriod);
        query = query.gte('created_at', period);
      }

      if (filter?.startDate && filter.endDate) {
        query = query.gte('created_at', filter.startDate).lte('created_at', filter.endDate);
      }

      if (filter?.unanswered !== undefined && filter.unanswered === true) {
        query = query.eq('answer', 'NA');
      }

      const { data, error } = await query
        .order('created_at', { ascending: false })
        .returns<Tables<'kbb_faqs'>[]>()
        .csv();

      if (error) {
        console.error('Error exporting data:', error);
        return;
      }

      const blob = new Blob([data!], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'faqs-data.csv');
    },
    [getDateFromPeriod, FAQ_TABLE]
  );

  return {
    deleteItem,
    exportFiltered,
    get,
    getNextByStatus,
    getMany,
    getCategories,
    getCategory,
    getSources,
    getUncategorizedCount,
    post,
    put
  };
};

export default useFAQService;
