import { Chip } from '@mui/material';
import { NodeWrapper } from 'components/organisms';
import { NodeProps } from 'reactflow';
import { TransferPathwayNodeProps } from './TransferPathwayNode.props';

export function TransferPathwayNode(props: NodeProps<TransferPathwayNodeProps>) {
  console.log('data.agentId', props.data.agentId);
  return (
    <NodeWrapper nodeProps={props}>
      {props.data.agentId && <Chip label={props.data.agentId}></Chip>}
    </NodeWrapper>
  );
}
