import { Button, Drawer, FormControl, TextField } from '@mui/material';
import { useAgentsService } from 'hooks';
import { useUser } from 'hooks/reducers';
import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { customAlphabet } from 'nanoid';
import { Database } from 'types/models/db.type';

type Agent = Database['public']['Tables']['v_agents']['Row'];

interface AgentModalProps {
  isOpen: boolean;
  agentId?: string;
  onAgentUpdated?: (agent: Agent) => void;
  onAgentCreated?: (agent: Agent) => void;
  onModalClose: () => void;
  onAgentSelected?: (agent: Database['public']['Tables']['v_agents']['Row']) => VoidFunction;
}

const nanoid = customAlphabet('123456789', 10);

const AgentModal = ({
  isOpen,
  agentId,
  onModalClose,
  onAgentSelected,
  onAgentCreated,
  onAgentUpdated
}: AgentModalProps) => {
  const { user } = useUser();
  const { post } = useAgentsService();
  const queryClient = useQueryClient();

  const [agent, setAgent] = useState<Agent | null>(null);

  const postMutation = useMutation({
    mutationKey: 'postAgent',
    mutationFn: (agent: any) => post(agent),
    onSuccess: () => {
      handleModalClose();
      queryClient.invalidateQueries({ queryKey: ['agents_query'] });
    }
  });

  const onSubmit = useCallback(
    async (event?: React.FormEvent) => {
      event?.preventDefault();
      if (!agent?.agent_name || !agent.agent_description || !user?.user_metadata.organizationId) {
        return;
      }

      const organization_id = user?.user_metadata.organizationId;

      const newAgent = {
        ...agent,
        organization_id: organization_id
      } as Agent;

      postMutation.mutate(newAgent);
    },
    [agent, postMutation, user?.user_metadata.organizationId]
  );

  const handleModalClose = () => {
    onModalClose();
    setAgent(null);
  };

  return (
    <Drawer
      className="z-[1000] flex"
      anchor="right"
      open={isOpen}
      onClose={handleModalClose}
      PaperProps={{ className: 'w-[30rem] p-4 rounded-none', style: { maxWidth: '30%' } }}
      sx={{ img: { maxWidth: '100%' } }}
    >
      <div className="flex flex-col gap-4">
        <h1 className="text-xl font-bold">Add Agent</h1>
        <form className="flex flex-col gap-4" onSubmit={onSubmit}>
          <FormControl key={'name'}>
            <TextField
              key="Name"
              label="Name"
              variant="outlined"
              value={agent?.agent_name ?? ''}
              onChange={(e) =>
                setAgent((prev) => ({ ...prev, agent_name: e.target.value }) as Agent)
              }
              required
            />
          </FormControl>

          <FormControl key={'description'}>
            <TextField
              key="Description"
              label="Description"
              variant="outlined"
              value={agent?.agent_description ?? ''}
              onChange={(e) =>
                setAgent((prev) => ({ ...prev, agent_description: e.target.value }) as Agent)
              }
              required
              multiline
              rows={4}
            />
          </FormControl>

          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      </div>
    </Drawer>
  );
};

export default AgentModal;
