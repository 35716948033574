import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Check } from '@mui/icons-material';
import useSourcesService from 'hooks/services/sources-service/sources.service';
import { useToast } from 'contexts/ToastContext';
import { useUser } from 'hooks/reducers';

const ConfluenceForm = ({ handleClose }: { handleClose: () => void }) => {
  const [token, setToken] = useState({
    connectionId: '',
    providerConfigKey: ''
  });
  const [error, setError] = useState(false);
  const [pages, setPages] = useState<string[]>(['']);
  const [labels, setLabels] = useState<string[]>(['']);

  const { showToast } = useToast();

  const { authWithSSO: authWithConfluence, submitSource } = useSourcesService();

  const { user } = useUser();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (token.connectionId === '') {
        setError(true);
        return;
      }
      const pagesId = pages.filter((page) => page.trim() !== '');
      const labelsId = labels.filter((label) => label.trim() !== '');
      const metadata = {
        pages: [pagesId],
        labels: [labelsId]
      };
      submitSource(metadata, token.connectionId, token.providerConfigKey);
      setToken((prevState) => ({
        ...prevState,
        providerConfigKey: '',
        connectionId: ''
      }));
      showToast('Source successfully added', 'success');
      handleClose();
    } catch (error) {
      showToast('Error adding source', 'error');
    }
  };

  const handleAddPages = () => {
    setPages((prevPages) => [...prevPages, '']);
  };

  const handlePagesChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const { value } = event.target;
    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[index] = value;
      return updatedPages;
    });
  };

  const handleAddLabels = () => {
    setLabels((prevLabels) => [...prevLabels, '']);
  };

  const handleLabelsChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const { value } = event.target;
    setLabels((prevLabels) => {
      const updatedLabels = [...prevLabels];
      updatedLabels[index] = value;
      return updatedLabels;
    });
  };

  return (
    <div>
      <h3 className="text-base font-bold">Confluence</h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8">
          <Button
            variant="contained"
            className="bg-white text-black gap-4"
            onClick={() =>
              authWithConfluence('confluence', `${user?.user_metadata.organizationId!}`).then(
                (response) => {
                  if (response) setToken(response!);
                }
              )
            }
            disabled={token?.connectionId !== '' ? true : false}
          >
            {token.connectionId === '' ? (
              <>
                <img src={'/confluence192.png'} alt="Confluence" className="w-4 h-4" />
                Connect Confluence
              </>
            ) : (
              <>
                <img src={'/confluence192.png'} alt="Confluence" className="w-4 h-4" />
                Connected
                <Check className="text-sm text-orange" />
              </>
            )}
          </Button>

          <div>
            <span className="text-sm text-gray font-bold">Pages</span>
            {pages.map((page, index) => (
              <div key={index} className="flex items-center mb-4">
                <TextField
                  id={`driveUrl-${index}`}
                  label="Page ID"
                  variant="outlined"
                  value={page}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handlePagesChange(event, index)
                  }
                  className="w-full"
                />
              </div>
            ))}
            <span className="text-xs text-gray cursor-pointer" onClick={handleAddPages}>
              Add new Page ID
            </span>
          </div>
          <div>
            <span className="text-sm text-gray font-bold">Labels</span>
            {labels.map((label, index) => (
              <div key={index} className="flex items-center mb-4">
                <TextField
                  id={`driveUrl-${index}`}
                  label="Label ID"
                  variant="outlined"
                  value={label}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleLabelsChange(event, index)
                  }
                  className="w-full"
                />
              </div>
            ))}
            <span className="text-xs text-gray cursor-pointer" onClick={handleAddLabels}>
              Add new Label ID
            </span>
          </div>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          {error && <p className="text-orange text-sm m-0">Please connect Confluence first</p>}
        </div>
      </form>
      <div className="pt-8">
        <h3 className="text-base text-orange font-bold">Instructions</h3>
        <ol>
          <li>Authenticate your Confluence account clicking on the button "Connect Confluence".</li>
          <li>Click the "Submit" button to add Confluence as a source.</li>
        </ol>
      </div>
    </div>
  );
};

export default ConfluenceForm;
