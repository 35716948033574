import * as React from 'react';
import { TreeItem2, TreeItem2Label, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import { RichTreeView, RichTreeViewProps } from '@mui/x-tree-view/RichTreeView';
import { UseTreeItem2ContentSlotOwnProps, useTreeItem2Utils } from '@mui/x-tree-view';
import { Badge } from '@mui/material';
import { CategoryTreeItem } from 'types';

interface CustomLabelProps {
  children: string;
  className: string;
  onChange: (value: string) => void;
}

function CustomLabel(props: CustomLabelProps) {
  const { children, onChange, ...other } = props;

  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [value, setValue] = React.useState('');
  const editingLabelRef = React.useRef<HTMLInputElement>(null);

  const handleLabelDoubleClick = () => {
    setIsEditing(true);
    setValue(children);
  };

  const handleEditingLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleEditingLabelKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      setIsEditing(false);
      onChange(value);
    } else if (event.key === 'Escape') {
      event.stopPropagation();
      setIsEditing(false);
    }
  };

  React.useEffect(() => {
    if (isEditing) {
      editingLabelRef.current?.focus();
    }
  }, [isEditing]);

  if (isEditing) {
    return (
      <input
        value={value}
        onChange={handleEditingLabelChange}
        onKeyDown={handleEditingLabelKeyDown}
        ref={editingLabelRef}
      />
    );
  }

  const extractNumbersAndParentheses = (input: string): string | null => {
    const match = RegExp(/\(\d+\)/).exec(input);
    return match ? match[0].replace('(', '').replace(')', '') : null;
  };

  const count = extractNumbersAndParentheses(children);

  return (
    <TreeItem2Label {...other} onDoubleClick={handleLabelDoubleClick}>
      {count && (
        <Badge
          badgeContent={count}
          slotProps={{
            badge: { className: 'bg-gray6 text-gray7 mt-[10px] mr-[-.75rem]' }
          }}
        >
          {children.replace(/\(\d+\)/g, '')}
        </Badge>
      )}

      {!count && children}
    </TreeItem2Label>
  );
}

const TreeItemContext = React.createContext<{
  onLabelValueChange: (itemId: string, label: string) => void;
}>({ onLabelValueChange: () => {} });

const CustomTreeItem = React.forwardRef((props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) => {
  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children
  });

  const { onLabelValueChange } = React.useContext(TreeItemContext);

  const handleLabelValueChange = (newLabel: string) => {
    onLabelValueChange(props.itemId, newLabel);
  };

  const handleContentClick: UseTreeItem2ContentSlotOwnProps['onClick'] = (event) => {
    event.defaultMuiPrevented = true;
    interactions.handleSelection(event);
  };

  const handleIconContainerClick = (event: React.MouseEvent) => {
    interactions.handleExpansion(event);
  };

  return (
    <TreeItem2
      className="text-wrap w-[calc(100%_-_.75rem)]"
      ref={ref}
      {...props}
      slots={{
        label: CustomLabel
      }}
      slotProps={{
        content: { onClick: handleContentClick },
        iconContainer: { onClick: handleIconContainerClick },
        label: {
          onChange: handleLabelValueChange
        } as any
      }}
    />
  );
});

type Props = {} & RichTreeViewProps<any, boolean>;

export default function CustomTreeView(props: Props) {
  const [state, setState] = React.useState(props.items);

  const context = React.useMemo(
    () => ({
      onLabelValueChange: (itemId: string, label: string) =>
        setState((prev) => {
          const tree = (item: CategoryTreeItem): CategoryTreeItem => {
            if (item.id === itemId) {
              return { ...item, label };
            }
            if (item.children) {
              return { ...item, children: item.children.map(tree) };
            }

            return item;
          };

          return prev.map(tree);
        })
    }),
    []
  );

  return (
    <TreeItemContext.Provider value={context}>
      <RichTreeView
        style={{
          minHeight: 'calc(100vh - 52px)',
          backgroundColor: '#fff',
          borderRight: '1px solid #E0E0E0'
        }}
        {...props}
        slots={{ item: CustomTreeItem }}
        items={state}
      />
    </TreeItemContext.Provider>
  );
}
