import { Box, Chip, Skeleton, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { AwesomeIcon } from 'components/atoms';
import { format } from 'date-fns';
import { useState } from 'react';

interface LLMResponseProps {
  loading?: boolean;
  similarity: 'low' | 'medium' | 'high';
  used: boolean;
  text: string;
  timestamp?: string;
  onClick: VoidFunction;
}

const LLMResponse = ({ loading, similarity, used, text, timestamp, onClick }: LLMResponseProps) => {
  const [active, setActive] = useState<boolean>(false);

  const handleClick = () => {
    !active && setActive(true);
    onClick && onClick();
  };

  return (
    <Stack
      direction="column"
      className={clsx(
        'mb-6 border border-solid border-1 rounded-xl p-4',
        active ? 'border-orange' : 'border-gray9',
        active && 'outline outline-4 outline-pink3'
      )}
      onClick={handleClick}
    >
      <Stack direction="row">
        <Stack className="w-[70%]" direction="row">
          <Box className="mr-3">
            <AwesomeIcon />
          </Box>
          <Typography variant="subtitle1" className="mr-7">
            LLM Response
          </Typography>
        </Stack>
        <Stack className="w-[30%] gap-2 justify-end" direction="row">
          <Chip
            className={clsx(used && 'bg-green4 text-green5', !used && 'bg-pink4 text-red3')}
            label={used ? 'Used' : 'Not used'}
          />
          <Chip
            className={clsx(
              'capitalize',
              similarity == 'high' && 'bg-green4 text-green5',
              similarity == 'low' && 'bg-pink4 text-red3'
            )}
            label={`${similarity ?? 'N/A'} Similarity`}
          />
        </Stack>
      </Stack>
      <Box className="pt-4">
        {loading && <Skeleton variant="rectangular" height={32} />}
        {!loading && (
          <Stack className="w-full">
            <Typography variant="caption" className="flex items-center relative text-gray8">
              {timestamp && format(new Date(timestamp), 'dd MMM, yyyy HH:mm a')}
            </Typography>
            <Typography variant="body1">{text}</Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default LLMResponse;
