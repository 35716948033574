import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Check } from '@mui/icons-material';
import useSourcesService from 'hooks/services/sources-service/sources.service';
import { useToast } from 'contexts/ToastContext';
import { useUser } from 'hooks/reducers';

const ClickUpForm = ({ handleClose }: { handleClose: () => void }) => {
  const [token, setToken] = useState({
    connectionId: '',
    providerConfigKey: ''
  });
  const [error, setError] = useState(false);
  const [documents, setDocuments] = useState<string[]>(['']);

  const { showToast } = useToast();

  const { authWithSSO: authWithClickUp, submitSource } = useSourcesService();

  const { user } = useUser();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const chanelsName = documents.filter((channel) => channel.trim() !== '');
      const metadata = {
        channels: [chanelsName]
      };
      submitSource(metadata, token.connectionId, token.providerConfigKey);
      if (token.connectionId === '') {
        setError(true);
        return;
      }
      setToken((prevState) => ({
        ...prevState,
        providerConfigKey: '',
        connectionId: ''
      }));
      showToast('Source successfully added', 'success');
      handleClose();
    } catch (error) {
      showToast('Error adding source', 'error');
    }
  };

  const handleAddChannel = () => {
    setDocuments((prevChannels) => [...prevChannels, '']);
  };

  const handleChannelChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const { value } = event.target;
    setDocuments((prevChannels) => {
      const updatedChannels = [...prevChannels];
      updatedChannels[index] = value;
      return updatedChannels;
    });
  };

  return (
    <div>
      <h3 className="text-base font-bold">ClickUp</h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8">
          <Button
            variant="contained"
            className="bg-white text-black gap-4"
            onClick={() =>
              authWithClickUp('clickup', `${user?.user_metadata.organizationId!}`).then(
                (response) => {
                  if (response) setToken(response!);
                }
              )
            }
            disabled={token.connectionId! ? (token.connectionId !== '' ? true : false) : false}
          >
            {token.connectionId === '' ? (
              <>
                <img src={'/clickup192.png'} alt="ClickUp" className="w-4 h-4" />
                Connect ClickUp
              </>
            ) : (
              <>
                <img src={'/clickup192.png'} alt="ClickUp" className="w-4 h-4" />
                Connected
                <Check className="text-sm text-orange" />
              </>
            )}
          </Button>
          <div>
            {documents.map((channel, index) => (
              <div key={index} className="flex items-center mb-4">
                <TextField
                  id={`driveUrl-${index}`}
                  label="Document URL"
                  variant="outlined"
                  value={channel}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleChannelChange(event, index)
                  }
                  className="w-full"
                />
              </div>
            ))}
            <span className="text-xs text-gray cursor-pointer" onClick={handleAddChannel}>
              Add new Document
            </span>
          </div>

          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          {error && <p className="text-orange text-sm m-0">Please connect ClickUp first</p>}
        </div>
      </form>
      <div className="pt-8">
        <h3 className="text-base text-orange font-bold">Instructions</h3>
        <ol>
          <li>Authenticate your ClickUp account clicking on the button "Connect ClickUp".</li>
          <li>Fill in the document URL.</li>
          <li>Click the "Submit" button to add ClickUp as a source.</li>
        </ol>
      </div>
    </div>
  );
};

export default ClickUpForm;
