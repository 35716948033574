import { Button, IconButton } from '@mui/material';
import { InnerPageTemplate } from 'components/templates';
import { TableColumn } from 'types';
import { SimpleTable } from 'components/organisms';
import { useCallback, useState } from 'react';
import JsonView from 'react18-json-view';
import ActionsModal from 'components/organisms/actions/ActionsModal';
import { useUser } from 'hooks/reducers';
import useActionsService from 'hooks/services/actions/actions.service';
import { Delete, Edit } from '@mui/icons-material';
import { Action } from 'types/models';

import 'react18-json-view/src/style.css';
import { useConfirmationDialog } from 'hooks';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const ActionsPage = () => {
  const { user } = useUser();
  const { openDialog } = useConfirmationDialog();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedAction, setSelectedAction] = useState<string | null>(null);

  const { getMany, deleteItem } = useActionsService();

  const { data: actions, refetch } = useQuery({
    queryKey: ['actions', user?.user_metadata.organizationId],
    queryFn: () => getMany(user?.user_metadata.organizationId),
    enabled: !!user?.user_metadata.organizationId
  });

  const deleteMutation = useMutation({
    mutationKey: 'deleteAction',
    mutationFn: (id: string) => deleteItem(id),
    onSuccess: (data: void | Action[] | undefined) => {
      if (data) {
        queryClient.invalidateQueries(['actions', user?.user_metadata.organizationId]);
      }
      refetch();
    }
  });

  const handleDelete = useCallback(
    (id: string) => {
      openDialog('Delete Action', 'Are you sure to delete this Action?', () =>
        deleteMutation.mutate(id)
      );
      refetch();
    },
    [deleteMutation, openDialog, refetch]
  );

  const onModalClose = () => {
    setIsModalOpen(false);
    refetch();
    if (selectedAction) setSelectedAction(null);
  };

  const onEditClick = (id: string) => {
    setSelectedAction(id);
    setIsModalOpen(true);
  };

  const cols: TableColumn<{
    id: string;
    actionName: string;
    actionDescription: string;
    categoryId: string;
    requestMethod: string;
    requestEndpoint: string;
    requestParameters: string;
  }>[] = [
    {
      id: 'action_name',
      label: 'Action',
      renderer: ({ actionName }: { actionName: string }) => <span>{actionName}</span>
    },

    {
      id: 'action_description',
      label: 'Description',
      renderer: ({ actionDescription }: { actionDescription: string }) => (
        <span>{actionDescription ?? '-'}</span>
      )
    },
    {
      id: 'endpoint',
      label: 'Endpoint',
      renderer: ({
        requestMethod,
        requestEndpoint
      }: {
        requestMethod: string;
        requestEndpoint: string;
      }) => (
        <span>
          {requestMethod} - {requestEndpoint}
        </span>
      )
    },
    {
      id: 'parameters',
      label: 'Parameters',
      renderer: ({ requestParameters }: { requestParameters: string }) => (
        <JsonView src={requestParameters} theme="github" />
      )
    },
    {
      id: 'actions',
      label: 'Actions',
      renderer: ({ id }: { id: string }) => (
        <div className="flex gap-2">
          <IconButton onClick={() => onEditClick(id)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => handleDelete(id)}>
            <Delete />
          </IconButton>
        </div>
      )
    }
  ];

  return (
    <InnerPageTemplate header="Actions">
      <div className="flex flex-col gap-4">
        <Button className="flex self-end w-32" onClick={() => setIsModalOpen(true)}>
          Add Action
        </Button>
        <SimpleTable columns={cols} rows={actions! || []} />
        <ActionsModal isOpen={isModalOpen} onModalClose={onModalClose} action={selectedAction!} />
      </div>
    </InnerPageTemplate>
  );
};

export default ActionsPage;
