import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { NodeIcon } from 'components/molecules';
import { useAgentsService } from 'hooks';
import { customAlphabet } from 'nanoid';
import { useSearchParams } from 'react-router-dom';
import { Handle, Position, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';
import { NodeType } from 'types';
import NodeWrapperProps from './NodeWrapper.props';
import { StartNodeIcon } from 'components/atoms';

const nanoid = customAlphabet('123456789', 10);
const NodeWrapper = ({ children, nodeProps }: NodeWrapperProps) => {
  const flow = useReactFlow();
  const [searchParams] = useSearchParams();

  const { deleteNode, addNode } = useAgentsService();

  const handleDelete = () => {
    const pathwayId = searchParams.get('versionId');

    if (!pathwayId || nodeProps.data.isStart) return;

    const edgesToDelete = flow
      .getEdges()
      ?.filter((e) => e.source === nodeProps.id || e.target === nodeProps.id);

    flow.deleteElements({ nodes: [nodeProps], edges: edgesToDelete });

    console.log('deleting node', nodeProps);
    deleteNode({
      pathwayId,
      nodeId: parseInt(nodeProps.id)
    });
  };

  const handleDuplicate = () => {
    const pathwayId = searchParams.get('id');

    if (!pathwayId) return;

    const newId = nanoid();

    const newNode = {
      id: newId,
      x: nodeProps.xPos + 350,
      y: nodeProps.yPos,
      position: {
        x: nodeProps.xPos + 350,
        y: nodeProps.yPos
      },
      data: {
        name: nodeProps.data.name,
        label: nodeProps.data.label,
        text: nodeProps.data.text,
        isGlobal: nodeProps.data.isGlobal,
        isStaticText: nodeProps.data.isStaticText,
        prompt: nodeProps.data.prompt,
        isStart: nodeProps.data.isStart,
        nodeEnterCondition: nodeProps.data.nodeEnterCondition,
        userData: nodeProps.data.userData,
        agentId: nodeProps.data.agentId
      },
      type: nodeProps.type
    };
    flow.addNodes(newNode);

    addNode({
      pathwayId,
      node: newNode
    });
  };

  return (
    <div
      className={clsx(
        'rounded-lg w-[320px] border-solid  shadow-xl bg-white',
        nodeProps.data.isGlobal ? 'border-yellow6' : 'border-gray10',
        nodeProps.selected && '!border-orange'
      )}
    >
      {nodeProps.selected && (
        <Stack
          direction="column"
          className="bg-white rounded-lg border-solid border-gray10 absolute right-[-3rem]"
        >
          <Tooltip title="Delete">
            <IconButton onClick={handleDelete} disabled={nodeProps.data.isStart}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Duplicate">
            <IconButton onClick={handleDuplicate}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      <div className="divide-y divide-gray-200">
        {nodeProps.data.isGlobal && (
          <Box className="bg-yellow6 absolute top-[-10px] right-3 rounded-md pr-2 pl-2">
            <Typography className="font-bold text-[9.54px] ">Global Node</Typography>
          </Box>
        )}
        <div className="p-6 h-[48px] flex items-center border-l-0 border-t-0 border-b-1 border-r-0 border-solid border-gray10">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <span>
                <div
                  className={clsx(
                    nodeProps.data.isStart && 'bg-green6',
                    !nodeProps.data.isStart && 'bg-gray6',
                    'border border-gray-200 w-[24px] h-[24px] rounded-md flex items-center justify-center '
                  )}
                >
                  {nodeProps.data.isStart && <StartNodeIcon />}
                  {!nodeProps.data.isStart && (
                    <NodeIcon nodeType={(nodeProps.type ?? 'Default') as NodeType} />
                  )}
                </div>
              </span>
              <h2 className="text-[13px] font-medium leading-5 line-clamp-1">
                {nodeProps.data.name}
              </h2>
            </div>
            <div className="text-gray12 text-[11px] leading-4 font-medium">{nodeProps.type}</div>
          </div>
        </div>
        <div className="p-6">
          <div className="flex items-center gap-4">
            <Typography variant="body2" className="line-clamp-2 text-gray8">
              {nodeProps.data.prompt ?? nodeProps.data.text}
            </Typography>
          </div>
          {children}
        </div>
      </div>

      {!nodeProps.data.isStart && !nodeProps.data.isGlobal && (
        <Handle
          className="h-3 w-3 mt-[-.10rem] hover:h-6 hover:w-6 hover:mt-[-.5rem] bg-sky-blue border-none"
          type="target"
          position={Position.Top}
        />
      )}
      {!nodeProps.data.isGlobal && (
        <Handle
          className="h-3 w-3 mb-[-.10rem] hover:h-6 hover:w-6 hover:mb-[-.4rem] bg-sky-blue border-none"
          type="source"
          position={Position.Bottom}
        />
      )}
    </div>
  );
};

export default NodeWrapper;
