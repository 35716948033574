import { Divider, Drawer, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import GoogleDriveForm from './forms/GoogleDriveForm';
import ClickUpForm from './forms/ClickUpForm';
import ConfluenceForm from './forms/ConfluenceForm';
import JiraForm from './forms/JiraForm';
import { Sources } from 'types/models/sources.model';
import SalesforceConversationsForm from './forms/SalesforceConversationsForm';
import SalesforceArticlesForm from './forms/SalesforceArticlesForm';
import IntercomConversationsForm from './forms/IntercomConversationsForm';
import IntercomArticlesForm from './forms/IntercomArticlesForm';
import PDFForm from './forms/PDFForm';

const SourcesModal = ({ isOpen, onModalClose }: { isOpen: boolean; onModalClose: () => void }) => {
  const SourcesForm = ({ source }: { source: string }) => {
    switch (source) {
      case 'google-drive':
        return <GoogleDriveForm handleClose={onModalClose} />;
      case 'clickup':
        return <ClickUpForm handleClose={onModalClose} />;
      case 'confluence':
        return <ConfluenceForm handleClose={onModalClose} />;
      case 'jira':
        return <JiraForm handleClose={onModalClose} />;
      case 'salesforce-conversations':
        return <SalesforceConversationsForm handleClose={onModalClose} />;
      case 'salesforce-articles':
        return <SalesforceArticlesForm handleClose={onModalClose} />;
      case 'intercom-conversations':
        return <IntercomConversationsForm handleClose={onModalClose} />;
      case 'intercom-articles':
        return <IntercomArticlesForm handleClose={onModalClose} />;
      case 'pdf':
        return <PDFForm handleClose={onModalClose} />;
      default:
        return null;
    }
  };

  const [source, setSource] = useState('');

  const handleModalClose = () => {
    onModalClose();
    setSource('');
  };

  return (
    <Drawer
      className="z-[1000] flex"
      anchor="right"
      open={isOpen}
      onClose={handleModalClose}
      PaperProps={{ className: 'w-[30rem] p-4', style: { maxWidth: '20%' } }}
      sx={{ img: { maxWidth: '100%' } }}
    >
      <div className="flex flex-col gap-4">
        <h1 className="text-xl font-bold">Add Source</h1>
        <FormControl variant="outlined">
          <InputLabel id="source-options">Select a Source</InputLabel>
          <Select
            labelId="source-options"
            label="Select a Source"
            onChange={(e) => {
              setSource(e.target.value as string);
            }}
            value={source}
          >
            {Object.keys(Sources).map((key) => (
              <MenuItem key={key} value={Sources[key as keyof typeof Sources]}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider />
      </div>
      <SourcesForm source={source} />
    </Drawer>
  );
};

export default SourcesModal;
