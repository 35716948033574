import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React, { createContext, useState } from 'react';

type ConfirmationDialogProviderProps = {
  children: React.ReactNode;
};

export type ConfirmationDialogContextType = {
  openDialog: (
    dialogTitle: string,
    dialogDescription: string,
    onConfirm: () => void,
    icon?: React.ReactNode
  ) => void;
};

const initialState: ConfirmationDialogContextType = {
  openDialog: () => {}
};

export const ConfirmationDialogContext = createContext<ConfirmationDialogContextType>(initialState);

export const ConfirmationDialogProvider = ({ children }: ConfirmationDialogProviderProps) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [icon, setIcon] = useState<React.ReactNode>();
  const [description, setDescription] = useState('');
  const [confirmAction, setConfirmAction] = useState<() => void>(() => {});

  const openDialog = (
    dialogTitle: string,
    dialogDescription: string,
    onConfirm: () => void,
    icon?: React.ReactNode
  ) => {
    setIcon(icon);
    setTitle(dialogTitle);
    setDescription(dialogDescription);
    setConfirmAction(() => onConfirm);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    confirmAction();
    setOpen(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      {children}
      <Dialog
        open={open}
        onClose={closeDialog}
        PaperProps={{ className: 'min-w-[10vw] min-h-[10vh]' }}
      >
        <DialogTitle className="flex items-center">
          {icon} {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationDialogContext.Provider>
  );
};
