import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { AgentIcon } from 'components/atoms';
import { format } from 'date-fns';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface MessageProps {
  type: 'CUSTOMER' | 'COMPANY_AGENT';
  sender: string;
  timestamp: string;
  text: string;
  url?: string;
}

const Message = ({ type, sender, timestamp, text, url }: MessageProps) => {
  const navigate = useNavigate();
  const [urlVisible, setUrlVisible] = useState<boolean>(false);

  const toggleIcon = () => setUrlVisible((prev) => !prev);

  return (
    <Stack direction="column" className="mb-6" onMouseEnter={toggleIcon} onMouseLeave={toggleIcon}>
      <Stack direction="row">
        <Box className="mr-3">
          <Avatar className={clsx('w-10 h-10 text-gray8', type === 'COMPANY_AGENT' && 'bg-pink3')}>
            {type === 'CUSTOMER' && sender.substring(0, 1)}
            {type === 'COMPANY_AGENT' && <AgentIcon />}
          </Avatar>
        </Box>
        <Box>
          <Stack direction="row" className="flex items-center">
            <Typography variant="subtitle2" className="mr-7">
              {sender}
            </Typography>
            <Typography
              variant="caption"
              className="flex items-center relative before:content-['.'] before:flex before:align-middle before:absolute before:left-[-1rem] before:!m-0 before:w-[3px] before:h-[3px] before:bg-gray8 before:rounded-full text-gray8"
            >
              {timestamp && format(new Date(timestamp), 'dd MMM, yyyy HH:mm a')}
            </Typography>

            <IconButton
              className={clsx(urlVisible ? 'visible' : 'invisible')}
              onClick={() => url && window.open(url, 'blank_', 'noreferrer noopener')}
            >
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Typography variant="body1">{text}</Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Message;
