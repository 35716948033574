import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { PageHeader } from 'components/atoms';
import ToolbarPageTemplateProps from './ToolbarTemplate.props';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const ToolbarPageTemplate = ({
  backEnabled = false,
  children,
  header,
  headerLeftComponent,
  headerRightComponent
}: ToolbarPageTemplateProps) => {
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  return (
    <Grid container className="pl-[80px] ">
      <Grid
        container
        className="bg-white border border-solid border-t-0 border-l-0 border-r-0 border-gray2 mt-[-1rem] pt-4 pb-4"
      >
        <Grid item xs={12} md={12} lg={6} className="pl-4">
          <Stack direction="row" className="gap-4">
            {backEnabled && (
              <IconButton onClick={handleBack}>
                <ArrowBackIcon fontSize="small" className="text-black" />
              </IconButton>
            )}
            {header && (
              <Typography variant="h5" className="font-bold text-xl	flex items-center">
                {header}
              </Typography>
            )}
            {headerLeftComponent}
          </Stack>
        </Grid>
        {headerRightComponent && (
          <Grid item xs={12} md={12} lg={6} className="flex justify-around ml-[-1rem]">
            {headerRightComponent}
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ToolbarPageTemplate;
