import {
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import { useUser } from 'hooks/reducers';
import useTagsService from 'hooks/services/tagger/tags.service';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Tables } from 'types/models/db.type';

type Tags = Tables<'tags'>;

interface TaggerModalProps {
  isOpen: boolean;
  tagId?: string;
  onTagUpdated?: (tag: Tags) => void;
  onTagCreated?: (tag: Tags) => void;
  onModalClose: () => void;
  onTagSelected?: (tag: Tables<'tags'>) => VoidFunction;
  keys?: string[];
}

interface Filter {
  filter: string;
  organizationId: string;
}

const TaggerModal = ({
  keys,
  isOpen,
  tagId,
  onModalClose,
  onTagSelected,
  onTagCreated,
  onTagUpdated
}: TaggerModalProps) => {
  const { user } = useUser();
  const { get, getMany, getUserStatuses, put, post } = useTagsService();
  const queryClient = useQueryClient();

  const [selectedTag, setSelectedTag] = useState<Tags | null>(null);
  const [selectedTags, setSelectedTags] = useState<Tags[]>([]);
  const [filter, setFilter] = useState<Filter>({
    filter: '',
    organizationId: user?.user_metadata.organizationId
  });
  const initialState = useRef<Tags | null>(null);

  useEffect(() => {
    const fetSelectedTag = async () => {
      if (tagId) {
        const data = await get({ id: tagId });
        if (data) {
          setSelectedTag(data);
          initialState.current = data;
        }
      }
    };

    fetSelectedTag();
  }, [get, tagId]);

  const { data: tags, refetch } = useQuery({
    queryKey: ['tags', filter],
    queryFn: getMany,
    enabled: !!user?.user_metadata.organizationId
  });

  const postMutation = useMutation({
    mutationKey: 'postTag',
    mutationFn: (tag: Tags) => post(tag),
    onSuccess: (data) => {
      onTagCreated && data && onTagCreated(data);
      queryClient.invalidateQueries('tags');
      onModalClose();
    }
  });

  const editMutation = useMutation({
    mutationKey: 'editTag',
    mutationFn: (tag: Tags) => put(tag),
    onSuccess: () => {
      queryClient.invalidateQueries('tags');
      onModalClose();
    }
  });

  const statusQuery = useQuery({
    queryKey: ['tag_statuses', { organizationId: user?.user_metadata.organizationId }],
    queryFn: getUserStatuses,
    enabled: !!user?.user_metadata.organizationId
  });

  const onSubmit = useCallback(
    async (event?: React.FormEvent) => {
      event?.preventDefault();
      if (tagId) {
        editMutation.mutate(selectedTag!);
        onTagUpdated && selectedTag && onTagUpdated(selectedTag);
      } else {
        const newTag = {
          ...selectedTag,
          organization_id: user?.user_metadata.organizationId
        } as Tags;

        postMutation.mutate(newTag);
      }
    },
    [tagId, editMutation, selectedTag, postMutation, user?.user_metadata.organizationId]
  );

  useEffect(() => {
    keys?.forEach((key) => {
      setSelectedTag((prev) => ({ ...prev, [key]: undefined }) as Tags);
    });
  }, [keys]);

  const formatLabel = (label: string) => {
    const labelWithSapces = label.replace(/_/g, ' ');
    const capitalizedLabel = labelWithSapces.replace(/\b\w/g, (item) => item.toUpperCase());
    return capitalizedLabel;
  };

  const handleModalClose = () => {
    if (
      selectedTag?.name &&
      selectedTag?.description &&
      JSON.stringify(initialState.current) !== JSON.stringify(selectedTag)
    ) {
      onSubmit();
    }
    onModalClose();
    setSelectedTag(null);
  };

  const isBoolean = (key: string) => {
    return (
      key === 'is_mutually_exclusive' || key === 'can_be_deflected' || key === 'show_draft_auto'
    );
  };

  const handleSelectTags = useCallback((value: Tags[]) => {
    setSelectedTags(value);
  }, []);

  return (
    <Drawer
      className="z-[1000] flex"
      anchor="right"
      open={isOpen}
      onClose={handleModalClose}
      PaperProps={{ className: 'w-[30rem] p-4 rounded-none', style: { maxWidth: '30%' } }}
      sx={{ img: { maxWidth: '100%' } }}
    >
      <div className="flex flex-col gap-4">
        {/* TODO: Implement existing tags selection feature */}
        {/* <h1 className="text-xl font-bold">Select Tags</h1>
        {!tagId && <TagsSelector onTagsSelected={handleSelectTags} />}

        <Divider className="mt-4">OR</Divider> */}

        <h1 className="text-xl font-bold">Add Tag</h1>
        <form className="flex flex-col gap-4" onSubmit={onSubmit}>
          <FormControl key={'name'}>
            <TextField
              key={'name'}
              label={formatLabel('name')}
              variant="outlined"
              value={(selectedTag && selectedTag['name' as keyof typeof selectedTag]!) || ''}
              onChange={(e) =>
                setSelectedTag((prev) => ({ ...prev, name: e.target.value }) as Tags)
              }
              required
            />
          </FormControl>

          <FormControl key={'description'}>
            <TextField
              key={'description'}
              label={formatLabel('description')}
              variant="outlined"
              value={(selectedTag && selectedTag['description' as keyof typeof selectedTag]!) || ''}
              onChange={(e) =>
                setSelectedTag((prev) => ({ ...prev, description: e.target.value }) as Tags)
              }
              required
              multiline
              rows={4}
            />
          </FormControl>

          <FormControl className="w-full">
            <InputLabel id="status-filter">User Status</InputLabel>
            <Select
              labelId="user-status-filter"
              label="User Status"
              className="w-full"
              onChange={(e) =>
                setSelectedTag((prev) => ({ ...prev, user_status: e.target.value }) as Tags)
              }
              value={(selectedTag && selectedTag['user_status' as keyof typeof selectedTag]!) || ''}
            >
              {statusQuery?.data?.data?.map((value: any) => (
                <MenuItem key={value.user_status} value={value.user_status}>
                  {value.user_status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl key={'examples'}>
            <TextField
              key={'examples'}
              label={formatLabel('examples')}
              variant="outlined"
              value={(selectedTag && selectedTag['examples' as keyof typeof selectedTag]!) || ''}
              onChange={(e) =>
                setSelectedTag((prev) => ({ ...prev, examples: e.target.value }) as Tags)
              }
              multiline
              rows={4}
            />
          </FormControl>

          <FormControl key={'llm_additional_instructions'}>
            <TextField
              key={'llm_additional_instructions'}
              label={formatLabel('llm_additional_instructions')}
              variant="outlined"
              value={
                (selectedTag &&
                  selectedTag['llm_additional_instructions' as keyof typeof selectedTag]!) ||
                ''
              }
              onChange={(e) =>
                setSelectedTag(
                  (prev) => ({ ...prev, llm_additional_instructions: e.target.value }) as Tags
                )
              }
              multiline
              rows={4}
            />
          </FormControl>

          {keys
            ?.filter(
              (key) =>
                ![
                  'name',
                  'description',
                  'user_status',
                  'examples',
                  'llm_additional_instructions'
                ].includes(key)
            )
            .map((key) =>
              !isBoolean(key) ? (
                <TextField
                  key={key}
                  label={formatLabel(key)}
                  variant="outlined"
                  value={(selectedTag && selectedTag[key as keyof typeof selectedTag]!) || ''}
                  onChange={(e) =>
                    setSelectedTag((prev) => ({ ...prev, [key]: e.target.value }) as Tags)
                  }
                />
              ) : (
                <FormControl key={key}>
                  <FormLabel id={`${key}`}>{formatLabel(key)}</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby={`${key}`}
                    name={`${key}`}
                    value={
                      (selectedTag && selectedTag[key as keyof typeof selectedTag]!)?.toString() ??
                      false
                    }
                    onChange={(e) =>
                      setSelectedTag((prev) => ({ ...prev, [key]: e.target.value }) as Tags)
                    }
                  >
                    <FormControlLabel value={true} control={<Radio />} label="True" />
                    <FormControlLabel value={false} control={<Radio />} label="False" />
                  </RadioGroup>
                </FormControl>
              )
            )}
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      </div>
    </Drawer>
  );
};

export default TaggerModal;
