import { supabaseClient } from 'api/supabaseClient';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Router from 'routes';
import ROUTE_PATHS from 'routes/paths';

function App(): React.ReactElement {
  return <Router />;
}

export default App;
