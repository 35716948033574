import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useCallback } from 'react';
import { Tables } from 'types/models/db.type';

import { BaseAdapter } from '../adapters';
import { Analytics } from 'types/models';
import { useToast } from 'contexts/ToastContext';

const useAnalyticsService = () => {
  const { adapt } = BaseAdapter<Tables<'analytics'>, Analytics>();
  const { ANALYTICS_TABLE } = KBB_SCHEMA;

  const { showToast } = useToast();

  const getMany = useCallback(
    async (organizationId: string) => {
      if (!organizationId) return;

      let { data, error } = await supabaseClient
        .from(ANALYTICS_TABLE)
        .select('*')
        .eq('organization_id', organizationId);

      if (error) {
        showToast(error.message, 'error');
        return;
      }

      return data?.map((item) => adapt(item));
    },
    [ANALYTICS_TABLE, adapt, showToast]
  );

  return { getMany };
};

export default useAnalyticsService;
