import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 1
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          borderRadius: '1rem',
          padding: '.75rem 1.5rem'
        }
      }
    }
  };
}
