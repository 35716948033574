import { Box } from '@mui/material';
import TabPanelProps from './TabPanel.props';

function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      id={`tab-panel-${index}`}
      hidden={value !== index}
      aria-labelledby={`tab-panel-${index}`}
      role="tabpanel"
      {...other}
    >
      <Box p={0}>{value === index && children}</Box>
    </Box>
  );
}

export default TabPanel;
