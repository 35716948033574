import { Category } from '@mui/icons-material';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LogoutIcon from '@mui/icons-material/Logout';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import QuizIcon from '@mui/icons-material/Quiz';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import SourceIcon from '@mui/icons-material/Source';
import { Box } from '@mui/material';
import { LogoIcon, ProsperIcon } from 'components/atoms';
import { UserActionTypes } from 'contexts/reducers';
import { useFeatureFlag } from 'hooks';
import { useUser, useUserDispatch } from 'hooks/reducers';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATHS from 'routes/paths';
import { VerticalNav } from '..';
import { supabaseClient } from 'api/supabaseClient';
import PhoneIcon from '@mui/icons-material/Phone';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

function LeftNavigation(): React.ReactElement {
  const [open, setOpen] = useState(false);
  const { user } = useUser();

  const dispatch = useUserDispatch();
  const navigate = useNavigate();
  const { isDisabled } = useFeatureFlag();

  const toggleDrawer = useCallback(
    (newOpen: boolean) => () => {
      setOpen(newOpen);
    },
    []
  );

  const handleLogout = useCallback(async () => {
    await supabaseClient.auth.signOut();
    dispatch({ type: UserActionTypes.LogOutUser, payload: {} });
    navigate(ROUTE_PATHS.AUTH);
  }, [dispatch, navigate]);

  let navbarConfig = [
    {
      subheader: 'Content',
      items: [
        {
          title: 'Sources',
          path: ROUTE_PATHS.SOURCES,
          icon: <SourceIcon />
        },
        { title: 'FAQs', path: ROUTE_PATHS.FAQ_LIST_PAGE, icon: <QuizIcon /> },
        // TODO: implement suggested articles
        // {
        //   title: 'Suggested Articles',
        //   disabled: isDisabled,
        //   path: ROUTE_PATHS.SUGGESTED_ARTICLES,
        //   icon: <NewspaperIcon />
        // },
        {
          title: 'Categories',
          path: ROUTE_PATHS.CATEGORIES,
          icon: <Category />
        }
      ]
    },
    {
      subheader: 'Customization',
      items: [
        { title: 'Tags', path: ROUTE_PATHS.TAGS, icon: <LocalOfferIcon />, disabled: false },
        {
          title: 'Actions',
          path: ROUTE_PATHS.ACTIONS,
          icon: <SmartButtonIcon />,
          disabled: false
        }
      ]
    }
  ];

  const voiceSection = [
    {
      subheader: 'Voice',
      items: [
        {
          title: 'Phone numbers',
          path: ROUTE_PATHS.PHONE_NUMBERS,
          icon: <PhoneIcon />,
          disabled: true
        },
        { title: 'Agents', path: ROUTE_PATHS.AGENTS, icon: <SupportAgentIcon /> },
        {
          title: 'Call logs',
          disabled: isDisabled,
          path: ROUTE_PATHS.CALL_LOGS,
          icon: <LibraryBooksIcon />
        }
      ]
    }
  ];

  const performance = {
    subheader: 'Performance',
    items: [
      { title: 'Analytics', path: ROUTE_PATHS.ANALYTICS, icon: <QueryStatsIcon />, disabled: false }
    ]
  };

  if (user?.user_metadata?.organizations.evaluatorEnabled) {
    performance.items.unshift({
      title: 'Evaluations',
      path: ROUTE_PATHS.EVALUATOR,
      icon: <ChecklistRtlIcon />,
      disabled: false
    });
  }

  const bottomItems = [
    {
      disabled: true,
      title: 'Settings',
      icon: <SettingsSuggestIcon />,
      path: ROUTE_PATHS.SETTINGS
    },
    {
      title: 'Logout',
      path: '#',
      icon: <LogoutIcon />,
      onClick: handleLogout
    }
  ];

  useEffect(() => {
    return () => {
      // Close the drawer on unmount
      open && toggleDrawer(false);
    };
  }, [open, toggleDrawer]);

  return (
    <nav
      role="navigation"
      aria-label="Left Navigation Drawer"
      className={`h-full absolute z-50 bg-white navbar duration-400 transition-all shadow-lg ${
        open ? 'w-[15rem] ' : 'w-[5rem]'
      }`}
      onMouseOver={toggleDrawer(true)}
      onMouseLeave={() => setTimeout(toggleDrawer(false), 100)}
    >
      <Box className="w-full flex justify-star mt-2">
        {open && <LogoIcon className="ml-6" sx={{ width: '140px', height: '60px' }} />}
        {!open && <ProsperIcon className="ml-6" sx={{ width: '40px', height: '60px' }} />}
      </Box>
      {user?.user_metadata?.organizations.voiceEnabled && (
        <VerticalNav
          expanded={!open}
          navConfig={[...voiceSection, performance]}
          bottomItems={bottomItems}
        />
      )}
      {!user?.user_metadata?.organizations.voiceEnabled && (
        <VerticalNav
          expanded={!open}
          navConfig={[...navbarConfig, performance]}
          bottomItems={bottomItems}
        />
      )}
    </nav>
  );
}

export default LeftNavigation;
