import { Typography } from '@mui/material';
import { NodeWrapper } from 'components/organisms';
import { NodeProps } from 'reactflow';
import { KnowledgeBaseProps } from './knowledgeBaseNode.props';

export function KnowledgeBaseNode(props: NodeProps<KnowledgeBaseProps>) {
  return (
    <NodeWrapper nodeProps={props}>
      <Typography variant="body2">{props.data.kb}</Typography>
    </NodeWrapper>
  );
}
