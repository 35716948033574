import { Search, Clear } from '@mui/icons-material';
import { IconButton, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import SearchFieldProps from './SearchField.props';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

function SearchField({
  bordered,
  onClear,
  toggle = false,
  ...props
}: Readonly<SearchFieldProps>): React.ReactElement {
  const [showSearch, setShowSearch] = useState<boolean>(false);

  // Handler for toggling the search field
  const handleToggleSearch = () => {
    setShowSearch((prevState) => !prevState);
  };

  if (toggle) {
    return (
      <Stack direction="row" className="w-full justify-end">
        {showSearch && (
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <Search className="text-gray2" /> */}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => onClear && onClear()}
                  className="hover:cursor-pointer"
                >
                  <>{props.value && <Clear className="text-gray2" />}</>
                </InputAdornment>
              ),
              className: 'h-[50px]'
            }}
            variant="outlined"
            className="bg-white w-[75%]"
            sx={{ '& fieldset': { border: bordered ? '1px solid gray5' : 'none' } }}
            {...props}
          />
        )}
        <Tooltip title={showSearch ? 'Hide search' : 'Show search'}>
          <IconButton onClick={handleToggleSearch} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search className="text-gray2" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="start"
            onClick={() => onClear && onClear()}
            className="hover:cursor-pointer"
          >
            <>{props.value && <Clear className="text-gray2" />}</>
          </InputAdornment>
        ),
        className: 'h-[50px]'
      }}
      variant="outlined"
      className="bg-white w-[75%]"
      sx={{ '& fieldset': { border: bordered ? '1px solid gray5' : 'none' } }}
      {...props}
    />
  );
}

export default SearchField;
