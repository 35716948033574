import { SvgIcon, SvgIconProps } from '@mui/material';

function StickyNoteIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.04995 11.9502H8.34995V9.0252C8.34995 8.83395 8.41464 8.67363 8.54401 8.54426C8.67339 8.41488 8.8337 8.3502 9.02495 8.3502H11.95V2.0502H2.04995V11.9502ZM2.04363 13.3002C1.67284 13.3002 1.3562 13.168 1.0937 12.9036C0.831201 12.6393 0.699951 12.3214 0.699951 11.9502V2.0502C0.699951 1.67895 0.832139 1.36113 1.09651 1.09676C1.36089 0.832383 1.6787 0.700195 2.04995 0.700195H11.95C12.3212 0.700195 12.639 0.832164 12.9034 1.0961C13.1678 1.36003 13.3 1.6773 13.3 2.04793V8.23065C13.3 8.41035 13.2687 8.58145 13.2062 8.74395C13.1437 8.90645 13.0424 9.05772 12.9024 9.19777L9.19753 12.9026C9.05748 13.0427 8.90599 13.1439 8.74306 13.2064C8.58015 13.2689 8.40881 13.3002 8.22903 13.3002H2.04363ZM6.32495 8.3502H4.07495C3.8837 8.3502 3.72339 8.28589 3.59401 8.15728C3.46464 8.02865 3.39995 7.86928 3.39995 7.67915C3.39995 7.48901 3.46464 7.32832 3.59401 7.19707C3.72339 7.06582 3.8837 7.0002 4.07495 7.0002H6.32495C6.5162 7.0002 6.67651 7.0645 6.80589 7.19311C6.93526 7.32174 6.99995 7.48111 6.99995 7.67124C6.99995 7.86138 6.93526 8.02207 6.80589 8.15332C6.67651 8.28457 6.5162 8.3502 6.32495 8.3502ZM9.92495 5.6502H4.07495C3.8837 5.6502 3.72339 5.58589 3.59401 5.45728C3.46464 5.32865 3.39995 5.16928 3.39995 4.97915C3.39995 4.78901 3.46464 4.62832 3.59401 4.49707C3.72339 4.36582 3.8837 4.3002 4.07495 4.3002H9.92495C10.1162 4.3002 10.2765 4.3645 10.4059 4.49311C10.5353 4.62174 10.6 4.78111 10.6 4.97124C10.6 5.16138 10.5353 5.32207 10.4059 5.45332C10.2765 5.58457 10.1162 5.6502 9.92495 5.6502Z"
        fill="#FF421C"
      />
    </SvgIcon>
  );
}

export default StickyNoteIcon;
