import { FilterList } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Badge,
  Box,
  Button,
  Chip,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { supabaseClient } from 'api/supabaseClient';
import clsx from 'clsx';
import { SearchField, TabPanel } from 'components/molecules';
import { AgentModal, SimpleTable } from 'components/organisms';
import { InnerPageTemplate } from 'components/templates';
import { useToast } from 'contexts/ToastContext';
import { differenceInMinutes, format } from 'date-fns';
import { useCallLogService, useDebounce } from 'hooks';
import { useUser } from 'hooks/reducers';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import 'react18-json-view/src/style.css';
import ROUTE_PATHS from 'routes/paths';
import { TableColumn } from 'types';

interface CallLogFilter {
  filter: string;
  page: number;
  limit: number;
  to: string;
  from: string;
  outcome: string;
  endedBy: string;
  organizationId: string;
}

const CallLogsPage = () => {
  const { user } = useUser();
  const { debounce } = useDebounce();
  const { getMany, exportFiltered } = useCallLogService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  const [activeLog, setActiveLog] = useState<any>();
  const [qaRunEnabled, setQaRunEnabled] = useState<boolean>(true);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<CallLogFilter>({
    filter: searchParams.get('filter') ?? '',
    page: parseInt(searchParams.get('page') ?? '1'),
    limit: parseInt(searchParams.get('limit') ?? '10'),
    organizationId: user?.user_metadata.organizationId,
    to: searchParams.get('to') ?? '',
    from: searchParams.get('from') ?? '',
    outcome: searchParams.get('outcome') ?? '',
    endedBy: searchParams.get('endedBy') ?? ''
  });

  const [searchText, setSearchText] = useState<string>(filter.filter);

  const { data: callLogs, refetch } = useQuery({
    queryKey: ['get_call_logs', filter],
    queryFn: getMany,
    enabled: !!user?.user_metadata.organizationId,
    keepPreviousData: true
  });

  interface CallRecordingPlayerProps {
    data: any;
  }
  const CallRecordingPlayer = ({ data }: CallRecordingPlayerProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [recordingUrl, setRecordingUrl] = useState<string>(data.recording_url);
    const audioRef = useRef<any>();

    const handleRefreshRecording = async () => {
      const call = data.call_id;

      const res = await supabaseClient.functions.invoke('daily-refresh-recording-link', {
        body: { id: data.id, call_id: call.replace('https://prosper.daily.co/', '') }
      });

      if (res.data) {
        console.log('recording_url', res.data.recording_url);
        setRecordingUrl(res.data.recording_url);
        audioRef.current?.load();
      }
    };

    useEffect(() => {
      if (audioRef.current) {
        const handleCanPlay = () => setLoading(false);
        const handleError = () => setLoading(false); // Optionally handle errors

        audioRef.current.addEventListener('canplay', handleCanPlay);
        audioRef.current.addEventListener('error', handleError);

        return () => {
          if (audioRef.current) {
            audioRef.current.removeEventListener('canplay', handleCanPlay);
            audioRef.current.removeEventListener('error', handleError);
          }
        };
      }
    }, [recordingUrl]);

    return (
      <Stack direction="row" className="w-full items-center justify-between">
        <style>
          {`
          audio::-webkit-media-controls-current-time-display,
          audio::-webkit-media-controls-time-remaining-display {
              display: none;
          }
        `}
        </style>
        <audio controls className="w-full" ref={audioRef} style={{}}>
          <source src={recordingUrl} type="audio/mp4" />
          Your browser does not support the audio element.
        </audio>

        <Tooltip title={'Refresh recording link'}>
          <IconButton onClick={() => handleRefreshRecording()}>
            <RefreshIcon fontSize="small" className={clsx(loading && 'animate-spin')} />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  function formatTime(seconds: number): string {
    // Ensure the seconds is a positive number
    const totalSeconds = Math.max(0, Math.floor(seconds));

    // Calculate minutes and seconds
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;

    // Format minutes and seconds as two-digit numbers
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const cols: TableColumn<any>[] = [
    {
      id: 'id',
      label: 'Call ID',
      cellProps: { className: 'min-w-[6rem]' }
    },
    {
      id: 'agent_name',
      label: 'agent name',
      cellProps: { className: 'min-w-[20rem]' },
      renderer: (item: any) => {
        return <p>{item.agent_name}</p>;
      }
    },
    {
      id: 'created_at',
      label: 'created',
      cellProps: { className: 'min-w-[10rem]' },
      renderer: (item: any) => {
        return (
          <p>{item.created_at ? format(new Date(item.created_at), 'yyyy-MM-dd HH:mm') : ''}</p>
        );
      }
    },
    {
      id: 'to',
      label: 'to',
      cellProps: { className: 'min-w-[10rem]' },
      renderer: (item: any) => {
        return <p>{item.to}</p>;
      }
    },
    {
      id: 'from',
      label: 'from',
      cellProps: { className: 'min-w-[10rem]' },
      renderer: (item: any) => {
        return <p>{item.from}</p>;
      }
    },
    {
      id: 'call_length',
      label: 'Duration',
      renderer: (item: any) => {
        return <p>{formatTime(item?.call_length ?? 0)}</p>;
      }
    },
    {
      id: 'call_ended_reason',
      label: 'Outcome',
      cellProps: { className: 'min-w-[11rem]' },
      renderer: (item: any) => {
        let reason = item?.call_ended_reason;

        if (reason?.toLowerCase() === 'abandon_hangup') {
          reason = 'hangup';
        }

        if (reason?.toLowerCase() === 'abandon_transfer') {
          reason = 'transfer';
        }

        return (
          <Chip
            className={clsx(
              'font-semibold',
              reason === 'success' ? 'bg-green4 text-green5' : 'bg-yellow4 text-yellow5'
            )}
            label={reason}
          />
        );
      }
    },
    {
      id: 'call_ended_by',
      label: 'Ended By',
      renderer: (item: any) => {
        return <p>{item?.call_ended_by}</p>;
      }
    },
    {
      id: 'recording_url',
      cellProps: {
        width: '20%',
        className: 'text-gray2 min-w-[300px]'
      },
      label: 'recording',
      renderer: (item: any) => {
        if (item.recording_url === 'recording-disabled')
          return <Chip color="warning" label={item.recording_url}></Chip>;

        return <CallRecordingPlayer data={item} />;
      }
    },
    {
      id: 'language',
      label: 'language',
      renderer: (item: any) => {
        return <p>{item.language}</p>;
      }
    }
  ];

  const updateSearchParamsAndFilter = useCallback(
    (field: keyof CallLogFilter, value: string) => {
      setSearchParams((params) => {
        params.set(field, value);
        return params;
      });

      setFilter((prevState) => ({
        ...prevState,
        [field]: value
      }));
    },
    [setSearchParams]
  );

  const setSearchParamDebounced = useMemo(
    () =>
      debounce((field: keyof CallLogFilter, value: string) => {
        updateSearchParamsAndFilter(field, value);
      }, 300),
    [debounce, updateSearchParamsAndFilter]
  );

  const handleFilterChange = useCallback(
    (field: keyof CallLogFilter, value: any, effect: 'immediate' | 'debounced' = 'immediate') => {
      if (effect === 'immediate') {
        updateSearchParamsAndFilter(field, value);
      } else {
        setSearchParamDebounced(field, value);
      }
    },
    [setSearchParamDebounced, updateSearchParamsAndFilter]
  );

  const handleModalClose = () => {
    setIsDrawerOpen(false);
  };

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);

      const lastQARun = localStorage.getItem(activeLog?.id);

      if (!lastQARun) {
        setQaRunEnabled(true);
        return;
      }

      if (lastQARun && differenceInMinutes(new Date(lastQARun), new Date()) <= 2) {
        localStorage.removeItem(activeLog?.id);
        setQaRunEnabled(false);
      }
    },
    [activeLog?.id]
  );

  const handleRowClick = (item: any) => {
    setActiveLog(item);
    console.log('call log', item);
    setIsDrawerOpen(true);
  };

  const getTextColorByRole = (role: string) => {
    const roleColors = {
      tools_available: 'text-blue',
      assistant: 'text-orange',
      system: 'text-red3',
      tool_call: 'text-yellow5',
      user: 'text-green5'
    };

    return roleColors[role as keyof typeof roleColors] ?? 'text-black';
  };

  const CollapseContent = ({
    id,
    role,
    content
  }: {
    id: string;
    role: string;
    content: string;
  }) => {
    console.log('role-content', role, content);
    const [collapsed, setCollapsed] = useState<boolean>(false);

    return (
      <ListItemText>
        <Stack direction="row" className="w-ful gap-2 flex items-center overflow-x-auto">
          <Box width="90%">
            {id !== undefined && (
              <Typography variant="caption" className="text-gray7 font-thin text-xs leading-4 mr-2">
                {id}
              </Typography>
            )}
            {role && (
              <Typography
                variant="caption"
                className={clsx('text-xs leading-4 font-medium', getTextColorByRole(role))}
              >
                <span>[</span>
                {role}
                <span>]</span>
              </Typography>
            )}
          </Box>
          {content.length > 100 && (
            <Box width="10%">
              {!collapsed && (
                <IconButton onClick={() => setCollapsed(true)}>
                  <ExpandMoreIcon fontSize="small" />
                </IconButton>
              )}
              {collapsed && (
                <IconButton onClick={() => setCollapsed(false)}>
                  <ExpandLessIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          )}
        </Stack>

        <Box className="w-full max-w-full overflow-x-auto">
          {!collapsed && (
            <Stack direction="column">
              <p className="text-black font-normal text-sm leading-5 text-justify whitespace-pre-wrap m-0">
                {content.length > 100 ? `${content.substring(0, 100)}...` : content}
              </p>
            </Stack>
          )}
          {collapsed && (
            <Stack direction="column">
              <p className="text-black font-normal text-sm leading-5 text-justify whitespace-pre-wrap m-0">
                {content}
              </p>
            </Stack>
          )}
        </Box>
      </ListItemText>
    );
  };

  const CollapseListItem = ({
    title,
    subtitle,
    children
  }: {
    title: string;
    subtitle: string;
    children: React.ReactNode;
  }) => {
    const [collapsed, setCollapsed] = useState<boolean>(true);

    return (
      <Box
        className={clsx(
          'pt-4 pb-2 pl-4 pr-4 mt-4 border-solid border-gray10 rounded-t-xl rounded-b-xl'
        )}
      >
        <Stack>
          <Box className="w-full">
            <Typography variant="subtitle1" className="capitalize">
              {title}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" className="w-full">
          <Box className="w-[95%]">
            {subtitle && (
              <Typography variant="h5" className="capitalize">
                {typeof subtitle === 'string' ? subtitle.toLowerCase() : subtitle}
              </Typography>
            )}
          </Box>
          <Box className="w-[5%]">
            <IconButton onClick={() => setCollapsed((prev) => !prev)}>
              {collapsed ? (
                <ExpandMoreIcon fontSize="small" />
              ) : (
                <ExpandLessIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
        </Stack>
        {!collapsed && children}
      </Box>
    );
  };

  const handleRun = useCallback(async () => {
    if (!activeLog) return;

    const res = await fetch(
      `${process.env.REACT_APP_VOICE_SERVICE_URL}/qa?call_log_id=${activeLog?.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (res.ok) {
      showToast('QA parameters refreshed successfully!', 'success');
      localStorage.setItem(activeLog?.id, new Date().toString());
      setQaRunEnabled(false);
    }
  }, [activeLog, showToast]);

  const handleEdit = () => {
    if (!activeLog) return;
    navigate(ROUTE_PATHS.AGENT_PERFORMANCE(activeLog?.v_pathways?.agent_id));
  };

  return (
    <InnerPageTemplate
      header="Call Logs"
      noPadding
      headerRightComponent={
        <Stack direction="row" className="w-full mr-8 gap-4">
          <SearchField
            bordered
            toggle
            id="search-filter"
            className="bg-white w-full"
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              handleFilterChange('filter', e.target.value, 'debounced');
            }}
            onClear={() => {
              setSearchText('');
              handleFilterChange('filter', '', 'immediate');
            }}
          />
          <Tooltip title="Export to CSV">
            <IconButton onClick={() => exportFiltered(filter)}>
              <DownloadIcon className="text-black" />
            </IconButton>
          </Tooltip>
          <CallLogsFilterOptions
            handleFilterChange={(field: keyof CallLogFilter, value: string | string[]) => {
              handleFilterChange('page', 1);
              handleFilterChange(field, value, 'immediate');
            }}
            filter={filter}
          />
        </Stack>
      }
    >
      {callLogs?.data && callLogs?.data?.length === 0 && searchText === '' && (
        <Box className="w-full text-center">
          <Typography variant="body1">No call logs available yet</Typography>
        </Box>
      )}

      {callLogs?.data && callLogs?.data?.length > 0 && (
        <SimpleTable
          columns={cols}
          onRowClick={handleRowClick}
          rows={callLogs?.data || []}
          tableContainerProps={{ sx: { maxHeight: '72vh', padding: '0', marginTop: '1.5rem' } }}
          tableHeaderCellProps={{
            sx: {
              position: 'sticky',
              top: -18,
              backgroundColor: 'white',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              zIndex: 1
            }
          }}
          tableRowProps={{
            sx: {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }
          }}
          tableBodyCellProps={{
            sx: {
              display: '',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: '20px',
              maxWidth: '40px',
              padding: '0 1rem',
              cursor: 'default'
            }
          }}
        />
      )}
      <Grid container>
        <Grid item xs={12} className="mt-4 pr-2 pl-6 flex justify-end">
          <Pagination
            page={filter.page}
            count={Math.ceil((callLogs?.count ?? 0) / filter.limit)}
            onChange={(e, value) => {
              setSearchParams((params) => {
                params.set('page', value.toString());
                return params;
              });
              setFilter((prevValue) => ({ ...prevValue, page: value }));
            }}
            color="primary"
            className="mt-2"
            size="small"
          />
        </Grid>
      </Grid>
      <AgentModal
        isOpen={isOpen}
        onModalClose={() => {
          setOpen(false);
          refetch();
        }}
        agentId=""
      />

      <Drawer
        className="z-[1000] flex"
        anchor="right"
        open={isDrawerOpen}
        onClose={handleModalClose}
        PaperProps={{ className: 'w-[30rem] p-4 rounded-none', style: { maxWidth: '30%' } }}
        sx={{ img: { maxWidth: '100%' } }}
      >
        <div className="flex flex-col gap-4">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} aria-label="Call logs details tabs">
                <Tab label="Logs" className="capitalize font-semibold" />
                <Tab label="Data" className="capitalize font-semibold" />
                <Tab label="QA" className="capitalize font-semibold" />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Box className="w-full mt-2">
                <List className="w-full p-2">
                  {activeLog?.call_logs?.map((item: any, idx: number) => (
                    <ListItem
                      key={item.id}
                      className={clsx(
                        'pl-4 pr-4  border-b-0 border-solid border-gray10',
                        idx === 0 && 'rounded-t-xl',
                        idx === activeLog?.call_logs?.length - 1 && 'rounded-b-xl border-b-2'
                      )}
                    >
                      <CollapseContent id={item.id} role={item.role} content={item.content} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <List className="w-full p-2">
                {activeLog &&
                  activeLog?.call_variables !== undefined &&
                  Object.keys(activeLog?.call_variables)?.map((key: any, idx: number) => (
                    <ListItem
                      key={key}
                      className={clsx(
                        'pt-2 pb-2 pl-4 pr-4 border-b-0 border-solid border-gray10',
                        idx === 0 && 'rounded-t-xl',
                        idx === Object.keys(activeLog?.call_variables)?.length - 1 &&
                          'rounded-b-xl border-b-2'
                      )}
                    >
                      <ListItemText>
                        <Stack
                          direction="row"
                          className="w-ful h-[60px] gap-2 flex items-center overflow-x-auto"
                        >
                          <Typography
                            variant="subtitle2"
                            className="text-black font-bold text-sm leading-4 "
                          >
                            {key}
                          </Typography>
                          <Typography
                            variant="caption"
                            className="text-black font-normal text-sm leading-4 "
                          >
                            {activeLog?.call_variables[key]}
                          </Typography>
                        </Stack>
                      </ListItemText>
                    </ListItem>
                  ))}
              </List>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Stack direction="row" className="gap-3 mb-6 mt-6">
                <Stack direction="column">
                  <Stack direction="row" className="w-full">
                    <Tooltip title={'Update QA parameters'}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="inherit"
                        onClick={handleRun}
                        disabled={!qaRunEnabled}
                        className="mr-4"
                      >
                        <RefreshIcon fontSize="small" className="mr-2" />
                        Run
                      </Button>
                    </Tooltip>
                    <Tooltip title={'Edit QA parameters'}>
                      <Button size="small" variant="outlined" color="inherit" onClick={handleEdit}>
                        Edit
                      </Button>
                    </Tooltip>
                  </Stack>
                  {!qaRunEnabled && (
                    <Typography>
                      We are generating the auto QA for this call. Once it finishes, it will
                      automatically appear in this page
                    </Typography>
                  )}
                </Stack>
              </Stack>
              {activeLog?.qa_json?.qa_rubric && (
                <CollapseListItem
                  title={'QA Rubric'}
                  subtitle={`${activeLog?.qa_json?.qa_rubric?.length}/${activeLog?.qa_json?.qa_rubric?.length}`}
                >
                  <>
                    {activeLog?.qa_json?.qa_rubric?.map((item: any, idx: number) => (
                      <ListItemText
                        className="w-full pt-3 pb-3 pl-4 pr-4 border-l-0 border-r-0 border-b-0 border-solid border-gray10"
                        key={idx}
                      >
                        <Stack direction="column" className="w-ful gap-2 flex overflow-x-auto">
                          {item.question !== undefined && (
                            <Typography
                              variant="caption"
                              className="text-black font-normal text-sm leading-5 "
                            >
                              <span className="font-bold">Q: </span>
                              {item.question}
                            </Typography>
                          )}
                          {item.answer && (
                            <Typography
                              variant="caption"
                              className="text-black font-normal text-sm leading-5 "
                            >
                              <span className="font-bold">A: </span>
                              {item.answer}
                            </Typography>
                          )}
                        </Stack>
                      </ListItemText>
                    ))}
                  </>
                </CollapseListItem>
              )}

              {activeLog?.qa_json?.repetitions && (
                <CollapseListItem
                  title={'Repetitions'}
                  subtitle={activeLog?.qa_json?.repetitions?.length}
                >
                  <>
                    {activeLog?.qa_json?.repetitions?.map((item: any, idx: number) => (
                      <ListItemText
                        className="w-full pt-3 pb-3 pl-4 pr-4 border-l-0 border-r-0 border-b-0 border-solid border-gray10"
                        key={idx}
                      >
                        <Stack direction="column" className="w-ful gap-2 flex overflow-x-auto">
                          {item.reasoning !== undefined && (
                            <Typography
                              variant="caption"
                              className="text-black font-normal text-sm leading-5 "
                            >
                              <span className="font-bold">Reasoning: </span>
                              {item.reasoning}
                            </Typography>
                          )}
                          {item.intervention_text && (
                            <Typography
                              variant="caption"
                              className="text-black font-normal text-sm leading-5 "
                            >
                              <span className="font-bold">Intervention Text: </span>
                              {item.intervention_text}
                            </Typography>
                          )}
                        </Stack>
                      </ListItemText>
                    ))}
                  </>
                </CollapseListItem>
              )}

              {activeLog?.qa_json?.sentiment_analysis && (
                <CollapseListItem
                  title={'Sentiment'}
                  subtitle={activeLog?.qa_json?.sentiment_analysis.sentiment}
                >
                  <ListItemText className="w-full pt-3 pb-3 pl-4 pr-4 border-l-0 border-r-0 border-b-0 border-solid border-gray10">
                    <Stack direction="column" className="w-ful gap-2 flex overflow-x-auto">
                      {activeLog?.qa_json?.sentiment_analysis.reasoning !== undefined && (
                        <Typography
                          variant="caption"
                          className="text-black font-normal text-sm leading-5 "
                        >
                          <span className="font-bold">Reasoning: </span>
                          {activeLog?.qa_json?.sentiment_analysis.reasoning}
                        </Typography>
                      )}
                    </Stack>
                  </ListItemText>
                </CollapseListItem>
              )}

              {activeLog?.qa_json?.unanswered_questions && (
                <CollapseListItem
                  title={'Unanswered Questions'}
                  subtitle={activeLog?.qa_json?.unanswered_questions?.length}
                >
                  <>
                    {activeLog?.qa_json?.unanswered_questions?.map((item: any, idx: number) => (
                      <ListItemText
                        className="w-full pt-3 pb-3 pl-4 pr-4 border-l-0 border-r-0 border-b-0 border-solid border-gray10"
                        key={idx}
                      >
                        <Stack direction="column" className="w-ful gap-2 flex overflow-x-auto">
                          {item.question !== undefined && (
                            <Typography
                              variant="caption"
                              className="text-black font-normal text-sm leading-5 "
                            >
                              <span className="font-bold">Q: </span>
                              {item.question}
                            </Typography>
                          )}
                          {item.intervention_text && (
                            <Typography
                              variant="caption"
                              className="text-black font-normal text-sm leading-5 "
                            >
                              <span className="font-bold">Intervention Text: </span>
                              {item.intervention_text}
                            </Typography>
                          )}
                        </Stack>
                      </ListItemText>
                    ))}
                  </>
                </CollapseListItem>
              )}
            </TabPanel>
          </Box>
        </div>
      </Drawer>
    </InnerPageTemplate>
  );
};

type CallLogsFilterOptionsProps = {
  handleFilterChange: (field: keyof CallLogFilter, value: string | string[]) => void;
  filter: CallLogFilter;
};

const CallLogsFilterOptions = ({ handleFilterChange, filter }: CallLogsFilterOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const location = useLocation();
  const { user } = useUser();
  const { getEndedBy, getFrom, getOutcome, getTo } = useCallLogService();

  const getEndedByQuery = useQuery({
    queryKey: ['call_log_ended_by', { organizationId: user?.user_metadata.organizationId }],
    queryFn: getEndedBy,
    enabled: !!user?.user_metadata.organizationId
  });

  const getFromQuery = useQuery({
    queryKey: ['call_log_from', { organizationId: user?.user_metadata.organizationId }],
    queryFn: getFrom,
    enabled: !!user?.user_metadata.organizationId
  });

  const getOutcomeQuery = useQuery({
    queryKey: ['call_log_outcome', { organizationId: user?.user_metadata.organizationId }],
    queryFn: getOutcome,
    enabled: !!user?.user_metadata.organizationId
  });

  const getToQuery = useQuery({
    queryKey: ['call_log_to', { organizationId: user?.user_metadata.organizationId }],
    queryFn: getTo,
    enabled: !!user?.user_metadata.organizationId
  });

  const searchParams = new URLSearchParams(location.search);
  const ignoredParams = ['filter', 'sort', 'page'];
  const filteredParams = Array.from(searchParams.keys()).filter(
    (key) => !ignoredParams.includes(key)
  );
  const numFilters = filteredParams.length;

  return (
    <>
      <div className="flex items-center">
        <Badge
          badgeContent={numFilters}
          color="primary"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <FilterList
            className="h-6 w-6 hover:text-orange cursor-pointer"
            onClick={(e) => setAnchorEl(e?.currentTarget)}
          />
        </Badge>
      </div>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={() => setAnchorEl(null)}
      >
        <div className="flex flex-col p-4 min-w-[20rem]">
          <h3 className="text-xs pb-2">Filter by</h3>
          <div className="flex gap-4 flex-col">
            <FormControl className="w-full">
              <InputLabel id="status-filter">From</InputLabel>
              <Select
                labelId="from-filter"
                label="From"
                className="w-full"
                onChange={(e) => handleFilterChange('from', e.target.value as string)}
                value={filter.from}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {getFromQuery?.data?.data?.map((value: any) => (
                  <MenuItem key={value.from} value={value.from}>
                    {value.from}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="w-full">
              <InputLabel id="status-filter">To</InputLabel>
              <Select
                labelId="to-filter"
                label="to"
                className="w-full"
                onChange={(e) => handleFilterChange('to', e.target.value as string)}
                value={filter.to}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {getToQuery?.data?.data?.map((value: any) => (
                  <MenuItem key={value.to} value={value.to}>
                    {value.to}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="w-full">
              <InputLabel id="status-filter">Outcome</InputLabel>
              <Select
                labelId="outcome-filter"
                label="outcome"
                className="w-full"
                onChange={(e) => handleFilterChange('outcome', e.target.value as string)}
                value={filter.outcome}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {getOutcomeQuery?.data?.data?.map((value: any) => (
                  <MenuItem key={value.call_ended_reason} value={value.call_ended_reason}>
                    {value.call_ended_reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="w-full">
              <InputLabel id="status-filter">Ended By</InputLabel>
              <Select
                labelId="ended-by-filter"
                label="Ended by"
                className="w-full"
                onChange={(e) => handleFilterChange('endedBy', e.target.value as string)}
                value={filter.endedBy}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {getEndedByQuery?.data?.data?.map((value: any) => (
                  <MenuItem key={value.call_ended_by} value={value.call_ended_by}>
                    {value.call_ended_by}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default CallLogsPage;
