import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { customAlphabet } from 'nanoid';
import { useCallback } from 'react';
import { QueryFunctionContext } from 'react-query';
import { Edge, Node } from 'reactflow';
import { Database } from 'types/models/db.type';
import { getValuable } from 'utils';

type Agent = Database['public']['Tables']['v_agents']['Row'];
type AgentPathway = Database['public']['Tables']['v_pathways']['Row'];

type GetManyParams = QueryFunctionContext<
  [
    'agents_query',
    {
      filter: string;
      status: 'DRAFT' | 'PUBLISHED' | 'all' | undefined;
      page: number | undefined;
      limit?: number | undefined;
      organizationId: string;
    }
  ]
>;
type GetPathwayParams = QueryFunctionContext<['v_agents_pathflow', { id: string }]>;
type GetAgentPathwaysParams = QueryFunctionContext<['v_agents_pathways', { agentId: string }]>;
type GetAgentParams = QueryFunctionContext<['v_agents', { id: string }]>;

const getNodeType = (type: string) => {
  const nodeTypes = {
    Default: 'default',
    'Knowledge Base': 'knowledge_base',
    'End Call': 'end_call',
    'Wait For Response': 'wait_for_response',
    'Function Call': 'function_call',
    'Transfer Call': 'transfer_call',
    DTMF: 'dtmf',
    'Transfer Pathway Node': 'transfer_pathway'
  };

  const value = nodeTypes[type as keyof typeof nodeTypes] || nodeTypes['Default'];

  return value;
};
const nanoid = customAlphabet('123456789', 10);

const useAgentsService = () => {
  const { AGENT_PATHWAYS_TABLE, AGENT_TABLE } = KBB_SCHEMA;

  // #region Agent
  const get = useCallback(
    async (params: GetAgentParams) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [filter, queryParams] = params.queryKey;
      const { id } = queryParams;

      const { data, error } = await supabaseClient
        .from(AGENT_TABLE)
        .select('*, v_phone_numbers(*)')
        .eq('id', id)
        .single();

      if (error) console.error(error);

      if (!data) return;

      return data;
    },
    [AGENT_TABLE]
  );

  const getMany = useCallback(
    async (params: GetManyParams) => {
      const [, queryParams] = params.queryKey;
      const { filter, page, limit, status, organizationId } = queryParams;

      if (!organizationId) return;

      let query = supabaseClient
        .from(AGENT_TABLE)
        .select('*, v_phone_numbers(*), v_pathways(id, version_status)', { count: 'exact' })
        .order('agent_name')
        .eq('organization_id', organizationId);

      if (filter) {
        query = query.or(`agent_name.like.%${filter}%,agent_description.like.%${filter}%`);
      }

      console.log('status', status);
      if (status && status !== 'all') {
        query = query.eq('v_pathways.version_status', status);
      }

      if (page) {
        if (page === 0) {
          query = query.range(0, limit ?? 10);
        } else query = query.range((page - 1) * (limit ?? 10), page * (limit ?? 10));
      }

      let { data, count, error } = await query.order('id');

      if (error) console.log(error);

      return { data, count };
    },
    [AGENT_TABLE]
  );

  const put = useCallback(
    async ({ ...agent }: Agent) => {
      try {
        debugger;
        const { data, error } = await supabaseClient
          .from(AGENT_TABLE)
          .update({ ...agent, updated_at: new Date() })
          .eq('id', agent.id)
          .single();

        if (error) console.error(error);

        return data;
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_TABLE]
  );

  const post = useCallback(
    async (item: Agent) => {
      try {
        const { data, error } = await supabaseClient.from(AGENT_TABLE).insert(item).select();

        if (error) console.error(error);

        if (!data) return;

        const nodeId = nanoid();

        const agentPathway = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .insert({
            agent_id: data[0]['id'],
            version_status: 'DRAFT',
            pathway: {
              nodes: [
                {
                  id: parseInt(nodeId),
                  node_name: 'start_call',
                  node_type: 'default'
                }
              ],
              edges: [],
              general_instructions: ''
            },
            pathway_ui: {
              nodes: [
                {
                  id: parseInt(nodeId),
                  position: {
                    x: 500,
                    y: 0
                  }
                }
              ]
            }
          })
          .select();

        if (agentPathway.error) console.log(agentPathway.error);

        return data;
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_TABLE, AGENT_PATHWAYS_TABLE]
  );

  const deleteItem = useCallback(
    async (id: string) => {
      try {
        const pathwayRes = await supabaseClient.from(AGENT_TABLE).delete().eq('id', id);

        if (pathwayRes.error) console.error(pathwayRes.error);

        const { error } = await supabaseClient.from(AGENT_TABLE).delete().eq('id', id);

        if (error) console.error(error);
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_TABLE]
  );

  const duplicateAgent = useCallback(
    async (item: Agent) => {
      try {
        let newAgentPathways: AgentPathway[] = [];

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, created_at, updated_at, v_pathways, ...itemData } = item as any;

        const { data, error } = await supabaseClient
          .from(AGENT_TABLE)
          .insert({ ...itemData })
          .select();

        if (error) console.error(error);

        if (!data) return;

        const agentPathwaysRes = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('agent_id', id)
          .eq('version_status', 'DRAFT');

        if (agentPathwaysRes.error) console.error(agentPathwaysRes.error);
        if (!agentPathwaysRes.data) return;

        if (agentPathwaysRes.data && agentPathwaysRes.data?.length > 0) {
          const newItems = agentPathwaysRes.data.map((a) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...pathway } = a as AgentPathway;

            return { ...pathway, agent_id: data[0]['id'] };
          });

          const newAgentPathwaysRes = await supabaseClient
            .from(AGENT_PATHWAYS_TABLE)
            .insert([...newItems])
            .select();

          newAgentPathways = newAgentPathwaysRes.data as AgentPathway[];
        }

        return {
          agent: data[0],
          agent_pathway: newAgentPathways.find((x) => x.version_status === 'DRAFT') ?? undefined
        };
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_TABLE, AGENT_PATHWAYS_TABLE]
  );

  // #endregion Agent

  // #region Agent Pathway

  const getPathway = useCallback(
    async (params: GetPathwayParams) => {
      const [, queryParams] = params.queryKey;
      const { id } = queryParams;

      const { data, error } = await supabaseClient
        .from(AGENT_PATHWAYS_TABLE)
        .select('*')
        .eq('agent_id', id)
        .eq('version_status', 'DRAFT')
        .select();

      if (error) console.error(error);

      if (!data) return;

      return data[0];
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const getPathwayVersions = useCallback(
    async (params: GetAgentPathwaysParams) => {
      const [, queryParams] = params.queryKey;
      const { agentId } = queryParams;

      const { data, error } = await supabaseClient
        .from(AGENT_PATHWAYS_TABLE)
        .select('*')
        .eq('agent_id', agentId)
        .neq('version_status', 'DRAFT')
        .order('version_ts', { ascending: false });

      if (error) console.error(error);

      if (!data) return;

      return data;
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const addNode = useCallback(
    async ({ pathwayId, node, edge }: { pathwayId: string; node: Node; edge?: Edge }) => {
      try {
        const { data } = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('id', pathwayId)
          .select();

        if (!data) return;

        const updatedPathway = { ...data[0] };

        const newNode = getValuable({
          id: parseInt(node.id),
          function_name: node.data.functionName,
          prompt: node.data.prompt,
          is_global: node.data.isGlobal,
          is_static_text: node.data.isStaticText,
          global_node_pathway_label: node.data.globalNodePathwayLabel,
          global_node_pathway_description: node.data.globalNodePathwayDescription,
          node_name: node.data.name,
          node_type: getNodeType(node.type ?? 'Default'),
          node_enter_condition: node.data.nodeEnterCondition,
          user_data: node.data.userData ?? [],
          transfer_call_number: node.data.transferCallNumber,
          agent_id: node.data.agentId
        });

        // add node
        updatedPathway.pathway.nodes.push(newNode);

        if (edge) {
          // add edge
          updatedPathway.pathway.edges.push({
            id: parseInt(edge.id),
            label: edge.label,
            source: parseInt(edge.source),
            target: parseInt(edge.target)
          });
        }

        const allNodes = [...updatedPathway.pathway_ui.nodes, node];

        // update node positions
        updatedPathway.pathway_ui = {
          nodes: allNodes.map((x) => ({
            id: parseInt(x.id),
            position: x.position
          }))
        };

        const updatedData = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({
            pathway: updatedPathway.pathway,
            pathway_ui: updatedPathway.pathway_ui,
            updated_at: new Date()
          })
          .eq('id', pathwayId)
          .select();

        if (!updatedData.error) return updatedData;
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const addEdge = useCallback(
    async ({ pathwayId, edge }: { pathwayId: string; edge: Edge }) => {
      try {
        const { data } = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('id', pathwayId)
          .select();

        if (!data) return;

        const updatedPathway = { ...data[0] };

        // add edge
        updatedPathway.pathway.edges.push({
          id: parseInt(edge.id),
          label: edge.label,
          source: parseInt(edge.source),
          target: parseInt(edge.target)
        });

        const updatedData = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({
            pathway: updatedPathway.pathway,
            updated_at: new Date()
          })
          .eq('id', pathwayId)
          .select();

        if (!updatedData.error) return updatedData;
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const updateNode = useCallback(
    async ({ pathwayId, node }: { pathwayId: string; node?: Node }) => {
      try {
        const { data } = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('id', pathwayId)
          .select();

        if (!data) return;

        const updatedPathway = { ...data[0] };

        const existingNode = updatedPathway.pathway.nodes.find(
          (x: any) => x.id === parseInt(node?.id ?? '')
        );

        if (node && !existingNode) {
          const newNode = getValuable({
            id: parseInt(node.id),
            function_name: node.data.functionName,
            prompt: node.data.prompt,
            is_global: node.data.isGlobal,
            is_static_text: node.data.isStaticText,
            global_node_pathway_label: node.data.globalNodePathwayLabel,
            global_node_pathway_description: node.data.globalNodePathwayDescription,
            node_name: node.data.name,
            node_type: getNodeType(node.type ?? 'Default'),
            node_enter_condition: node.data.nodeEnterCondition,
            user_data: node.data.userData ?? [],
            transfer_call_number: node.data.transferCallNumber,
            agent_id: node.data.agentId
          });

          updatedPathway.pathway.nodes.push(newNode);
        }

        if (node && existingNode) {
          const nodes = [...updatedPathway.pathway.nodes];
          updatedPathway.pathway.nodes = [
            ...nodes.map((x: any) => {
              if (x.id.toString() === node.id) {
                const updatedNode = getValuable({
                  id: parseInt(node.id),
                  function_name: node.data.functionName,
                  prompt: node.data.prompt,
                  is_global: node.data.isGlobal,
                  is_static_text: node.data.isStaticText,
                  global_node_pathway_label: node.data.globalNodePathwayLabel,
                  global_node_pathway_description: node.data.globalNodePathwayDescription,
                  node_name: node.data.name,
                  node_type: getNodeType(node.type ?? 'Default'),
                  node_enter_condition: node.data.nodeEnterCondition,
                  user_data: node.data.userData ?? [],
                  transfer_call_number: node.data.transferCallNumber,
                  agent_id: node.data.agentId
                });
                return updatedNode;
              } else {
                return x;
              }
            })
          ];
        }

        const updatedData = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({ pathway: updatedPathway.pathway, updated_at: new Date() })
          .eq('id', pathwayId)
          .select();

        if (!updatedData.error) return updatedData;
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const deletePathway = useCallback(
    async (id: string) => {
      try {
        const pathwayRes = await supabaseClient.from(AGENT_PATHWAYS_TABLE).delete().eq('id', id);

        if (pathwayRes.error) console.error(pathwayRes.error);

        const { error } = await supabaseClient.from(AGENT_PATHWAYS_TABLE).delete().eq('id', id);

        if (error) console.error(error);
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const updateEdge = useCallback(
    async ({ pathwayId, edge }: { pathwayId: string; edge?: Edge }) => {
      try {
        const { data } = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('id', pathwayId)
          .select();

        if (!data) return;

        const updatedPathway = { ...data[0] };

        const existingEdge = updatedPathway.pathway.edges.find(
          (x: any) => x.id === parseInt(edge?.id ?? '')
        );

        if (edge && !existingEdge) {
          updatedPathway.pathway.edges.push({
            id: parseInt(edge.id),
            label: edge.label,
            source: parseInt(edge.source),
            target: parseInt(edge.target)
          });
        }

        if (edge && existingEdge) {
          const edges = [...updatedPathway.pathway.edges];
          updatedPathway.pathway.edges = [
            ...edges.map((e: any) => {
              if (e.id.toString() === edge.id) {
                const updatedEdge = getValuable({
                  id: parseInt(edge.id),
                  label: edge.label,
                  source: parseInt(edge.source),
                  target: parseInt(edge.target)
                });
                return updatedEdge;
              } else {
                return e;
              }
            })
          ];
        }

        const updatedData = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({ pathway: updatedPathway.pathway, updated_at: new Date() })
          .eq('id', pathwayId)
          .select();

        if (!updatedData.error) return updatedData;
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const deleteNode = useCallback(
    async ({ pathwayId, nodeId }: { pathwayId: string; nodeId: number }) => {
      try {
        const { data } = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('id', pathwayId)
          .select();

        if (!data) return;

        const updatedPathway = { ...data[0] };
        const nodes = [...updatedPathway.pathway.nodes];
        const edges = [...updatedPathway.pathway.edges];

        updatedPathway.pathway.nodes = nodes.filter((x: any) => x.id !== nodeId);

        updatedPathway.pathway.edges = edges.filter(
          (e) => e.source !== nodeId && e.target !== nodeId
        );

        const updatedData = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({ pathway: updatedPathway.pathway, updated_at: new Date() })
          .eq('id', pathwayId)
          .select();

        if (!updatedData.error) return updatedData;
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const deleteNodesAndEdges = useCallback(
    async ({
      pathwayId,
      nodeIds,
      edgeIds
    }: {
      pathwayId: string;
      nodeIds?: number[];
      edgeIds?: number[];
    }) => {
      try {
        const { data } = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('id', pathwayId)
          .select();

        if (!data) return;

        const updatedPathway = { ...data[0] };

        if (edgeIds) {
          const edges = [...updatedPathway.pathway.edges];

          updatedPathway.pathway.edges = edges.filter(
            (x: any) => edgeIds.find((id) => id === x.id) === undefined
          );
        }

        if (nodeIds) {
          const nodes = [...updatedPathway.pathway.nodes];
          const edges = [...updatedPathway.pathway.edges];

          updatedPathway.pathway.nodes = nodes.filter(
            (x: any) => nodeIds.find((id) => id === x.id) === undefined
          );

          updatedPathway.pathway.edges = edges.filter(
            (e) => !nodeIds.includes(e.source) && !nodeIds.includes(e.target)
          );
        }

        const updatedData = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({ pathway: updatedPathway.pathway, updated_at: new Date() })
          .eq('id', pathwayId)
          .select();

        if (!updatedData.error) return updatedData;
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const updateNodePosition = useCallback(
    async ({ pathwayId, node }: { pathwayId: string; node: Node }) => {
      try {
        const { data } = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*, v_phone_numbers(*)')
          .eq('id', pathwayId)
          .select();

        if (!data) return;

        const updatedPathway = { ...data[0] };
        const existingNode = updatedPathway.pathway_ui?.nodes?.find(
          (x: any) => x.id === parseInt(node?.id ?? '')
        );

        if (existingNode) {
          const nodesUI = [...(updatedPathway.pathway_ui?.nodes ?? [])];

          updatedPathway.pathway_ui.nodes = [
            ...nodesUI.map((existingNode: any) => {
              if (existingNode.id.toString() === node.id) {
                const updatedNode = {
                  id: parseInt(node.id),
                  position: node.position
                };
                return updatedNode;
              } else {
                return existingNode;
              }
            })
          ];
        } else {
          const nodesLength = updatedPathway.pathway_ui?.nodes?.length;

          if (nodesLength === undefined || nodesLength === 0) {
            updatedPathway.pathway_ui = {
              nodes: [
                {
                  id: parseInt(node.id),
                  position: node.position
                }
              ]
            };
          } else {
            updatedPathway.pathway_ui.nodes.push({
              id: parseInt(node.id),
              position: node.position
            });
          }
        }

        const updatedData = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({ pathway_ui: updatedPathway.pathway_ui, updated_at: new Date() })
          .eq('id', pathwayId)
          .select();

        if (updatedData.data && updatedData.data.length > 0) {
          return updatedData;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const updateAllNodesPosition = useCallback(
    async ({ pathwayId, nodes }: { pathwayId: string; nodes: Node[] }) => {
      try {
        const { data } = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('id', pathwayId)
          .select();

        if (!data) return;

        const updatedPathway = { ...data[0] };

        updatedPathway.pathway_ui = {
          nodes: nodes.map((x) => ({ id: parseInt(x.id), position: x.position }))
        };

        const updatedData = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({ pathway_ui: updatedPathway.pathway_ui, updated_at: new Date() })
          .eq('id', pathwayId)
          .single();

        if (updatedData.data) {
          return updatedData;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const updatePathway = useCallback(
    async (pathway: AgentPathway) => {
      try {
        const { data, error } = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({ ...pathway, updated_at: new Date() })
          .eq('id', pathway.id)
          .select();

        if (error) console.error(error);

        if (!data) {
          return;
        }

        return data[0];
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const publishPathwayVersion = useCallback(
    async ({ agentId, pathwayId }: { agentId: string; pathwayId: string }) => {
      try {
        let newAgentPathways: AgentPathway[] = [];

        const versionRes = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('id', pathwayId);

        if (versionRes.error) console.error(versionRes.error);
        if (!versionRes.data) return;

        const updatePublishedVersions = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({ version_status: 'ARCHIVED', updated_at: new Date() })
          .eq('agent_id', agentId)
          .eq('version_status', 'PUBLISHED')
          .select();

        if (updatePublishedVersions.error) {
          console.log(updatePublishedVersions.error);
          return;
        }

        if (versionRes.data && versionRes.data?.length > 0) {
          const newItems = versionRes.data.map((a) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...pathway } = a as AgentPathway;

            return {
              ...pathway,
              agent_id: agentId,
              version_status: 'PUBLISHED',
              version_ts: new Date()
            };
          });

          const newAgentPathwaysRes = await supabaseClient
            .from(AGENT_PATHWAYS_TABLE)
            .insert([...newItems])
            .select();

          newAgentPathways = newAgentPathwaysRes.data as AgentPathway[];
        }

        return {
          agent: versionRes.data,
          agent_pathways: newAgentPathways
        };
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  const overrideDraftWithVersion = useCallback(
    async ({ agentId, pathwayId }: { agentId: string; pathwayId: string }) => {
      try {
        const versionRes = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .select('*')
          .eq('id', pathwayId);

        if (versionRes.error) console.error(versionRes.error);
        if (!versionRes.data) return;

        const updateDraftVersion = await supabaseClient
          .from(AGENT_PATHWAYS_TABLE)
          .update({
            updated_at: new Date(),
            version_ts: new Date(),
            pathway: versionRes.data[0]['pathway'],
            pathway_ui: versionRes.data[0]['pathway_ui']
          })
          .eq('agent_id', agentId)
          .eq('version_status', 'DRAFT')
          .select();

        if (updateDraftVersion.error) {
          console.log(updateDraftVersion.error);
          return;
        }

        return versionRes.data;
      } catch (error) {
        console.error(error);
      }
    },
    [AGENT_PATHWAYS_TABLE]
  );

  // #endregion Agent Pathway

  return {
    get,
    getMany,
    deleteItem,
    post,
    put,
    duplicateAgent,
    getPathway,
    getPathwayVersions,
    publishPathwayVersion,
    addEdge,
    addNode,
    updateEdge,
    updateNode,
    deleteNode,
    updateNodePosition,
    updateAllNodesPosition,
    updatePathway,
    deleteNodesAndEdges,
    deletePathway,
    overrideDraftWithVersion
  };
};

export default useAgentsService;
