import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import classes from './CleanTemplate.styles';

function CleanTemplate(): React.ReactElement {
  return (
    <Box className={classes.mainContainer}>
      <Outlet />
    </Box>
  );
}

export default CleanTemplate;
