import { Badge } from '@mui/material';
import { baseColors } from 'theme/palette';
import { Status } from 'types';

const StatusBadge = ({ status }: { status: Status }) => {
  const chipStyles = {
    Published: {
      backgroundColor: baseColors.green,
      color: baseColors.white
    },
    'Review needed': {
      backgroundColor: baseColors.pink,
      color: baseColors.black
    },
    Archived: {
      backgroundColor: baseColors.red2,
      color: baseColors.black
    },
    Draft: {
      backgroundColor: baseColors.yellow3,
      color: baseColors.black
    }
  };

  const styles = chipStyles[status];

  return <Badge variant="dot" className="w-3 h-3 rounded-lg" style={{ ...styles }}></Badge>;
};

export default StatusBadge;
