import { Box } from '@mui/material';
import { LeftNavigation } from 'components/organisms';
import React from 'react';
import { Outlet } from 'react-router-dom';
import classes from './BaseTemplate.styles';

interface Props {
  gaps?: boolean;
  horizontalGlutter?: boolean;
}

function BaseTemplate({ gaps = true, horizontalGlutter = true }: Props): React.ReactElement {
  return (
    <Box>
      <LeftNavigation />
      <Box component="main" className={classes(false, gaps, horizontalGlutter).mainContainer}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default BaseTemplate;
