import { useUser } from 'hooks/reducers';
import { useCallback } from 'react';
import { RelatedArticle, SimilarArticle } from 'types/models';
import { APIService } from 'types/services';
import { BaseAdapter } from '../adapters';

type RelatedArticleService = Omit<APIService<RelatedArticle>, 'getMany' | 'post' | 'put'>;

type GetRelatedArticlesParams = {
  faqId: string;
  question: string;
  answer: string;
  categoryName: string;
};

type RelatedArticleResponse = {
  faq_id: string;
  similar_articles: SimilarArticle[];
};

const useRelatedArticlesService = (): RelatedArticleService => {
  const user = useUser();
  const { adapt } = BaseAdapter<RelatedArticleResponse, RelatedArticle>();

  const get = useCallback(
    async ({ faqId, question, answer, categoryName }: GetRelatedArticlesParams) => {
      const response = await fetch(
        `${process.env.REACT_APP_LLM_SERVICE_URL}/assistant/get_similar_articles`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-User-Id': user.user?.id ?? '',
            'X-Organization': user?.user?.user_metadata?.organizations.id ?? '',
            'X-Api-Key': user?.user?.user_metadata?.organizations.apiKey ?? ''
          },
          body: JSON.stringify({
            faq_id: faqId,
            question: question,
            answer: answer,
            category_name: categoryName
          })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to generate content');
      }

      const data = await response.json();

      return adapt(data);
    },
    [adapt, user]
  );

  return { get };
};

export default useRelatedArticlesService;
