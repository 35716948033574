import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface LoaderProps {
  isLoading: boolean;
  message?: string;
}

const Loader: React.FC<LoaderProps> = ({ isLoading, message }) => {
  if (!isLoading) return null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bgcolor="rgba(255, 255, 255, 0.8)"
    >
      <CircularProgress />
      {message && <Box mt={2}>{message}</Box>}
    </Box>
  );
};

export default Loader;
