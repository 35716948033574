import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Check } from '@mui/icons-material';
import useSourcesService from 'hooks/services/sources-service/sources.service';
import { useToast } from 'contexts/ToastContext';
import { useUser } from 'hooks/reducers';

const JiraForm = ({ handleClose }: { handleClose: () => void }) => {
  const [token, setToken] = useState({
    connectionId: '',
    providerConfigKey: ''
  });
  const [error, setError] = useState(false);
  const [projects, setProjects] = useState<string[]>(['']);

  const { showToast } = useToast();

  const { authWithSSO: authWithJira, submitSource } = useSourcesService();

  const { user } = useUser();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (token.connectionId === '') {
        setError(true);
        return;
      }
      const projectsIds = projects.filter((project) => project.trim() !== '');
      const metadata = {
        projects: [projectsIds]
      };
      submitSource(metadata, token.connectionId, token.providerConfigKey);
      setToken((prevState) => ({
        ...prevState,
        providerConfigKey: '',
        connectionId: ''
      }));
      showToast('Source successfully added', 'success');
      handleClose();
    } catch (error) {
      showToast('Error adding source', 'error');
    }
  };

  const handleAddProject = () => {
    setProjects((prevProjects) => [...prevProjects, '']);
  };

  const handleProjectChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const { value } = event.target;
    setProjects((prevProjects) => {
      const updatedProjects = [...prevProjects];
      updatedProjects[index] = value;
      return updatedProjects;
    });
  };

  return (
    <div>
      <h3 className="text-base font-bold">Jira</h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8">
          <Button
            variant="contained"
            className="bg-white text-black gap-4"
            onClick={() =>
              authWithJira('jira', `${user?.user_metadata.organizationId!}`).then((response) => {
                if (response) setToken(response!);
              })
            }
            disabled={token?.connectionId !== '' ? true : false}
          >
            {token.connectionId === '' ? (
              <>
                <img src={'/jira192.png'} alt="Jira" className="w-4 h-4" />
                Connect Jira
              </>
            ) : (
              <>
                <img src={'/jira192.png'} alt="Jira" className="w-4 h-4" />
                Connected
                <Check className="text-sm text-orange" />
              </>
            )}
          </Button>
          <div>
            {projects.map((project, index) => (
              <div key={index} className="flex items-center mb-4">
                <TextField
                  id={`driveUrl-${index}`}
                  label="Project ID"
                  variant="outlined"
                  required={index === 0}
                  value={project}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleProjectChange(event, index)
                  }
                  className="w-full"
                />
              </div>
            ))}
            <span className="text-xs text-gray cursor-pointer" onClick={handleAddProject}>
              Add new Project ID
            </span>
          </div>

          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          {error && <p className="text-orange text-sm m-0">Please connect Jira first</p>}
        </div>
      </form>
      <div className="pt-8">
        <h3 className="text-base text-orange font-bold">Instructions</h3>
        <ol>
          <li>Authenticate your Jira account clicking on the button "Connect Jira".</li>
          <li>Enter the Project ID you want to add.</li>
          <li>Click the "Submit" button to add Jira as a source.</li>
        </ol>
      </div>
    </div>
  );
};

export default JiraForm;
