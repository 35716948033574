import { ListItemButton, ListItemButtonProps, ListItemIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { baseColors } from 'theme/palette';

interface ListItemStyleProps extends ListItemButtonProps {
  to?: string;
}

export const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 40,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(2),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

export const ListItemIconStyle = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'isActiveRoot'
})(({ isActiveRoot }: { isActiveRoot: boolean }) => ({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: isActiveRoot ? baseColors.orange : undefined
}));
