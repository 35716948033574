import { Theme } from '@mui/material/styles';
import { FONT_MAIN } from 'theme/typography';

// ----------------------------------------------------------------------

export default function Chip(theme: Theme): object {
  return {
    MuiChip: {
      defaultProps: {},

      styleOverrides: {
        root: {
          borderRadius: '.5rem!important',
          borderWidth: '1px',
          fontFamily: FONT_MAIN,
          fontWeight: 500,
          fontSize: '0.8125rem',
          lineHeight: '18px'
        },
        sizeSmall: {
          fontSize: '.75rem',
          fontWeight: 800,
          padding: '.25rem .5rem',
          '& > span': {
            padding: 0
          }
        },
        colorDefault: {
          '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
            color: theme.palette.text.secondary
          }
        },
        colorPrimary: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.common.black,
          '&:hover': {
            backgroundColor: theme.palette.common.black
          }
        },
        colorSecondary: {
          color: theme.palette.common.black,
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.grey[300]
          }
        },
        outlined: {
          borderColor: '#919EAB52'
        }
      }
    }
  };
}
