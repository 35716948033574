import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  Stack,
  ListItemText,
  Collapse
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useCallback, useState } from 'react';
import CollapsibleListProps from './CollapsibleList.props';

const CollapsibleList = ({ items }: CollapsibleListProps) => {
  const [collapsedItems, setCollapsedItems] = useState<number[]>([]);

  const handleCollapse = useCallback((index: number) => {
    setCollapsedItems((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  }, []);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} disabled>
        <Typography>Similar FAQs</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {items.map((item, index) => (
            <ListItem key={index} className="w-full">
              <Stack className="flex-col w-full">
                <Stack className="flex-row w-full">
                  <span className="bg-pink2 rounded-sm w-8 h-8 text-md text-orange font-bold flex items-center justify-center mr-4">
                    {index}
                  </span>
                  <ListItemText
                    onClick={() => handleCollapse(index)}
                    className="flex justify-between w-full"
                  >
                    <Typography className="font-bold cursor-pointer text-base">
                      {item.title}
                    </Typography>
                  </ListItemText>
                  {collapsedItems?.includes(index) ? (
                    <ExpandLessIcon
                      className="cursor-pointer"
                      onClick={() => handleCollapse(index)}
                    />
                  ) : (
                    <ExpandMoreIcon
                      className="cursor-pointer"
                      onClick={() => handleCollapse(index)}
                    />
                  )}
                </Stack>
                <Collapse in={collapsedItems.includes(index)} className="ml-12">
                  <Typography className="font-inter text-md text-gray">
                    {item.description}
                  </Typography>
                </Collapse>
              </Stack>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default CollapsibleList;
