import Nango from '@nangohq/frontend';
import { useCallback, useState } from 'react';
import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useToast } from 'contexts/ToastContext';

const useSourcesService = () => {
  const nango = new Nango({
    publicKey: process.env.REACT_APP_NANGO_PUBLIC_KEY!
  });

  const [isLoading, setIsLoading] = useState(false);

  const { showToast } = useToast();

  const { SOURCES_TABLE } = KBB_SCHEMA;

  const checkSlackConnection = useCallback(
    async (organizationId: string) => {
      const { data: slackConnection } = await supabaseClient
        .from(SOURCES_TABLE)
        .select()
        .eq('organization_id', organizationId)
        .eq('source_type', 'slack')
        .single();

      const isSlackConnected = Boolean(slackConnection);

      return isSlackConnected;
    },
    [SOURCES_TABLE]
  );

  const fetchData = useCallback(async (organizationId: string) => {
    setIsLoading(true);
    const { data } = await supabaseClient.functions.invoke('get-sources', {
      body: {
        organizationId
      }
    });
    if (!data) {
      return [];
    }
    setIsLoading(false);
    return data;
  }, []);

  const authWithSSO = async (source: string, organizationId: string) => {
    try {
      const result = await nango.auth(source, organizationId, {});
      if (!result) throw new Error('Error authenticating with source');

      return result;
    } catch (err) {
      console.error(err);
      showToast('Error authenticating with source', 'error');
      return null;
    }
  };

  const submitSource = async (
    metadata: Record<string, string[][]> | undefined,
    connectionId: string,
    providerConfigKey: string
  ) => {
    try {
      if (metadata) {
        await supabaseClient.functions.invoke('add-nango-metadata', {
          body: {
            metadata,
            organizationId: connectionId,
            source: providerConfigKey
          }
        });
      }

      const matchRow = await supabaseClient
        .from(SOURCES_TABLE)
        .select()
        .eq('source_type', providerConfigKey)
        .eq('organization_id', connectionId)
        .single();

      if (!matchRow.data) {
        await supabaseClient.from(SOURCES_TABLE).insert([
          {
            source_type: providerConfigKey,
            organization_id: connectionId
          }
        ]);
      }
    } catch (error) {
      showToast('Error registering source', 'error');
    }
  };

  const deleteConnection = async (connectionId: string, providerConfigKey: string) => {
    try {
      if (providerConfigKey === 'pdf') {
        const { data: paths } = await supabaseClient
          .from('sources')
          .select('details')
          .eq('organization_id', connectionId)
          .eq('source_type', 'pdf');

        let filesDetails: string[] = [];
        paths?.[0]?.details.forEach((path: string) => {
          filesDetails.push(`${connectionId}/${path}`);
        });
        await supabaseClient.storage.from('sources').remove(filesDetails);
      }

      await supabaseClient
        .from(SOURCES_TABLE)
        .delete()
        .eq('organization_id', connectionId)
        .eq('source_type', providerConfigKey)
        .select();

      if (providerConfigKey !== 'pdf') {
        await supabaseClient.functions.invoke('delete-nango-connection', {
          body: {
            organizationId: connectionId,
            source: providerConfigKey
          }
        });
      }
    } catch (error) {
      showToast('Error deleting connection', 'error');
    }
  };

  const triggerFlow = async (deploymentId: string) => {
    showToast('Triggering flow...', 'info');
    try {
      const response = await supabaseClient.functions.invoke('trigger-prefect-flow', {
        body: {
          deploymentId
        }
      });
      if (response.error) throw new Error(response.error);
      showToast('Flow triggered successfully', 'success');
    } catch (error) {
      showToast('Error triggering flow', 'error');
    }
  };

  const postPDF = async (formData: FormData, organizationId: string) => {
    const files = formData.getAll('file') as File[];
    try {
      if (!files) throw new Error('No file to upload');

      const { data: sources } = await supabaseClient
        .from(SOURCES_TABLE)
        .select()
        .eq('organization_id', organizationId)
        .eq('source_type', 'pdf')
        .single();

      if (!sources) {
        await supabaseClient.from(SOURCES_TABLE).insert([
          {
            source_type: 'pdf',
            organization_id: organizationId,
            details: []
          }
        ]);
      }

      let filesDetails: string[] = [];

      await Promise.all(
        files.map(async (file: File) => {
          //This regex is used to remove any non-ascii characters from the filename before uploading it to the storage (https://github.com/orgs/supabase/discussions/2480)
          // eslint-disable-next-line no-control-regex
          const filename = file.name.replace(/[^\x00-\x7F]/g, '');
          const { error } = await supabaseClient.storage
            .from('sources')
            .upload(`${organizationId}/${filename}`, file, {
              cacheControl: '3600'
            });
          if (error) throw new Error(error.message);

          filesDetails.push(filename);

          if (files) showToast(`${file.name} uploaded successfully`, 'success');
        })
      );

      const { data: pdfs } = await supabaseClient
        .from(SOURCES_TABLE)
        .select('details')
        .eq('organization_id', organizationId)
        .eq('source_type', 'pdf')
        .single();

      const details = [...pdfs?.details, ...filesDetails];

      await supabaseClient
        .from(SOURCES_TABLE)
        .update({ details })
        .eq('organization_id', organizationId)
        .eq('source_type', 'pdf')
        .single();
    } catch (error) {
      showToast(`${error}`, 'error');
    }
  };

  return {
    authWithSSO,
    submitSource,
    deleteConnection,
    fetchData,
    triggerFlow,
    postPDF,
    isLoading,
    checkSlackConnection
  };
};

export default useSourcesService;
