import { Delete, FilterList } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import {
  Badge,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Stack,
  Typography
} from '@mui/material';
import clsx from 'clsx';
import { EditableCell, SearchField } from 'components/molecules';
import { AgentModal, SimpleTable } from 'components/organisms';
import { InnerPageTemplate } from 'components/templates';
import { useToast } from 'contexts/ToastContext';
import { format } from 'date-fns';
import { useAgentsService, useCallLogService, useConfirmationDialog, useDebounce } from 'hooks';
import { useUser } from 'hooks/reducers';
import { MouseEvent as ReactMouseEvent, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useSearchParams } from 'react-router-dom';
import { TableColumn } from 'types';
import { Database } from 'types/models/db.type';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

type Status = 'PUBLISHED' | 'DRAFT' | 'all' | undefined;
interface Filter {
  filter: string;
  page: number;
  limit: number;
  status: Status;
  organizationId: string;
}

type Agent = Database['public']['Tables']['v_agents']['Row'];

const AgentsPage = () => {
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { openDialog } = useConfirmationDialog();
  const { debounce } = useDebounce();
  const { getMany, deleteItem, put, duplicateAgent } = useAgentsService();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showToast } = useToast();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({
    filter: searchParams.get('filter') ?? '',
    page: parseInt(searchParams.get('page') ?? '1'),
    limit: parseInt(searchParams.get('limit') ?? '10'),
    status: (searchParams.get('status') ?? 'all') as Status,
    organizationId: user?.user_metadata.organizationId
  });
  const [searchText, setSearchText] = useState<string>(filter.filter);

  const { data: agents, refetch } = useQuery({
    queryKey: ['agents_query', filter],
    queryFn: getMany
  });

  const editMutation = useMutation({
    mutationKey: 'edit_agent',
    mutationFn: (agent: Agent) => put(agent),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agents_query'] });
      refetch();
    }
  });

  const deleteMutation = useMutation({
    mutationKey: 'deleteAgent',
    mutationFn: (id: string) => deleteItem(id),
    onSuccess: (data: void | Agent[] | undefined) => {
      queryClient.invalidateQueries({ queryKey: ['agents_query'] });
      refetch();
    }
  });

  const duplicateMutation = useMutation({
    mutationKey: 'duplicateAgent',
    mutationFn: (agent: Agent) => duplicateAgent(agent),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agents_query'] });
      refetch();
    }
  });

  const handleDeleteClick = useCallback(
    (id: string) => {
      openDialog('Delete Agent', 'Are you sure to delete this agent?', () =>
        deleteMutation.mutate(id)
      );
    },
    [deleteMutation, openDialog]
  );

  const handleDuplicate = async (agentId: string) => {
    const agent = agents?.data?.find((a) => a.id === agentId);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { v_phone_numbers, ...newAgent } = agent;

    duplicateMutation.mutate({
      ...newAgent,
      agent_name: `${agent.agent_name} (copy)`,
      phone_number_id: v_phone_numbers?.id ?? null
    });
  };

  const extractAgentSettings = useCallback((agent: Agent) => {
    let settingParams = '';
    console.log('agent?.setting', agent?.settings);
    console.log('agent?', agent);
    if (!agent?.settings) {
      return '';
    }

    const { is_hipaa, language, enable_recording } = agent?.settings as any;

    if (is_hipaa !== undefined) {
      settingParams += `&is_hipaa=${is_hipaa}`;
    }

    if (language !== undefined) {
      settingParams += `&language=${language}`;
    }

    if (enable_recording !== undefined) {
      settingParams += `&enable_recording=${enable_recording}`;
    }

    return settingParams;
  }, []);

  const cols: TableColumn<any>[] = [
    {
      id: 'agent_name',
      label: 'name',
      cellProps: {
        width: '30%'
      },
      renderer: (item: any) => {
        const draft = item.v_pathways?.find((p: any) => p.version_status === 'DRAFT');
        const versionIdParam = draft ? `&versionId=${draft.id}` : '';
        const agentUrl = `/pathway?id=${item.id}${versionIdParam}`;

        return (
          <EditableCell
            item={item}
            component="link"
            componentValue={agentUrl}
            field="agent_name"
            value={item['agent_name']}
            onSubmit={(field, value, item) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { v_phone_numbers, v_pathways, ...agent } = item;
              const curatedValue = value.replace(/ /g, '');
              editMutation.mutate({ ...agent, [field]: curatedValue });
            }}
          />
        );
      }
    },
    {
      id: 'agent_description',
      label: 'description',
      cellProps: {
        width: '40%'
      },
      renderer: (item: any) => {
        return (
          <EditableCell
            item={item}
            field="agent_description"
            value={item['agent_description']}
            onSubmit={(field, value, item) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { v_phone_numbers, v_pathways, ...agent } = item;
              editMutation.mutate({ ...agent, [field]: value });
            }}
          />
        );
      }
    },
    {
      id: 'updated_at',
      cellProps: {
        width: '20%'
      },
      label: 'updated at',
      renderer: (item: any) => {
        return (
          <p>{item.updated_at ? format(new Date(item.updated_at), 'yyyy-MM-dd HH:mm') : ''}</p>
        );
      }
    },
    {
      id: 'status',
      cellProps: { className: 'min-w-[10rem]' },
      label: 'status',
      renderer: (item: any) => {
        const status = item.v_pathways?.find((p: any) => p.version_status === 'PUBLISHED')
          ? 'PUBLISHED'
          : 'DRAFT';

        return (
          <Stack direction="row" className="flex gap-2 items-center">
            <Chip
              className={clsx(
                'font-semibold capitalize',
                (status ?? '').toLowerCase() === 'published'
                  ? 'bg-green4 text-green5'
                  : 'bg-gray11 text-black'
              )}
              label={status.toLocaleLowerCase()}
            />
          </Stack>
        );
      }
    },
    {
      id: 'actions',
      label: 'Actions',
      cellProps: { className: 'min-w-[8rem]' },
      renderer: (item: any) => {
        const itemStatus = item.v_pathways?.find((p: any) => p.version_status === 'PUBLISHED')
          ? 'PUBLISHED'
          : 'DRAFT';

        return (
          <Box className="w-full flex justify-end">
            {itemStatus === 'PUBLISHED' && (
              <IconButton
                onClick={(e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
                  const url = `${process.env.REACT_APP_VOICE_SERVICE_URL}/start?agent_id=${
                    item.id
                  }${extractAgentSettings(item)}`;
                  navigator.clipboard.writeText(url);
                  showToast('Agent version URL copied to your clipboard', 'success');

                  e.stopPropagation();
                }}
              >
                <LinkIcon />
              </IconButton>
            )}
            <IconButton onClick={() => handleDuplicate(item.id)}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(item.id)}>
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        );
      }
    }
  ];

  const updateSearchParamsAndFilter = useCallback(
    (field: keyof Filter, value: string) => {
      setSearchParams((params) => {
        params.set(field, value);
        return params;
      });

      setFilter((prevState) => ({
        ...prevState,
        [field]: value
      }));
    },
    [setSearchParams]
  );

  const setSearchParamDebounced = useMemo(
    () =>
      debounce((field: keyof Filter, value: string) => {
        updateSearchParamsAndFilter(field, value);
      }, 300),
    [debounce, updateSearchParamsAndFilter]
  );

  const handleFilterChange = useCallback(
    (field: keyof Filter, value: any, effect: 'immediate' | 'debounced' = 'immediate') => {
      if (effect === 'immediate') {
        updateSearchParamsAndFilter(field, value);
      } else {
        setSearchParamDebounced(field, value);
      }
    },
    [setSearchParamDebounced, updateSearchParamsAndFilter]
  );

  const handleAddClick = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <InnerPageTemplate
      header="Agents"
      noPadding
      headerRightComponent={
        <Stack direction="row" className="w-full mr-8 gap-4 flex items-center">
          <SearchField
            id="search-filter"
            className="bg-white w-full"
            bordered
            toggle
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              handleFilterChange('filter', e.target.value, 'debounced');
            }}
            onClear={() => {
              setSearchText('');
              handleFilterChange('filter', '', 'immediate');
            }}
          />

          <AgentsFilterOption
            handleFilterChange={(field: keyof Filter, value: string | string[]) => {
              handleFilterChange('page', 1);
              handleFilterChange(field, value, 'immediate');
            }}
            filter={filter}
          />

          <Button
            className="min-w-[123px]"
            variant="contained"
            color="primary"
            size="small"
            onClick={handleAddClick}
          >
            <AddCircleOutlineIcon fontSize="small" className="mr-2" />
            Add Agent
          </Button>
        </Stack>
      }
    >
      {agents?.data?.length === 0 && searchText === '' && (
        <Box className="w-full text-center">
          <Typography variant="body1">
            No agents available yet, please create at least one
          </Typography>
        </Box>
      )}

      {agents?.data?.length && agents?.data?.length > 0 && (
        <SimpleTable
          columns={cols}
          rows={agents?.data || []}
          tableContainerProps={{ sx: { maxHeight: '80vh', padding: '1rem' } }}
          tableHeaderCellProps={{
            sx: {
              position: 'sticky',
              top: -18,
              backgroundColor: 'white',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              zIndex: 1
            }
          }}
          tableRowProps={{
            sx: {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }
          }}
          tableBodyCellProps={{
            sx: {
              display: '',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: '20px',
              maxWidth: '40px',
              padding: '0 1rem',
              cursor: 'default'
            }
          }}
        />
      )}
      <Grid container>
        <Grid item xs={12} className="mt-4 pr-2 pl-6 flex justify-end">
          <Pagination
            page={filter.page}
            count={Math.ceil((agents?.count ?? 0) / filter.limit)}
            onChange={(e, value) => {
              setSearchParams((params) => {
                params.set('page', value.toString());
                return params;
              });
              setFilter((prevValue) => ({ ...prevValue, page: value }));
            }}
            color="primary"
            className="mt-2"
            size="small"
          />
        </Grid>
      </Grid>
      <AgentModal
        isOpen={isOpen}
        onModalClose={() => {
          setIsOpen(false);
          refetch();
        }}
        agentId=""
      />
    </InnerPageTemplate>
  );
};

type CallLogsFilterOptionsProps = {
  handleFilterChange: (field: keyof Filter, value: string | string[]) => void;
  filter: Filter;
};

const AgentsFilterOption = ({ handleFilterChange, filter }: CallLogsFilterOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const location = useLocation();
  const { user } = useUser();

  const searchParams = new URLSearchParams(location.search);
  const ignoredParams = ['filter', 'page'];
  const filteredParams = Array.from(searchParams.keys()).filter(
    (key) => !ignoredParams.includes(key)
  );
  const numFilters = filteredParams.length;

  return (
    <>
      <div className="flex items-center">
        <Badge
          badgeContent={numFilters}
          color="primary"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <FilterList
            className="h-6 w-6 hover:text-orange cursor-pointer"
            onClick={(e) => setAnchorEl(e?.currentTarget)}
          />
        </Badge>
      </div>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={() => setAnchorEl(null)}
      >
        <div className="flex flex-col p-4 min-w-[20rem]">
          <h3 className="text-xs pb-2">Filter by</h3>
          <div className="flex gap-4 flex-col">
            <FormControl className="w-full">
              <InputLabel id="status-filter">Status</InputLabel>
              <Select
                labelId="status-filter"
                label="Status"
                className="w-full"
                onChange={(e) => handleFilterChange('status', e.target.value as string)}
                value={filter?.status}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                <MenuItem key={'DRAFT'} value={'DRAFT'}>
                  Draft
                </MenuItem>

                <MenuItem key={'PUBLISHED'} value={'PUBLISHED'}>
                  Published
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default AgentsPage;
