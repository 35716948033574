import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Node } from 'reactflow';
import { NodeDialogProps } from './NodeDialog.props';

const NodeDialog = (props: NodeDialogProps) => {
  const { onSubmit, onClose, nodeProps, open } = props;

  const { control, handleSubmit, watch } = useForm<Node>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { ...nodeProps }
  });

  const { fields, append, remove } = useFieldArray({
    name: 'data.userData',
    control
  });

  const watchStaticTextEnabled = watch('data.isStaticText');
  const watchNodeType = watch('type');

  const watchUserData = watch('data.userData', nodeProps.data.userData);

  const handleClose = useCallback(() => {
    const validVariables = watchUserData.filter(
      (x: any) => x.name !== '' && x.data_type !== '' && x.description !== ''
    );

    if (validVariables?.length === watchUserData?.length) {
      handleSubmit(onSubmit)();
      onClose();
    }
  }, [handleSubmit, onClose, onSubmit, watchUserData]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log('value', value);
      console.log('name', name);
      console.log('type', type);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Edit Node</DialogTitle>
      <DialogContent>
        <Box className="w-full mt-4">
          <form className="flex flex-col gap-4">
            <Grid container className="gap-4 flex flex-col">
              <Grid item xs={12} className="gap-4 flex flex-row">
                <Grid item xs={6} className="flex flex-1">
                  <Controller
                    control={control}
                    name="data.name"
                    rules={{ required: true }}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <TextField
                        className="flex-1"
                        name={name}
                        value={value}
                        onChange={onChange}
                        label="Node Name"
                        ref={ref}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6} className="flex flex-1">
                  <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="type-select-label">Type</InputLabel>
                        <Select
                          {...field}
                          labelId="type-select-label"
                          label="Type"
                          value={field.value}
                          onChange={field.onChange}
                          fullWidth
                        >
                          <MenuItem value="Default">Default</MenuItem>
                          <MenuItem value="DTMF">DTMF</MenuItem>
                          <MenuItem value="End Call">End Call</MenuItem>
                          <MenuItem value="Function Call">Function call</MenuItem>
                          <MenuItem value="Transfer Call">Transfer call</MenuItem>
                          <MenuItem value="Transfer Pathway Node">Transfer Pathway Node</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              {watchNodeType === 'Function Call' && (
                <Grid item xs={12} className="flex flex-1">
                  <Controller
                    control={control}
                    name="data.functionName"
                    rules={{ required: true }}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <TextField
                        className="flex-1"
                        name={name}
                        value={value}
                        onChange={onChange}
                        label="Function Name"
                        ref={ref}
                      />
                    )}
                  />
                </Grid>
              )}

              {watchNodeType === 'Transfer Call' && (
                <Grid item xs={12} className="flex flex-1">
                  <Controller
                    control={control}
                    name="data.transferCallNumber"
                    rules={{ required: true }}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <TextField
                        className="flex-1"
                        name={name}
                        value={value}
                        onChange={onChange}
                        label="Transfer Call Number"
                        ref={ref}
                      />
                    )}
                  />
                </Grid>
              )}

              {watchNodeType === 'Transfer Pathway Node' && (
                <Grid item xs={12} className="flex flex-1">
                  <Controller
                    control={control}
                    name="data.agentId"
                    rules={{ required: true }}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <TextField
                        className="flex-1"
                        name={name}
                        value={value}
                        onChange={onChange}
                        label="Agent ID"
                        ref={ref}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} className="flex flex-1">
                {!nodeProps.data.isStart && (
                  <Controller
                    control={control}
                    name="data.nodeEnterCondition"
                    render={({ field: { name, value, onChange, ref } }) => (
                      <TextField
                        className="flex-1"
                        multiline
                        rows={3}
                        name={name}
                        value={value}
                        onChange={onChange}
                        label={'Node enter condition'}
                        ref={ref}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} className="gap-4 flex flex-row">
                <Grid item xs={3} className="flex flex-1">
                  <Controller
                    control={control}
                    name="data.isGlobal"
                    render={({ field }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              defaultChecked={nodeProps.data.isGlobal}
                              onChange={field.onChange}
                            />
                          }
                          label="Global node"
                        />
                      </FormGroup>
                    )}
                  />
                </Grid>

                {watchNodeType !== 'DTMF' && (
                  <Grid item xs={3} className="flex flex-1">
                    <Controller
                      control={control}
                      name="data.isStaticText"
                      render={({ field }) => (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={nodeProps.data.isStaticText}
                                onChange={field.onChange}
                              />
                            }
                            label="Static Text"
                          />
                        </FormGroup>
                      )}
                    />
                  </Grid>
                )}
              </Grid>

              {watchNodeType !== 'DTMF' && (
                <Grid item xs={12} className="flex flex-1">
                  <Controller
                    control={control}
                    name={'data.prompt'}
                    render={({ field: { name, value, onChange, ref } }) => (
                      <TextField
                        className="flex-1"
                        multiline
                        name={name}
                        value={value}
                        onChange={onChange}
                        label={watchStaticTextEnabled ? 'Static Text' : 'Prompt'}
                        ref={ref}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} className="flex flex-1">
                <Box className="w-full">
                  <Stack direction="row" className="flex justify-end">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => append({ name: '', data_type: 'string', description: '' })}
                    >
                      Add variable
                    </Button>
                  </Stack>
                  {fields.map((field, idx) => {
                    return (
                      <Stack direction="row" className="flex flex-col gap-2 mt-4" key={field.id}>
                        <Stack direction="row" className="flex flex-row gap-2">
                          <Stack direction="column" className="flex-col flex-1">
                            <Controller
                              control={control}
                              rules={{ required: { value: true, message: 'Name is required' } }}
                              name={`data.userData.${idx}.name`}
                              render={({ field: { value, onChange, ref } }) => (
                                <TextField
                                  fullWidth
                                  value={value}
                                  onChange={onChange}
                                  label={'Name'}
                                  ref={ref}
                                />
                              )}
                            />
                            {watchUserData[idx]?.name === '' && (
                              <span className="text-red2">name is required</span>
                            )}
                          </Stack>
                          <Stack direction="column" className="flex-col flex-1">
                            <Controller
                              control={control}
                              rules={{
                                required: { value: true, message: 'Data type is required' }
                              }}
                              name={`data.userData.${idx}.data_type`}
                              render={({ field }) => (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="data-type-select-label">Type</InputLabel>
                                  <Select
                                    labelId="data-type-select-label"
                                    label="Type"
                                    value={field.value}
                                    onChange={field.onChange}
                                    className="flex-col flex-[0.5]"
                                    inputProps={{ className: '!pr-0' }}
                                  >
                                    <MenuItem value="boolean">Boolean</MenuItem>
                                    <MenuItem value="float">Float</MenuItem>
                                    <MenuItem value="string">String</MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                            />
                            {watchUserData[idx]?.data_type === '' && (
                              <span className="text-red2">data type is required</span>
                            )}
                          </Stack>

                          <Stack direction="column" className="flex-col flex-1">
                            <Controller
                              control={control}
                              rules={{
                                required: { value: true, message: 'Description is required' }
                              }}
                              name={`data.userData.${idx}.description`}
                              render={({ field: { value, onChange, ref } }) => (
                                <TextField
                                  className="flex-col flex-1"
                                  value={value}
                                  onChange={onChange}
                                  label={'Description'}
                                  ref={ref}
                                />
                              )}
                            />
                            {watchUserData[idx]?.description === '' && (
                              <span className="text-red2">description is required</span>
                            )}
                          </Stack>
                          <IconButton
                            onClick={() => {
                              remove(idx);
                            }}
                            className="flex-col flex-[.13]"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NodeDialog;
