import { Box, FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { StatusBadge } from 'components/atoms';
import { useFAQStatusService } from 'hooks';
import { forwardRef, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Status } from 'types';
import { FAQStatus } from 'types/models';

type StatusSelectProps = SelectProps & {
  allowEmptyValue: boolean;
  showLabel?: boolean;
};

const StatusSelect = forwardRef<HTMLSelectElement, StatusSelectProps>(
  ({ allowEmptyValue, showLabel = false, ...rest }: StatusSelectProps) => {
    const { getMany: getStatuses } = useFAQStatusService();
    const [statuses, setStatuses] = useState<FAQStatus[]>([]);

    const query = useQuery('statuses', getStatuses);

    useEffect(() => {
      if (!query.isLoading && query.data?.length) {
        setStatuses(query.data);
      }
    }, [query]);

    if (!statuses)
      return (
        <Select disabled fullWidth>
          <MenuItem>Loading...</MenuItem>
        </Select>
      );

    const selectedStatus = statuses.find((x) => x.id === rest.value);
    return (
      <Box className="w-full flex justify-around items-center gap-2">
        {statuses?.length > 0 && <StatusBadge status={selectedStatus?.status as Status} />}
        <FormControl fullWidth>
          {showLabel && <InputLabel>Status</InputLabel>}
          {statuses.length > 0 && (
            <Select
              label={showLabel ? 'Status' : undefined}
              defaultValue={allowEmptyValue ? 'all' : statuses[0].id}
              {...rest}
            >
              {allowEmptyValue && <MenuItem value={'all'}>{'All'}</MenuItem>}
              {statuses?.map((status) => (
                <MenuItem key={status.id} value={status.id} className="capitalize">
                  {status.status}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Box>
    );
  }
);

export default StatusSelect;
