import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack
} from '@mui/material';
import { format } from 'date-fns';
import { useFilterValues } from 'hooks';
import { useCallback, useState } from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRangeFieldProps {
  periodValue?: string;
  rangeValue?: RangeKeyDict;
  onPeriodChange: (value: string | undefined) => void;
  onRangeChange: (item: RangeKeyDict | undefined) => void;
}

const DateRangeField = ({
  periodValue,
  rangeValue,
  onPeriodChange,
  onRangeChange
}: DateRangeFieldProps) => {
  const { datePeriodFilters } = useFilterValues();
  const [keepPeriodOpen, setKeepPeriodOpen] = useState<boolean>(false);
  const [rangeVisible, setRangeVisible] = useState<boolean>();
  const [periodVisible, setPeriodVisible] = useState<boolean>(!rangeValue);
  const [rangeValueVisible, setRangeValueVisible] = useState<boolean>(Boolean(rangeValue));

  const initialStartDate = new Date();
  const initialEndDate = new Date();

  const initialState = {
    selection: {
      startDate: rangeValue?.selection.startDate
        ? new Date(rangeValue?.selection.startDate)
        : initialStartDate,
      endDate: rangeValue?.selection.endDate
        ? new Date(rangeValue?.selection.endDate)
        : initialEndDate,
      key: 'selection'
    }
  };

  const [state, setState] = useState<RangeKeyDict>({ ...initialState });

  const handleChange = useCallback((item: RangeKeyDict) => {
    setState((prevState) => ({ ...prevState, ...item }));
  }, []);

  const handlePeriodChange = useCallback(
    (value: string) => {
      if (value === 'dr') {
        setRangeVisible(true);
        setKeepPeriodOpen(true);
      } else {
        setRangeVisible(false);
        setKeepPeriodOpen(false);
        onRangeChange(undefined);
      }

      onPeriodChange(value);
      setKeepPeriodOpen(false);
    },
    [onPeriodChange, onRangeChange]
  );

  const handlePeriodClick = useCallback((value: string) => {
    if (value === 'dr') {
      setRangeVisible(true);
      setKeepPeriodOpen(false);
    }
  }, []);

  return (
    <>
      {periodVisible && !rangeVisible && (
        <FormControl fullWidth>
          <InputLabel id="date-select-label">Date Range</InputLabel>
          <Select
            open={keepPeriodOpen}
            onOpen={() => !keepPeriodOpen && setKeepPeriodOpen(true)}
            onClose={() => keepPeriodOpen && setKeepPeriodOpen(false)}
            labelId="date-select-label"
            id="date-select"
            value={periodValue ?? 'all'}
            label="Date Range"
            onChange={(e) => handlePeriodChange(e.target.value as string)}
          >
            <MenuItem value={'all'}>{'All'}</MenuItem>
            {datePeriodFilters.map((filter) => (
              <MenuItem
                key={filter.value}
                value={filter.value}
                onClick={() => handlePeriodClick(filter.value)}
              >
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {(rangeValueVisible || rangeVisible) && (
        <FormControl fullWidth>
          <InputLabel id="date-select-label">Date Range</InputLabel>
          <Select
            labelId="date-select-label"
            id="date-select"
            value={0}
            label="Date Range"
            onOpen={() => {
              setRangeVisible(false);
              setRangeValueVisible(false);
              setKeepPeriodOpen(true);
              setPeriodVisible(true);
            }}
          >
            <MenuItem value={0}>{`${format(
              state?.selection.startDate ?? initialStartDate,
              'P'
            )} - ${format(state?.selection.endDate ?? initialEndDate, 'P')}`}</MenuItem>
          </Select>
        </FormControl>
      )}
      {rangeVisible && (
        <Box className="w-full z-10 relative" onClick={(e) => e.stopPropagation()}>
          <Stack className="fixed top-30" onClick={(e) => e.stopPropagation()}>
            <Paper elevation={15} onClick={(e) => e.stopPropagation()}>
              <DateRange
                onChange={handleChange}
                maxDate={new Date()}
                ranges={[state.selection]}
                rangeColors={['#ff421c']}
              />
              <Box className="w-full flex justify-between pl-4 pr-4">
                <Button
                  variant="text"
                  onClick={() => {
                    setRangeVisible(false);
                    setKeepPeriodOpen(true);
                  }}
                  className="ml-4 mb-4"
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onRangeChange(state ?? initialState);
                    setRangeVisible(false);
                    setPeriodVisible(false);
                    setRangeValueVisible(true);
                  }}
                  className="ml-4 mb-4"
                >
                  Apply
                </Button>
              </Box>
            </Paper>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default DateRangeField;
