import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useCallback } from 'react';
import { FAQStatus } from 'types/models';
import { Tables } from 'types/models/db.type';
import { APIService } from 'types/services';
import { BaseAdapter } from '../adapters';
import { QueryFunctionContext } from 'react-query';
import { DatePeriod, FAQFilter } from 'types';
import { useDateTime, useFAQService } from 'hooks';

type GetStatusesParams = QueryFunctionContext<['faqs_statuses', FAQFilter]>;
type QnAStatusService = Omit<APIService<FAQStatus>, 'post' | 'put'> & {
  getStatusesFAQCount: (params: GetStatusesParams) => Promise<FAQStatus[] | undefined>;
};

const useFAQStatusService = (): QnAStatusService => {
  const { adapt } = BaseAdapter<Tables<'kbb_faq_statuses'>, FAQStatus>();
  const { getDateFromPeriod } = useDateTime();
  const { getCategory } = useFAQService();

  const { FAQ_STATUS_TABLE } = KBB_SCHEMA;

  const get = useCallback(
    async (filter: { id: string }) => {
      let query = supabaseClient.from(FAQ_STATUS_TABLE).select('*');

      if (filter.id) {
        query = query.eq('id', filter.id).limit(1);
      }

      const { data, error } = await query.single<Tables<'kbb_faq_statuses'>>();

      if (error) console.error(error);

      if (!data) return;

      return adapt(data);
    },
    [adapt, FAQ_STATUS_TABLE]
  );

  const getMany = useCallback(async () => {
    let query = supabaseClient.from(FAQ_STATUS_TABLE).select('*');

    const { data, error } = await query.returns<Tables<'kbb_faq_statuses'>[]>();

    if (error) console.log(error);
    return data?.map((item) => adapt(item));
  }, [adapt, FAQ_STATUS_TABLE]);

  const getStatusesFAQCount = useCallback(
    async (params: GetStatusesParams) => {
      const [, filter] = params.queryKey;

      if (!filter.organizationId) return;

      let query = supabaseClient.from(FAQ_STATUS_TABLE).select('*, kbb_faqs(count)');

      if (filter?.question && filter.question.trim().length > 0) {
        query = query.like('kbb_faqs.question', `%${filter.question.trim()}%`);
      }

      if (
        filter?.category &&
        typeof filter.category === 'string' &&
        filter.category.toLocaleLowerCase() !== 'all' &&
        filter.category.toLowerCase() !== 'none' &&
        filter.category !== ''
      ) {
        const category = await getCategory({
          id: filter.category,
          organizationId: filter.organizationId
        });

        if (category?.categories && category?.categories?.length > 0) {
          query = query.in(
            'kbb_faqs.category_id',
            category?.categories?.map((item) => item.id) || []
          );
        } else {
          query = query.eq('kbb_faqs.category_id', filter.category);
        }
      }

      if (typeof filter.category === 'string' && filter?.category === 'none') {
        query = query.is('kbb_faqs.category_id', null);
      }

      if (filter?.source && filter.source !== 'all') {
        query = query.eq('kbb_faqs.source', filter.source);
      }

      if (filter?.period && filter.period !== 'all' && filter.period !== 'dr') {
        const period = getDateFromPeriod(filter.period as DatePeriod);
        query = query.gte('kbb_faqs.created_at', period);
      }

      if (filter?.startDate && filter.endDate) {
        query = query
          .gte('kbb_faqs.created_at', filter.startDate)
          .lte('kbb_faqs.created_at', filter.endDate);
      }

      if (filter?.unanswered !== undefined && filter.unanswered === true) {
        query = query.eq('kbb_faqs.answer', 'NA');
      }

      const { data, error } = await query
        .eq('kbb_faqs.organization_id', filter.organizationId)
        .returns<Tables<'kbb_faq_statuses'>[]>();

      if (error) console.log(error);
      return data?.map((item) => adapt(item));
    },
    [adapt, getDateFromPeriod, getCategory, FAQ_STATUS_TABLE]
  );

  return { get, getMany, getStatusesFAQCount };
};

export default useFAQStatusService;
