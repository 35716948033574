import RedashChart from 'components/molecules/redash-chart/RedashChart';
import { InnerPageTemplate } from 'components/templates';
import { useUser } from 'hooks/reducers';
import useAnalyticsService from 'hooks/services/analytics/analytics.service';
import { useQuery } from 'react-query';

const AnalyticsPage = () => {
  const { user } = useUser();
  const { getMany: getChartsData } = useAnalyticsService();

  const { data } = useQuery(
    ['analytics', { organizationId: user?.user_metadata.organizationId }],
    () => getChartsData(user?.user_metadata.organizationId) || []
  );

  return (
    <InnerPageTemplate header="Analytics">
      {data?.map((chart) => <RedashChart key={chart.id} chartURL={chart.dashboardUrl!} />)}
    </InnerPageTemplate>
  );
};

export default AnalyticsPage;
