import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useAgentsService } from 'hooks';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Database } from 'types/models/db.type';
import DeleteIcon from '@mui/icons-material/Delete';

type Agent = Database['public']['Tables']['v_agents']['Row'];

interface AgentQASettings {
  auto_qa_enabled: boolean;
  evaluation_questions: Array<{ question: string }>;
  scale: 'quantitative' | 'qualitative';
  hallucinations_enabled: boolean;
  sentiments_enabled: boolean;
  repetitions_enabled: boolean;
}

interface AgentQARubricFormProps {
  agent: Agent;
  qaSettings: AgentQASettings;
}

const AgentQARubricForm = ({ agent, qaSettings }: AgentQARubricFormProps) => {
  const { put } = useAgentsService();

  const { control, handleSubmit, watch } = useForm<AgentQASettings>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      auto_qa_enabled: qaSettings?.auto_qa_enabled ?? false,
      evaluation_questions: qaSettings?.evaluation_questions ?? [],
      scale: qaSettings?.scale ?? 'qualitative',
      hallucinations_enabled: qaSettings?.hallucinations_enabled ?? false,
      sentiments_enabled: qaSettings?.sentiments_enabled ?? false,
      repetitions_enabled: qaSettings?.repetitions_enabled ?? false
    }
  });

  const watchUserData = watch('evaluation_questions', qaSettings?.evaluation_questions);

  const { fields, append, remove } = useFieldArray({
    name: 'evaluation_questions',
    control
  });

  const editMutation = useMutation({
    mutationKey: 'edit_agent',
    mutationFn: (agent: Agent) => put(agent),
    onSuccess: () => {}
  });

  const onSubmit = (data: AgentQASettings) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { v_phone_numbers, ...agentProps } = agent as any;

    editMutation.mutate({ ...agentProps, qa_settings: data as any });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const subscription = watch((value, { name, type }) => {
      if (name === 'evaluation_questions') {
        return;
      }

      handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [handleSubmit, watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body2" className="mb-4 text-gray8">
        Evaluate if your call was successful. You can use Rubric standalone or in combination with
        Success Evaluation Prompt. If both are provided, they are concatenated into appropriate
        instructions.
      </Typography>

      <Stack direction="column">
        <Controller
          control={control}
          name="auto_qa_enabled"
          rules={{ required: false }}
          render={({ field: { name, value, onChange, ref } }) => (
            <FormControlLabel
              control={
                <Switch
                  name={name}
                  value={value}
                  defaultChecked={qaSettings?.auto_qa_enabled}
                  onChange={onChange}
                  ref={ref}
                />
              }
              label="Enable autoQA after every call"
            />
          )}
        />
        <Typography variant="body2" className="text-gray8 mb-4 ml-12">
          Automatically evaluate the QA rubric after each call
        </Typography>
      </Stack>

      <Card elevation={0} className="border border-solid border-gray10">
        <Stack direction="column">
          <Typography variant="subtitle1" className="font-bold">
            Preloaded Detections
          </Typography>
          <Stack direction="column">
            <Controller
              control={control}
              name="hallucinations_enabled"
              rules={{ required: false }}
              render={({ field: { name, value, onChange, ref } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      name={name}
                      value={value}
                      defaultChecked={qaSettings?.hallucinations_enabled}
                      onChange={onChange}
                      ref={ref}
                    />
                  }
                  label="Detect hallucinations"
                />
              )}
            />
            <Typography variant="body2" className="text-gray8 mb-4 ml-12">
              Look for LLM hallucinations within the conversation
            </Typography>
          </Stack>

          <Stack direction="column">
            <Controller
              control={control}
              name="sentiments_enabled"
              rules={{ required: false }}
              render={({ field: { name, value, onChange, ref } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      name={name}
                      value={value}
                      defaultChecked={qaSettings?.sentiments_enabled}
                      onChange={onChange}
                      ref={ref}
                    />
                  }
                  label="Detect sentiments"
                />
              )}
            />
            <Typography variant="body2" className="text-gray8 mb-4 ml-12">
              Detect the user sentiment (good, neutral, bad)
            </Typography>
          </Stack>

          <Stack direction="column">
            <Controller
              control={control}
              name="repetitions_enabled"
              rules={{ required: false }}
              render={({ field: { name, value, onChange, ref } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      name={name}
                      value={value}
                      defaultChecked={qaSettings?.repetitions_enabled}
                      onChange={onChange}
                      ref={ref}
                    />
                  }
                  label="Detect repetitions"
                />
              )}
            />
            <Typography variant="body2" className="text-gray8 mb-4 ml-12">
              Detect if the user had to repeat multiple times the same information
            </Typography>
          </Stack>
        </Stack>
      </Card>

      <Card elevation={0} className=" mt-4 border border-solid border-gray10">
        <Stack direction="column">
          <Typography variant="subtitle1" className="font-bold">
            QA Rubric
          </Typography>
          <Typography variant="body2" className="text-gray8 mb-4">
            This enforces the rubric evaluation upon the Success Evaluation.
          </Typography>
        </Stack>

        <Controller
          control={control}
          name="scale"
          rules={{ required: false }}
          render={({ field }) => (
            <FormControl fullWidth variant="outlined" className="mb-4">
              <InputLabel id="scale-select-label">Scale</InputLabel>
              <Select
                labelId="scale-select-label"
                label="Scale"
                {...field}
                value={field.value}
                onChange={field.onChange}
                fullWidth
              >
                <MenuItem value="qualitative">Qualitative Scale</MenuItem>
                <MenuItem value="quantitative">Quantitative Scale</MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <Stack direction="column">
          <Typography variant="subtitle1" className="font-bold">
            Evaluation Questions
          </Typography>
          <Typography variant="body2" className="text-gray8">
            This is the prompt that’s used to evaluate if the call was successful.
          </Typography>
        </Stack>

        <Grid item xs={12} className="flex flex-1">
          <Box className="w-full">
            <Stack direction="row" className="flex justify-end">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => append({ question: '' })}
              >
                Add question
              </Button>
            </Stack>
            {fields?.map((field, idx) => {
              return (
                <Stack direction="row" className="flex flex-col gap-2 mt-4" key={field.id}>
                  <Stack direction="row" className="flex flex-row gap-2">
                    <Stack direction="column" className="flex-col flex-1">
                      <Controller
                        control={control}
                        rules={{ required: { value: true, message: 'Question is required' } }}
                        name={`evaluation_questions.${idx}.question`}
                        render={({ field: { value, onChange, ref } }) => (
                          <TextField
                            fullWidth
                            value={value}
                            onChange={onChange}
                            label={'Name'}
                            ref={ref}
                          />
                        )}
                      />
                      {watchUserData[idx]?.question === '' && (
                        <span className="text-red2">question is required</span>
                      )}
                    </Stack>

                    <IconButton
                      onClick={() => {
                        remove(idx);
                      }}
                      className="flex-col flex-[.13]"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </Stack>
              );
            })}
          </Box>
        </Grid>

        {/* <Controller
          control={control}
          name="evaluation_questions"
          rules={{ required: false }}
          render={({ field: { name, value, onChange, ref } }) => (
            <TextField
              className="mb-4"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={handleSubmit(onSubmit)}
              label="Evaluation Questions"
              ref={ref}
              fullWidth
              multiline
              minRows={5}
              placeholder="Type your evaluation questions..."
              variant="outlined"
            />
          )}
        /> */}
      </Card>
    </form>
  );
};

export default AgentQARubricForm;
