import { FAQArticle } from 'components/organisms';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import ROUTE_PATHS from 'routes/paths';

const FAQArticlePage = (): React.ReactElement => {
  const { faqId } = useParams();
  const navigate = useNavigate();
  const [currentQuestionId, setCurrentQuestionId] = useState<string | undefined>(faqId);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (!faqId) return;

    setCurrentQuestionId(faqId);
  }, [setCurrentQuestionId, faqId]);

  if (!currentQuestionId || isNaN(Number(currentQuestionId))) {
    return <Navigate to={ROUTE_PATHS.FAQ_LIST_PAGE} replace />;
  }

  return (
    <FAQArticle
      faqId={Number(currentQuestionId)}
      handleBackClick={handleBack}
      key={currentQuestionId}
    />
  );
};

export default FAQArticlePage;
