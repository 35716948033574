import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton, Stack, Toolbar } from '@mui/material';
import { PageHeader, UpTransition } from 'components/atoms';
import React from 'react';

interface FullScreenDialogProps {
  children: React.ReactNode;
  title: string;
  open: boolean;
  handleClose: VoidFunction;
}

function FullScreenDialog({
  children,
  title,
  open,
  handleClose
}: Readonly<FullScreenDialogProps>): React.ReactElement {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={UpTransition}
      className="p-0"
    >
      <Box className="w-full">
        <Toolbar className="p-0 !min-h-[3rem]">
          <Stack direction="row" className="w-full justify-between">
            {title && <PageHeader title={title} />}
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Stack>
        </Toolbar>
        {children}
      </Box>
    </Dialog>
  );
}

export default FullScreenDialog;
