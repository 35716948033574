import { IconButton, TextField } from '@mui/material';
import { ReactElement, useState, useCallback } from 'react';
import EditableCellProps from './EditableCell.props';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';

const EditableCell = ({
  component,
  componentValue,
  field,
  value,
  item,
  onSubmit
}: EditableCellProps): ReactElement => {
  const [showEditor, setShowEditor] = useState<any>();
  const [inputValue, setInputValue] = useState<string>(value);
  const [showLink, setShowLink] = useState<boolean>(false);
  const navigate = useNavigate();

  const onChange = useCallback((newValue: string) => setInputValue(newValue), []);

  const handleSubmit = useCallback(() => {
    onSubmit(field, inputValue, item);
    setShowEditor(false);
  }, [field, inputValue, item, onSubmit]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
      if (e.key === 'Escape') {
        e.stopPropagation();
        setShowEditor(false);
      }
    },
    [handleSubmit]
  );

  if (showEditor) {
    return (
      <TextField
        label={field}
        variant="outlined"
        value={inputValue}
        onChange={(e) => onChange(e.target.value)}
        onBlur={handleSubmit}
        onKeyDown={handleKeyDown}
      />
    );
  }

  if (component === 'link') {
    return (
      <td
        onDoubleClick={() => {
          setShowEditor(!showEditor);
        }}
        onMouseEnter={() => setShowLink(true)}
        onMouseLeave={() => setShowLink(false)}
        className="flex items-center"
      >
        {inputValue}
        {showLink && (
          <IconButton
            onClick={() => {
              componentValue && navigate(componentValue);
            }}
          >
            <OpenInNewIcon className="cursor-pointer" fontSize="small" />
          </IconButton>
        )}
      </td>
    );
  }

  return (
    <td
      onDoubleClick={() => {
        setShowEditor(!showEditor);
      }}
    >
      {inputValue}
    </td>
  );
};

export default EditableCell;
