import { Box, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'components/molecules';
import { AgentQARubricForm, FullScreenDialog } from 'components/organisms';
import { useAgentsService } from 'hooks';
import { useUser } from 'hooks/reducers';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Database } from 'types/models/db.type';

type Agent = Database['public']['Tables']['v_agents']['Row'];

const AgentPerformancePage = () => {
  const { user } = useUser();
  const { get } = useAgentsService();
  let { agentId } = useParams();
  const [tabValue, setTabValue] = useState(1);
  const [agent, setAgent] = useState<Agent>();
  const navigate = useNavigate();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useQuery({
    queryKey: ['v_agents', { id: agentId!! }],
    queryFn: get,
    enabled: !!user?.user_metadata.organizationId,
    onSuccess: (data: any) => {
      if (data) {
        setAgent(data);
      }
    }
  });

  return (
    <FullScreenDialog title={'Performance'} open={true} handleClose={() => navigate(-1)}>
      <Box className="h-full">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="Performance tabs"
            className="mt-0"
          >
            <Tab label="General" className="capitalize font-semibold" disabled />
            <Tab label="Quality Assurance" className="capitalize font-semibold" />
            <Tab label="Pathway Analytics" className="capitalize font-semibold" disabled />
            <Tab label="FAQ Manager" className="capitalize font-semibold" disabled />
          </Tabs>
        </Box>
        <Box className="h-full ml-4">
          <TabPanel value={tabValue} index={0}>
            <Typography variant="h6">General Content</Typography>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            {agent && tabValue === 1 && (
              <Box className="w-full mt-6">
                <AgentQARubricForm agent={agent} qaSettings={agent?.qa_settings as any} />
              </Box>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Typography variant="h6">Pathway Analytics Content</Typography>
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <Typography variant="h6">FAQ Manager Content</Typography>
          </TabPanel>
        </Box>
      </Box>
    </FullScreenDialog>
  );
};

export default AgentPerformancePage;
