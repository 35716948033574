import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Chip, Skeleton, Stack, Typography } from '@mui/material';
import TaggerModal from '../tagger/TaggerModal';
import { useState } from 'react';
import { Tag } from 'types/models/evaluations.model';
import { Tables } from 'types/models/db.type';

type Tags = Tables<'tags'>;

interface TagsManagerProps {
  loading?: boolean;
  initialTags?: Tag[];
  onTagUpdated: (tags: Tags) => void;
  onTagCreated: (tags: Tags) => void;
}

const TagsManager = ({ initialTags, loading, onTagUpdated, onTagCreated }: TagsManagerProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTagId, setSelectedTagId] = useState<number>();

  const handleToggle = () => {
    setIsModalOpen(!isModalOpen);
    isModalOpen && setSelectedTagId(undefined);
  };

  const handleTagClick = (tag: Tag) => {
    setSelectedTagId(tag.id);
    setIsModalOpen(true);
  };

  return (
    <Stack className="h-full pt-6 pr-4 pb-6 pl-4">
      <Stack direction="row" className="w-full justify-between">
        <Typography variant="subtitle1">Tags</Typography>
        <Button
          variant="text"
          startIcon={<AddCircleOutlineIcon />}
          className="capitalize font-bold"
          onClick={handleToggle}
        >
          Add
        </Button>
      </Stack>
      <Stack direction="row" className="gap-2">
        {initialTags?.map((tag, idx) => (
          <>
            {!loading && (
              <Chip
                key={idx}
                variant="outlined"
                className="bg-white cursor-pointer"
                label={tag.name}
                onClick={() => handleTagClick(tag)}
              />
            )}
            {loading && <Skeleton width={72} height={32} />}
          </>
        ))}
      </Stack>
      <TaggerModal
        isOpen={isModalOpen}
        onModalClose={handleToggle}
        tagId={selectedTagId?.toString()}
        onTagUpdated={onTagUpdated}
        onTagCreated={onTagCreated}
      />
    </Stack>
  );
};

export default TagsManager;
