import { Filter, PeriodFilter } from 'types';

const useFilterValues = () => {
  const datePeriodFilters: PeriodFilter[] = [
    {
      name: 'Last hour',
      value: '1h'
    },
    {
      name: 'Last 24 hours',
      value: '24h'
    },
    {
      name: 'Last 7 days',
      value: '7d'
    },
    {
      name: 'Last 30 days',
      value: '30d'
    },
    {
      name: 'Date Range',
      value: 'dr'
    }
  ];

  const channelFilters: Filter[] = [
    {
      name: 'Web',
      value: 'Web'
    },
    {
      name: 'Conversation',
      value: 'conversation'
    }
  ];

  return { datePeriodFilters, channelFilters };
};

export default useFilterValues;
