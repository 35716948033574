import { SvgIcon, SvgIconProps } from '@mui/material';

function StartNodeIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.29248 5.34608C0.29248 2.69178 2.44422 0.540039 5.09853 0.540039H20.9264C23.5807 0.540039 25.7325 2.69178 25.7325 5.34608V21.174C25.7325 23.8283 23.5807 25.98 20.9264 25.98H5.09853C2.44422 25.98 0.29248 23.8283 0.29248 21.174V5.34608Z"
        fill="#22C55E"
        fill-opacity="0.08"
      />
      <mask
        id="mask0_900_13903"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="16"
        height="17"
      >
        <rect x="5.0625" y="5.31006" width="15.9" height="15.9" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_900_13903)">
        <path
          d="M12.2137 15.4895L15.0994 13.6359C15.2411 13.5459 15.312 13.4208 15.312 13.2607C15.312 13.1006 15.2411 12.9751 15.0994 12.8843L12.2137 11.0306C12.0651 10.9287 11.9126 10.9205 11.7562 11.0061C11.6 11.0916 11.5219 11.2251 11.5219 11.4064V15.1137C11.5219 15.295 11.6 15.4285 11.7562 15.514C11.9126 15.5996 12.0651 15.5915 12.2137 15.4895ZM13.0137 19.5538C12.1431 19.5538 11.3249 19.3886 10.5589 19.0583C9.79297 18.7279 9.12672 18.2795 8.56017 17.7132C7.99362 17.1469 7.54505 16.4809 7.21447 15.7153C6.88399 14.9497 6.71875 14.1316 6.71875 13.2612C6.71875 12.3907 6.88393 11.5724 7.2143 10.8065C7.54467 10.0405 7.99301 9.37427 8.55934 8.80773C9.12567 8.24118 9.79165 7.79261 10.5573 7.46202C11.3229 7.13155 12.1409 6.96631 13.0113 6.96631C13.8819 6.96631 14.7001 7.13149 15.4661 7.46186C16.232 7.79222 16.8983 8.24057 17.4648 8.8069C18.0314 9.37323 18.4799 10.0392 18.8105 10.8048C19.141 11.5705 19.3062 12.3885 19.3062 13.2589C19.3062 14.1294 19.1411 14.9477 18.8107 15.7136C18.4803 16.4796 18.032 17.1458 17.4657 17.7124C16.8993 18.2789 16.2334 18.7275 15.4677 19.0581C14.7021 19.3886 13.8841 19.5538 13.0137 19.5538Z"
          fill="#118D57"
        />
      </g>
    </SvgIcon>
  );
}

export default StartNodeIcon;
