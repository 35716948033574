import clsx from 'clsx';

const classes = (isCollapsed: boolean, gaps: boolean, horizontalGlutter: boolean) => ({
  mainContainer: clsx(
    'bg-light-gray h-screen max-h-screen w-full ',
    gaps && 'pt-4 ',
    gaps && horizontalGlutter && 'pl-[5.8125rem]',
    horizontalGlutter && 'mr-3 pr-3'
  )
});

export default classes;
