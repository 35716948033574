import RedashChartProps from './RedashChart.props';

const RedashChart = ({ chartURL }: RedashChartProps) => {
  return (
    <div className="overflow-hidden relative w-full h-[calc(100vh-10rem)]">
      <iframe
        src={chartURL}
        title="Dashboard"
        width="100%"
        className="w-full absolute h-[calc(100vh-10rem)] border-none overflow-auto"
      />
    </div>
  );
};

export default RedashChart;
