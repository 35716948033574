import { Stack, Button, Typography } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useState } from 'react';
import clsx from 'clsx';
import { Citation as CitationType } from 'types/models/evaluations.model';

interface CitationProps {
  citation: CitationType;
}

const Citation = ({ citation }: CitationProps) => {
  const [active, setActive] = useState<boolean>(false);

  const handleMouseMove = () => setActive(!active);

  const handleClick = () => {
    window.open(citation.url, '_blank');
  };

  return (
    <Stack
      direction="row"
      className={clsx('flex items-center', active && ' bg-pink', !active && 'bg-whit')}
      onMouseEnter={handleMouseMove}
      onMouseLeave={handleMouseMove}
    >
      <Button
        variant="text"
        startIcon={<InsertLinkIcon />}
        className="capitalize font-bold truncate"
        onClick={handleClick}
      >
        {citation.name}
      </Button>
      {/* TODO: check if citations should be cropped */}
      {/* <Typography variant="body2" className={clsx(active && 'text-black', !active && 'text-gray8')}>
        {citation.shortDescription}
      </Typography> */}
    </Stack>
  );
};

export default Citation;
