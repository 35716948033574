import { Button } from '@mui/material';
import React, { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';

import { useUser } from 'hooks/reducers';
import { useToast } from 'contexts/ToastContext';
import { useSourceService } from 'hooks';
import { Clear } from '@mui/icons-material';

const PDFForm = ({ handleClose }: { handleClose: () => void }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [formData, setFormData] = useState(new FormData());
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  };

  const { showToast } = useToast();

  const { user } = useUser();

  const { postPDF } = useSourceService();

  const postFiles = useCallback(async () => {
    if (files.length === 0) {
      showToast('Please upload a file', 'error');
      return;
    }

    await postPDF(formData, user?.user_metadata.organizationId!);
    handleClose();
  }, [files.length, formData, handleClose, postPDF, showToast, user?.user_metadata.organizationId]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const isAlreadyUploaded = files.some((f) => f.name === file.name);
        if (isAlreadyUploaded) {
          showToast(`${file.name} already selected`, 'error');
          return;
        }
        setFiles((prevFiles) => [...prevFiles, file]);
        formData.append('file', file);
      });
    },
    [files, formData, showToast]
  );

  const onFileDelete = useCallback(
    (file: File) => {
      setFiles((prevFiles) => prevFiles.filter((f) => f.name !== file.name));
      const newFormData = new FormData();
      files.forEach((f) => {
        if (f.name !== file.name) {
          newFormData.append('file', f);
        }
      });
      setFormData(newFormData);
    },
    [files, setFiles]
  );

  return (
    <div>
      <h3 className="text-base font-bold">PDF File</h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8">
          <Dropzone
            accept={{ 'application/pdf': [] }}
            maxSize={50000000}
            maxFiles={10}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
            onError={(error) => {
              showToast(`Error: ${error.message}`, 'error');
            }}
            onDropRejected={(error) => {
              if (error[0].errors[0].code === 'file-invalid-type') {
                showToast('Invalid file type. Please upload a PDF file', 'error');
              } else if (error[0].errors[0].code === 'file-too-large') {
                showToast('File is too large. Please upload a file smaller than 50MB', 'error');
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="flex h-32 text-sm border-dashed border-2 text-orange text-center justify-center items-center cursor-pointer p-8"
                >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>
          <ul className="pl-5 pr-5 ">
            {files.length > 0 && (
              <div className="">
                <h3 className="text-base font-bold">Files</h3>

                {files.map((file) => (
                  <li className="flex items-center" key={file.name}>
                    <span className="truncate">{file.name}</span>
                    <Clear
                      className="h-5 ml-2 w-5 hover:text-orange cursor-pointer"
                      onClick={() => onFileDelete(file)}
                    />
                  </li>
                ))}
              </div>
            )}
          </ul>
          <Button type="submit" variant="contained" color="primary" onClick={postFiles}>
            Submit
          </Button>
        </div>
      </form>
      <div className="pt-8">
        <h3 className="text-base text-orange font-bold">Instructions</h3>
        <ol>
          <li>Upload one or multiple PDF</li>
          <li>Files should be under 50MB each</li>
          <li>10 files limit</li>
          <li>Click submit</li>
        </ol>
      </div>
    </div>
  );
};

export default PDFForm;
