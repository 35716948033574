import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useToast } from 'contexts/ToastContext';
import useDateTime from 'hooks/utils/use-date-time';
import { useCallback } from 'react';
import { QueryFunctionContext } from 'react-query';
import { DatePeriod, EvaluationsFilter } from 'types';
import { Evaluations } from 'types/models';
import { Tables } from 'types/models/db.type';
import { Conversation, LLMResponse, Message } from 'types/models/evaluations.model';
import { BaseAdapter } from '../adapters';
import { useUser } from 'hooks/reducers';

type GetEvaluationsParams = QueryFunctionContext<['evaluations', EvaluationsFilter]>;
type GetInboxesParams = QueryFunctionContext<['inboxes', { organizationId: string | undefined }]>;

const useEvaluationsService = () => {
  const { adapt, reverse } = BaseAdapter<Tables<'rta_llm_responses'>, Evaluations>();
  const conversationAdapter = BaseAdapter<Tables<'vw_rta_conversations'>, Conversation>();
  const messageAdapter = BaseAdapter<Tables<'rta_messages'>, Message>();
  const llmResponseAdapter = BaseAdapter<Tables<'rta_llm_responses'>, LLMResponse>();
  const user = useUser();

  const {
    LLM_RESPONSES_TABLE,
    RTA_MESSAGES_TABLE,
    RTA_CONVERSATIONS_VIEW,
    RTA_CONVERSATION_INBOX_VIEW
  } = KBB_SCHEMA;

  const { getDateFromPeriod } = useDateTime();

  const { showToast } = useToast();

  const getConversationMessages = useCallback(
    async (filter: { conversationId: string }) => {
      if (!filter.conversationId) return;

      let query = supabaseClient
        .from(RTA_MESSAGES_TABLE)
        .select('*')
        .eq('conversation_id', filter.conversationId)
        .order('message_date');

      const { data, error } = await query.returns<Tables<'rta_messages'>[]>();

      if (error) console.error(error);

      if (!data) return;

      return data?.map((item) => messageAdapter.adapt(item));
    },
    [messageAdapter, RTA_MESSAGES_TABLE]
  );

  const getInboxes = useCallback(
    async (params: GetInboxesParams) => {
      const [, filter] = params.queryKey as any;

      let query = supabaseClient
        .from(RTA_CONVERSATION_INBOX_VIEW)
        .select(`*`, { count: 'exact' })
        .eq('organization_id', filter.organizationId);

      const { data, count, error } = await query.returns<any>();

      return { data, count };
    },
    [RTA_CONVERSATION_INBOX_VIEW]
  );

  const getConversations = useCallback(
    async (params: GetEvaluationsParams) => {
      const [, filter] = params.queryKey as any;

      let query = supabaseClient
        .from(RTA_CONVERSATIONS_VIEW)
        .select(`*`, { count: 'exact' })
        .eq('organization_id', filter.organizationId)
        .neq('agent_response_id', null)
        .order('customer_message_date', { ascending: filter.sort === 'asc' });

      if (filter.page !== undefined) {
        if (filter.page === 0) {
          query = query.range(0, filter.limit - 1);
        } else {
          query = query.range(
            (filter.page - 1) * filter.limit,
            (filter.page - 1) * filter.limit + filter.limit
          );
        }
      }

      if (filter.score && filter.score !== 'all') {
        if (filter.score === 'no-score') {
          query = query.is('similarity_score', null);
        } else {
          query = query.eq('similarity_score', filter.score);
        }
      }

      if (filter?.period && filter.period !== 'all' && filter.period !== 'dr') {
        const period = getDateFromPeriod(filter.period as DatePeriod);
        query = query.gte('customer_message_date', period);
      }

      if (filter?.startDate && filter.endDate) {
        query = query
          .gte('customer_message_date', filter.startDate)
          .lte('customer_message_date', filter.endDate);
      }

      if (filter?.text !== undefined && filter?.text !== '') {
        query = query.or(
          `customer_message_id.like.%${filter.text}%,customer_message_author_handle.like.%${filter.text}%,customer_message_text.like.%${filter.text}%`
        );
      }

      if (filter?.tags && filter.tags.length > 0) {
        query = query.filter(
          'tags',
          'cs',
          JSON.stringify(
            filter.tags?.map((t: any) => ({
              id: parseInt(t)
            }))
          )
        );
      }

      if (filter.inbox && filter.inbox !== 'all') {
        query = query.like('inbox', `%${filter.inbox}%`);
      }

      const { data, count, error } = await query.returns<Tables<'vw_rta_conversations'>[]>();

      const adaptedData = data?.map((item) => conversationAdapter.adapt(item));

      if (error) console.log(error);
      return { data: adaptedData, count };
    },
    [conversationAdapter, RTA_CONVERSATIONS_VIEW]
  );

  const get = useCallback(
    async ({ id, organizationId }: { id: string; organizationId: string }) => {
      let query = supabaseClient
        .from(RTA_CONVERSATIONS_VIEW)
        .select(`*`, { count: 'exact' })
        .eq('organization_id', organizationId)
        .eq('agent_response_id', id);

      const { data, count, error } = await query
        .returns<Tables<'vw_rta_conversations'>[]>()
        .single();

      const adaptedData = conversationAdapter.adapt(data as Tables<'vw_rta_conversations'>);

      if (error) console.log(error);
      return { data: adaptedData, count };
    },
    [conversationAdapter, RTA_CONVERSATIONS_VIEW]
  );

  const getMany = useCallback(
    async (params: GetEvaluationsParams) => {
      const [, filter] = params.queryKey as any;

      let query = supabaseClient
        .from(LLM_RESPONSES_TABLE)
        .select(`*`, { count: 'exact' })
        .eq('organization_id', filter.organizationId)
        .neq('agent_response_id', null)
        .order('customer_message_date', { ascending: filter.sort === 'asc' });

      if (filter.page) {
        if (filter.page === 0) {
          query = query.range(0, filter.limit - 1);
        } else
          query = query.range(filter.page * filter.limit, (filter.page + 1) * filter.limit - 1);
      }

      if (filter.score && filter.score !== 'all') {
        if (filter.score === 'no-score') {
          query = query.is('similarity_score', null);
        } else {
          query = query.eq('similarity_score', filter.score);
        }
      }

      if (filter?.period && filter.period !== 'all' && filter.period !== 'dr') {
        const period = getDateFromPeriod(filter.period as DatePeriod);
        query = query.gte('customer_message_date', period);
      }

      if (filter?.startDate && filter.endDate) {
        query = query
          .gte('customer_message_date', filter.startDate)
          .lte('customer_message_date', filter.endDate);
      }

      const { data, count, error } = await query.returns<Tables<'rta_llm_responses'>[]>();

      const adaptedData = data?.map((item) => adapt(item));

      if (error) console.log(error);
      return { data: adaptedData, count };
    },
    [LLM_RESPONSES_TABLE, getDateFromPeriod, adapt]
  );

  const updateLLMResponse = useCallback(
    async ({ ...llmResponse }: LLMResponse) => {
      try {
        const item = llmResponseAdapter.reverse(llmResponse);

        const { data, error } = await supabaseClient
          .from(LLM_RESPONSES_TABLE)
          .update({ ...item })
          .eq('agent_response_id', llmResponse.agentResponseId)
          .select();

        if (error) console.error(error);

        if (!data) return;

        showToast('Agent response successfully updated', 'success', {
          toastId: llmResponse?.agentResponseId!
        });

        return data.map((item) => llmResponseAdapter.adapt(item));
      } catch (error) {
        showToast('Error updating agent response', 'error');
      }
    },
    [reverse, LLM_RESPONSES_TABLE, showToast, adapt]
  );

  const simulate = useCallback(
    async ({
      conversationId,
      lastCustomerMessageId
    }: {
      conversationId: string;
      lastCustomerMessageId: string;
    }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_LLM_SERVICE_URL}/assistant/simulate_respond`,
          {
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'authorization, x-client-info, apikey, content-type',
              'Content-Type': 'application/json',
              'X-User-Id': user.user?.id ?? '',
              'X-Organization': user?.user?.user_metadata?.organizations.id ?? '',
              'X-Api-Key': user?.user?.user_metadata?.organizations.apiKey ?? ''
            },
            body: JSON.stringify({
              conversation_id: conversationId,
              message_id: lastCustomerMessageId
            })
          }
        );

        response.ok && showToast('Simulation successfully created', 'success');

        const data = await response.json();

        return data;
      } catch (error) {
        showToast('Error simulating response', 'error');
      }
    },
    [showToast, user]
  );

  return {
    get,
    getConversationMessages,
    getInboxes,
    getMany,
    updateLLMResponse,
    getConversations,
    simulate
  } as const;
};

export default useEvaluationsService;
