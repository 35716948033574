import { SvgIcon, SvgIconProps } from '@mui/material';

function AwesomeIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      z
      <mask
        id="mask0_160_25764"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_160_25764)">
        <path
          d="M15.8333 7.49967L16.875 5.20801L19.1666 4.16634L16.875 3.12467L15.8333 0.833008L14.7916 3.12467L12.5 4.16634L14.7916 5.20801L15.8333 7.49967Z"
          fill="#FF421C"
        />
        <path
          d="M15.8333 12.4997L14.7916 14.7913L12.5 15.833L14.7916 16.8747L15.8333 19.1663L16.875 16.8747L19.1666 15.833L16.875 14.7913L15.8333 12.4997Z"
          fill="#FF421C"
        />
        <path
          d="M9.58331 7.91634L7.49998 3.33301L5.41665 7.91634L0.833313 9.99967L5.41665 12.083L7.49998 16.6663L9.58331 12.083L14.1666 9.99967L9.58331 7.91634ZM8.32498 10.8247L7.49998 12.6413L6.67498 10.8247L4.85831 9.99967L6.67498 9.17467L7.49998 7.35801L8.32498 9.17467L10.1416 9.99967L8.32498 10.8247Z"
          fill="#FF421C"
        />
      </g>
    </SvgIcon>
  );
}

export default AwesomeIcon;
