import { formatDistanceToNow, parseISO, startOfDay, subDays, subHours } from 'date-fns';
import { useCallback } from 'react';
import { DatePeriod } from 'types';

const useDateTime = () => {
  const getDateFromPeriod = useCallback((range: DatePeriod): string => {
    let date: string;
    switch (range) {
      case '1h':
        date = subHours(new Date(), 1).toISOString();
        break;
      case '24h':
        date = startOfDay(subDays(new Date(), 1)).toISOString();
        break;
      case '7d':
        date = startOfDay(subDays(new Date(), 7)).toISOString();
        break;
      case '30d':
        date = startOfDay(subDays(new Date(), 30)).toISOString();
        break;
      default:
        date = 'all';
        break;
    }
    return date;
  }, []);

  const getTimeAgo = useCallback((timestamp: Date): string => {
    const date = parseISO(timestamp.toString());
    return formatDistanceToNow(date, { addSuffix: true });
  }, []);

  return { getDateFromPeriod, getTimeAgo };
};

export default useDateTime;
