import { ArrowBackIos } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Card, Grid, IconButton, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { PageHeader, Paragraph } from 'components/atoms';
import { SearchField } from 'components/molecules';
import FAQForm from 'components/organisms/forms/faq-form/FAQForm';
import SimpleTable from 'components/organisms/tables/simple-table/SimpleTable';
import {
  useContentSnippetService,
  useDebounce,
  useFAQService,
  useMarkdownConverter,
  useRelatedArticlesService
} from 'hooks';
import { useUser } from 'hooks/reducers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATHS from 'routes/paths';
import { ArticleFilter } from 'types';
import { ContentSnippet, FAQ, SimilarArticle } from 'types/models';
import FAQArticleProps from './FAQArticle.props';

const FAQArticle = ({ faqId, handleBackClick }: FAQArticleProps): React.ReactElement => {
  const navigate = useNavigate();
  const { debounce } = useDebounce();
  const { user } = useUser();

  const { get, getCategory } = useFAQService();

  const {
    get: getContentSnippet,
    put: updateContentSnippet,
    post: createContentSnippet,
    generate
  } = useContentSnippetService();

  const { get: getRelatedArticles } = useRelatedArticlesService();
  const { markdownToString } = useMarkdownConverter();
  const [currentFAQ, setCurrentFAQ] = useState<FAQ>(); // state here in case we need to update any currentFAQ's property
  const [draftContent, setDraftContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [contentSnippet, setContentSnippet] = useState<ContentSnippet>();

  const [relatedArticles, setRelatedArticles] = useState<SimilarArticle[]>([]);
  const [articleFilter, setArticleFilter] = useState<ArticleFilter>({
    title: ''
  });

  const createMutation = useMutation({
    mutationKey: 'postContentSnippet',
    mutationFn: (contentSnippet: ContentSnippet) => createContentSnippet(contentSnippet),
    onSuccess: (data: void | ContentSnippet[] | undefined) => {
      if (data) {
        setContentSnippet(data[0]);
        setDraftContent(data[0].content ?? '');
      }
    }
  });

  const updateMutation = useMutation({
    mutationKey: 'updateContentSnippet',
    mutationFn: (contentSnippet: ContentSnippet) => updateContentSnippet(contentSnippet),
    onSuccess: (data: void | ContentSnippet[] | undefined) => {
      if (data) {
        setContentSnippet(data[0]);
        setDraftContent(data[0].content ?? '');
      }
    }
  });

  const upsertContentSnippet = useCallback(
    async (content: string) => {
      try {
        if (!faqId) return;

        if (!contentSnippet) {
          createMutation.mutate({
            id: 0,
            createdAt: new Date().toISOString(),
            faqId: faqId,
            content: content
          });
        } else {
          updateMutation.mutate({ ...contentSnippet, content: content });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [faqId, contentSnippet, createMutation, updateMutation]
  );

  const relatedArticleCols = [
    {
      id: 'title',
      label: 'Title',
      renderer: ({ title, url }: SimilarArticle) => (
        <Stack direction="row" className="flex">
          <p className="line-clamp-1 text-ellipsis">{title}</p>
          <IconButton
            onClick={() => {
              url && window.open(url, '_blank');
            }}
          >
            <LinkIcon className="cursor-pointer" />
          </IconButton>
        </Stack>
      )
    },
    {
      id: 'content',
      label: 'Content',
      renderer: ({ content }: SimilarArticle) => (
        <p className="line-clamp-2 text-ellipsis">{markdownToString(content.substring(0, 100))}</p>
      )
    }
  ];

  const getQuestionData = useCallback(async () => {
    if (!faqId) return;

    const data = await get({
      id: faqId,
      organizationId: user?.user_metadata.organizationId
    });

    if (!data) {
      navigate(ROUTE_PATHS.FAQ_LIST_PAGE);
    }

    data && setCurrentFAQ(data);
  }, [get, navigate, faqId, user?.user_metadata.organizationId]);

  const getFAQContentSnippet = useCallback(
    async (faqId: string) => {
      if (!faqId) return;

      const data = await getContentSnippet({ faqId: faqId });

      if (data) {
        setContentSnippet({
          ...data
        });
        setDraftContent(data.content ?? '');
      }
    },
    [getContentSnippet]
  );

  const getRelatedArticlesData = useCallback(
    async (faq: FAQ) => {
      if (faq && user?.user_metadata.organizationId) {
        const category = await getCategory({
          id: faq.categoryId?.toString()!!,
          organizationId: user?.user_metadata.organizationId
        });

        const faqArticles = await getRelatedArticles({
          faqId: faq.id.toString(),
          question: faq.question ?? '',
          answer: faq.answer ?? '',
          categoryName: category?.category ?? ''
        });

        faqArticles && setRelatedArticles(faqArticles.similarArticles);
      } else {
        setRelatedArticles([]);
      }
    },
    [getRelatedArticles, getCategory, user?.user_metadata.organizationId]
  );

  const handleGenerate = useCallback(
    async (faq: FAQ) => {
      if (!faq || !user?.email) {
        return;
      }

      try {
        setLoading(true);
        getRelatedArticlesData(faq);

        const data = await generate(
          faq.id.toString(),
          faq.question ?? '',
          faq.answer ?? '',
          faq.additionalInformation ?? ''
        );

        if (data) {
          setDraftContent(data.content);
          upsertContentSnippet(data.content);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [generate, getRelatedArticlesData, upsertContentSnippet, user?.email]
  );

  const getDataDebounced = useMemo(
    () =>
      debounce((updatedFilter: FAQ, field: keyof FAQ, value: string) => {
        getRelatedArticlesData({ ...updatedFilter, [field]: value });
      }, 300),
    [debounce, getRelatedArticlesData]
  );

  const handleArticleFilterChange = useCallback(
    (field: keyof ArticleFilter, value: string) => {
      setArticleFilter((prevState) => {
        if (!prevState) return {} as ArticleFilter;
        return { ...prevState, [field]: value };
      });

      if (field === 'title') {
        getDataDebounced(articleFilter, field, value);
      }
    },
    [articleFilter, getDataDebounced]
  );

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getQuestionData();
    }

    return () => {
      ignore = true;
    };
  }, [getQuestionData]);

  useEffect(() => {
    if (currentFAQ) {
      getFAQContentSnippet(currentFAQ.id.toString());
    }
  }, [currentFAQ, getFAQContentSnippet]);

  return (
    <Box className="w-full h-full" id={faqId.toString()}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={() => handleBackClick()}>
          <ArrowBackIos fontSize="small" />
        </IconButton>
        <PageHeader title="Content Snippet" />
      </Toolbar>

      <Grid container>
        <Grid item xs={12} lg={6}>
          <Card className="bg-white flex flex-col h-full p-4 m-2 mt-0 pt-8">
            {currentFAQ && (
              <FAQForm
                allowSubmit={true}
                editMode={true}
                faq={currentFAQ}
                onSubmitted={() => {}}
                submitPlacement="top"
                submitText={loading ? 'Generating...' : 'Generate'}
                onBeforeSubmit={handleGenerate}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper
            elevation={2}
            className="bg-white flex flex-col h-[calc(50%_-_.5rem)] p-4 mb-2 pt-8 overflow-y-auto"
          >
            {draftContent && (
              <>
                <Box className="h-16 bg-white flex items-center p-4 justify-start">
                  <Typography className="font-semibold text-lg flex items-center">
                    Generated Content
                  </Typography>
                </Box>
                <Paper elevation={2} className="bg-white p-4 m-4">
                  <Box className="w-full flex justify-end">
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(draftContent);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Box>
                  <Markdown components={{ p: Paragraph }}>{draftContent}</Markdown>
                </Paper>
              </>
            )}
          </Paper>

          <Paper
            elevation={2}
            className="bg-white flex flex-col h-[50%] p-4 mt-2 pt-8 overflow-y-auto"
          >
            <Box className="h-16 bg-white flex items-center p-4">
              <Typography className="font-semibold text-lg flex items-center">
                Related Articles
              </Typography>
            </Box>
            <Grid container className="p-4">
              {/* TODO: Implement backend endpoint to search for related articles */}
              {/* <Grid item xs={12}>
                  <SearchField
                    id="article-conversation-filter"
                    className="bg-white w-full"
                    bordered
                    placeholder="Search article by title"
                    value={articleFilter?.title}
                    onChange={(e) => handleArticleFilterChange('title', e.target.value)}
                  />
                </Grid> */}
              <Grid item xs={12}>
                <SimpleTable
                  columns={relatedArticleCols}
                  rows={relatedArticles}
                  tableProps={{
                    stickyHeader: true,
                    'aria-label': 'Existing articles table'
                  }}
                  tableBodyCellProps={{ sx: { fontSize: '14px' } }}
                  tableHeaderCellProps={{ className: 'text-gray2', width: '25%' }}
                  tableContainerProps={{ elevation: 0, className: 'max-h-[60vh]' }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FAQArticle;
