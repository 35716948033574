import { SvgIcon, SvgIconProps } from '@mui/material';

function ReplyIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_270_421"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect x="16" y="16" width="16" height="16" transform="rotate(180 16 16)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_270_421)">
        <path
          d="M11.3 7.90006L9.6667 6.26672C9.54448 6.1445 9.48337 6.00284 9.48337 5.84172C9.48337 5.68061 9.54448 5.53629 9.6667 5.40876C9.78892 5.29185 9.93059 5.23339 10.0917 5.23339C10.2528 5.23339 10.3945 5.2945 10.5167 5.41672L13.1834 8.08339C13.3056 8.20157 13.3667 8.33945 13.3667 8.49702C13.3667 8.6546 13.3056 8.7945 13.1834 8.91672L10.5167 11.5834C10.3945 11.7056 10.2519 11.7667 10.0889 11.7667C9.92596 11.7667 9.78522 11.7056 9.6667 11.5834C9.54448 11.4649 9.48337 11.3241 9.48337 11.1612C9.48337 10.9982 9.54448 10.8556 9.6667 10.7334L11.3 9.10006L5.60003 9.10006C4.7147 9.10006 3.96003 8.78806 3.33603 8.16406C2.71203 7.54006 2.40003 6.78539 2.40003 5.90006L2.40003 4.10006C2.40003 3.93006 2.45719 3.78756 2.57151 3.67256C2.68585 3.55756 2.82751 3.50006 2.99651 3.50006C3.16553 3.50006 3.30836 3.55756 3.42503 3.67256C3.5417 3.78756 3.60003 3.93006 3.60003 4.10006L3.60003 5.90006C3.60003 6.45561 3.79448 6.92784 4.18337 7.31672C4.57225 7.70561 5.04448 7.90006 5.60003 7.90006L11.3 7.90006Z"
          fill="black"
          fillOpacity="0.72"
        />
      </g>
    </SvgIcon>
  );
}

export default ReplyIcon;
