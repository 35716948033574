import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, Chip, Grid, IconButton, Paper, Typography } from '@mui/material';
import { EditableCell, SearchField } from 'components/molecules';
import { SimpleTable } from 'components/organisms';
import ActionsModal from 'components/organisms/tagger/TaggerModal';
import { InnerPageTemplate } from 'components/templates';
import { useConfirmationDialog, useDebounce } from 'hooks';
import { useUser } from 'hooks/reducers';
import useTagsService from 'hooks/services/tagger/tags.service';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { TableColumn } from 'types';
import { Database } from 'types/models/db.type';

interface Filter {
  filter: string;
  organizationId: string;
}

const TaggerPage = () => {
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { openDialog } = useConfirmationDialog();
  const { debounce } = useDebounce();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const { getMany, deleteItem, put } = useTagsService();

  type Tags = Database['public']['Tables']['tags']['Row'];

  const [filter, setFilter] = useState<Filter>({
    filter: searchParams.get('filter') ?? '',
    organizationId: user?.user_metadata.organizationId
  });
  const [searchText, setSearchText] = useState<string>(filter.filter);

  const { data: tags, refetch } = useQuery({
    queryKey: ['tags', filter],
    queryFn: getMany,
    enabled: !!user?.user_metadata.organizationId
  });

  const editMutation = useMutation({
    mutationKey: 'editTag',
    mutationFn: (tag: Tags) => put(tag),
    onSuccess: () => {
      queryClient.invalidateQueries('tags');
      onModalClose();
    }
  });

  const deleteMutation = useMutation({
    mutationKey: 'deleteTag',
    mutationFn: (id: string) => deleteItem(id),
    onSuccess: (data: void | Tags[] | undefined) => {
      if (data) {
        queryClient.invalidateQueries(['tags', user?.user_metadata.organizationId]);
      }
      refetch();
    }
  });

  const handleDelete = useCallback(
    (id: string) => {
      openDialog('Delete Tag', 'Are you sure to delete this tag?', () => deleteMutation.mutate(id));
      refetch();
    },
    [deleteMutation, openDialog, refetch]
  );

  const onModalClose = () => {
    setIsModalOpen(false);
    refetch();
    if (selectedTag) setSelectedTag(null);
  };

  const onEditClick = (id: string) => {
    setSelectedTag(id);
    setIsModalOpen(true);
  };

  const filteredTags = tags?.map((item) => {
    const { organization_id, ...rest } = item;
    return rest;
  });

  const actionKeys = filteredTags ? (filteredTags[0] ? Object.keys(filteredTags[0]) : []) : [];

  const cols: TableColumn<any>[] = [
    {
      id: 'name',
      label: 'name',
      renderer: (item: any) => {
        return (
          <EditableCell
            item={item}
            field="name"
            value={item['name']}
            onSubmit={(field, value, item) => editMutation.mutate({ ...item, [field]: value })}
          />
        );
      }
    },
    {
      id: 'description',
      label: 'description',
      renderer: (item: any) => {
        return (
          <EditableCell
            item={item}
            field="description"
            value={item['description']}
            onSubmit={(field, value, item) => editMutation.mutate({ ...item, [field]: value })}
          />
        );
      }
    },
    {
      id: 'user_status',
      label: 'User Status',
      renderer: (item: any) => {
        return <Chip color="warning" key={item.id} label={item.user_status} />;
      }
    },
    {
      id: 'examples',
      label: 'examples',
      renderer: (item: any) => {
        return (
          <EditableCell
            item={item}
            field="examples"
            value={item['examples']}
            onSubmit={(field, value, item) => editMutation.mutate({ ...item, [field]: value })}
          />
        );
      }
    },
    {
      id: 'llm_additional_instructions',
      label: 'llm_additional_instructions',
      renderer: (item: any) => {
        return (
          <EditableCell
            item={item}
            field="llm_additional_instructions"
            value={item['llm_additional_instructions']}
            onSubmit={(field, value, item) => editMutation.mutate({ ...item, [field]: value })}
          />
        );
      }
    },
    {
      id: 'tags',
      label: 'Actions',
      cellProps: {
        width: '5%',
        align: 'right'
      },
      renderer: ({ id }: { id: string }) => (
        <Box className="w-full justify-end">
          <IconButton onClick={() => onEditClick(id)}>
            <Edit fontSize="small" />
          </IconButton>
          <IconButton onClick={() => handleDelete(id)}>
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      )
    }
  ];

  const updateSearchParamsAndFilter = useCallback(
    (field: keyof Filter, value: string) => {
      setSearchParams((params) => {
        params.set(field, value);
        return params;
      });

      setFilter((prevState) => ({
        ...prevState,
        [field]: value
      }));
    },
    [setSearchParams]
  );

  const setSearchParamDebounced = useMemo(
    () =>
      debounce((field: keyof Filter, value: string) => {
        updateSearchParamsAndFilter(field, value);
      }, 300),
    [debounce, updateSearchParamsAndFilter]
  );

  const handleFilterChange = useCallback(
    (field: keyof Filter, value: any, effect: 'immediate' | 'debounced' = 'immediate') => {
      if (effect === 'immediate') {
        updateSearchParamsAndFilter(field, value);
      } else {
        setSearchParamDebounced(field, value);
      }
    },
    [setSearchParamDebounced, updateSearchParamsAndFilter]
  );

  return (
    <InnerPageTemplate
      header="Tagger"
      headerRightComponent={
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setIsModalOpen(true)}
        >
          Add Tag
        </Button>
      }
    >
      <Paper elevation={0} className="pt-4 rounded-t-xl rounded-b-none">
        <Grid container>
          <Grid item xs={12} md={4} lg={4} className="pr-2 pl-6">
            <SearchField
              id="search-filter"
              className="bg-white w-full"
              bordered
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                handleFilterChange('filter', e.target.value, 'debounced');
              }}
              onClear={() => {
                setSearchText('');
                handleFilterChange('filter', '', 'immediate');
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <div className="flex flex-col gap-4">
        {tags && tags.length === 0 && searchText === '' && (
          <Box className="w-full text-center">
            <Typography variant="body1">
              No tags available yet, please create at least one
            </Typography>
          </Box>
        )}

        {tags && tags?.length > 0 && (
          <SimpleTable
            columns={cols}
            rows={tags! || []}
            tableContainerProps={{ sx: { overflow: 'auto', maxHeight: '85vh', padding: '1rem' } }}
            tableHeaderCellProps={{
              sx: {
                position: 'sticky',
                top: -18,
                backgroundColor: 'white',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                zIndex: 1
              }
            }}
            tableRowProps={{
              sx: {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }
            }}
            tableBodyCellProps={{
              sx: {
                display: '',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: '20px',
                maxWidth: '40px',
                padding: '0 1rem',
                cursor: 'default'
              }
            }}
          />
        )}
        <ActionsModal
          isOpen={isModalOpen}
          onModalClose={onModalClose}
          tagId={selectedTag!}
          keys={actionKeys.slice(1)}
        />
      </div>
    </InnerPageTemplate>
  );
};

export default TaggerPage;
