import { Slider, SliderProps, styled } from '@mui/material';

interface Props extends SliderProps {}

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb:before': {
    display: 'none'
  },
  '& .MuiSlider-thumb': {
    backgroundColor: 'transparent',
    '& .MuiSlider-valueLabel': {
      backgroundColor: 'transparent',
      color: 'black',
      transform: 'translateY(25%)'
    }
  }
}));

const BasicSlider = ({ ...props }: Props): React.ReactElement => {
  return (
    <StyledSlider
      className={`h-8 ${props.color === 'success' && 'text-green3'}`}
      defaultValue={props.defaultValue}
      getAriaValueText={props.getAriaValueText}
      min={0}
      max={100}
      step={0.1}
      {...props}
    />
  );
};

export default BasicSlider;
