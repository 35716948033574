import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AgentSettings } from 'types/models';
import AgentSettingsModalProps from './AgentSettingsModal.props';

const AgentSettingsModal = (props: AgentSettingsModalProps) => {
  const { onSubmit, onClose, settings, open } = props;

  console.log('AgentSettingsModal with agent.settings', settings);

  const { control, handleSubmit } = useForm<AgentSettings>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      agent_id: settings?.agent_id ?? '',
      language: settings.language ?? 'en',
      enable_recording: settings?.enable_recording ?? false,
      is_hipaa: settings?.is_hipaa ?? false,
      org: settings?.org ?? '',
      bot_starts_speaking: settings?.bot_starts_speaking ?? false
    }
  });

  const handleClose = useCallback(() => {
    handleSubmit(onSubmit)();
    onClose();
  }, [handleSubmit, onClose, onSubmit]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Edit Node</DialogTitle>
      <DialogContent>
        <Box className="w-full mt-4">
          <form className="flex flex-col gap-4">
            <Grid container className="gap-4 flex flex-col">
              <Grid item xs={12} className="flex flex-1">
                <Controller
                  control={control}
                  name="org"
                  rules={{ required: false }}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <TextField
                      className="flex-1"
                      name={name}
                      value={value}
                      onChange={onChange}
                      label="Reference"
                      ref={ref}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} className="flex flex-1">
                <Controller
                  control={control}
                  name="language"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="language-select-label">Language</InputLabel>
                      <Select
                        labelId="language-select-label"
                        label="Language"
                        {...field}
                        value={field.value}
                        onChange={field.onChange}
                        fullWidth
                      >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="es">Spanish</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} className="flex flex-1">
                <Controller
                  control={control}
                  name="bot_starts_speaking"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked={settings?.bot_starts_speaking}
                            onChange={field.onChange}
                          />
                        }
                        label="Bot starts speaking"
                      />
                    </FormGroup>
                  )}
                />
              </Grid>

              <Grid item xs={12} className="flex flex-1">
                <Controller
                  control={control}
                  name="enable_recording"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            defaultChecked={settings?.enable_recording}
                            onChange={field.onChange}
                          />
                        }
                        label="Enable Recording"
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12} className="flex flex-1">
                <Controller
                  control={control}
                  name="is_hipaa"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch defaultChecked={settings?.is_hipaa} onChange={field.onChange} />
                        }
                        label="Is Hipaa"
                      />
                    </FormGroup>
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AgentSettingsModal;
