import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import { SimpleTableProps } from './SimpleTable.props';
import { customAlphabet } from 'nanoid';
import clsx from 'clsx';

const nanoid = customAlphabet('123456789', 16);
const SimpleTable = <T,>({
  columns,
  header,
  rows,
  tableProps,
  tableContainerProps,
  tableHeaderCellProps,
  tableRowProps,
  tableBodyCellProps,
  onRowClick,
  showRowNumber = false
}: Readonly<SimpleTableProps<T>>): React.ReactElement => {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      {...tableContainerProps}
      className="rounded-none overflow-x-auto"
    >
      <Table {...tableProps}>
        <TableHead>
          {header}
          <TableRow {...tableRowProps}>
            {/* Render table headers based on columns */}
            {showRowNumber && (
              <TableCell align="left" className="text-gray2">
                #
              </TableCell>
            )}
            {columns.map((column, idx) => (
              <TableCell
                align="left"
                key={idx}
                {...column.cellProps}
                className={clsx(column.cellProps?.className, 'text-gray2 bg-white2')}
                {...tableHeaderCellProps}
              >
                <div className="flex items-center">
                  <label
                    className={clsx(
                      column.hasInfo && 'underline decoration-dotted',
                      '!capitalize text-sm leading-5 text-gray8'
                    )}
                  >
                    {column.label}
                  </label>
                  {column.component && column.component()}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Render table rows based on rows */}
          {rows.map((row, idx) => {
            return (
              <TableRow
                key={nanoid()}
                {...tableRowProps}
                onClick={() => onRowClick && onRowClick(row)}
                className="hover:bg-white2 h-[3.75rem]"
              >
                {showRowNumber && (
                  <TableCell
                    align="left"
                    className="text-black2 font-manrope h-4"
                    {...tableBodyCellProps}
                  >
                    {idx + 1}
                  </TableCell>
                )}
                {/* Render cells based on columns */}
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align="left"
                    {...column.cellProps}
                    className="text-black2 font-manrope font-normal text-sm leading-6"
                    {...tableBodyCellProps}
                  >
                    {column.renderer ? column.renderer(row) : (row[column.id as keyof T] as any)}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
