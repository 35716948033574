import { Chip } from '@mui/material';
import { NodeWrapper } from 'components/organisms';
import { NodeProps } from 'reactflow';
import { TransferCallNodeProps } from './TransferCallNode.props';

export function TransferCallNode(props: NodeProps<TransferCallNodeProps>) {
  return (
    <NodeWrapper nodeProps={props}>
      {props.data.transferCallNumber && <Chip label={props.data.transferCallNumber}></Chip>}
    </NodeWrapper>
  );
}
