import { Grid, Paper } from '@mui/material';
import { PageHeader } from 'components/atoms';
import InnerPageTemplateProps from './InnerPageTemplate.props';
import clsx from 'clsx';

const InnerPageTemplate = ({
  children,
  header,
  headerRightComponent,
  headerFilter,
  gaps = true,
  noPadding = false
}: InnerPageTemplateProps) => {
  return (
    <Grid container>
      <Paper
        elevation={2}
        className={clsx('w-full h-[calc(100vh_-_2rem)] pt-4 rounded-t-xl', noPadding && 'p-0')}
      >
        <Grid item xs={12}>
          <Grid container>
            {header && (
              <Grid item xs={6} className={clsx(noPadding && 'pl-6')}>
                <PageHeader title={header} />
              </Grid>
            )}
            {headerRightComponent && (
              <Grid item xs={6} className="flex justify-end">
                {headerRightComponent}
              </Grid>
            )}
          </Grid>
          {headerFilter && (
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              className={clsx(gaps && 'pl-14')}
              paddingTop="1.5rem"
              paddingBottom="1.5rem"
              justifyContent="space-between"
            >
              {headerFilter}
            </Grid>
          )}

          {children}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default InnerPageTemplate;
