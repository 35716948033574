import { SvgIcon, SvgIconProps } from '@mui/material';

function ProsperIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1_1046)">
        <path
          d="M0.046875 25.4995V5.2998L7.28163 9.81746V21.0857L0.046875 25.4995Z"
          fill="#141414"
        />
        <path
          d="M16.3246 11.1684V0.575195L9.08984 4.62553V15.4784L16.3246 11.1684Z"
          fill="#FF421C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_1046">
          <rect width="25" height="25" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ProsperIcon;
