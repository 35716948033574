import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React, { createContext, useState } from 'react';

type ContentDialogProviderProps = {
  children: React.ReactNode;
};

export type ContentDialogContextType = {
  openDialog: (dialogTitle: string, content: React.ReactNode) => void;
};

const initialState: ContentDialogContextType = {
  openDialog: () => {}
};

export const ContentDialogContext = createContext<ContentDialogContextType>(initialState);

export const ContentDialogProvider = ({ children }: ContentDialogProviderProps) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<React.ReactNode>();

  const openDialog = (dialogTitle: string, content: React.ReactNode) => {
    setTitle(dialogTitle);
    setContent(content);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <ContentDialogContext.Provider value={{ openDialog }}>
      {children}
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </ContentDialogContext.Provider>
  );
};
