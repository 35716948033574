import CallEndIcon from '@mui/icons-material/CallEnd';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import NodeIconProps from './NodeIcon.props';
import { CallIcon } from 'components/atoms';

const NodeIcon = ({ nodeType }: NodeIconProps) => {
  switch (nodeType) {
    case 'Default':
      return <CallIcon fontSize="small" />;
    case 'End Call':
      return <CallEndIcon fontSize="small" color="error" />;
    case 'Knowledge Base':
      return <LibraryBooksIcon fontSize="small" className="text-gray12" />;
    case 'Transfer Call':
      return <CallSplitIcon fontSize="small" className="text-gray12" />;
    case 'Wait For Response':
      return <HourglassEmptyIcon fontSize="small" className="text-gray12" />;
    case 'Function Call':
      return <ElectricBoltIcon fontSize="small" className="text-gray12" />;
    case 'DTMF':
      return <MultipleStopIcon fontSize="small" className="text-gray12" />;
    case 'Transfer Pathway Node':
      return <MoveUpIcon fontSize="small" className="text-gray12" />;
    default:
      return <></>;
  }
};

export default NodeIcon;
