// PrivateRoute.tsx
import { useUser } from 'hooks/reducers';
import React, { ReactNode, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import ROUTE_PATHS from './paths';
import { supabaseClient } from 'api/supabaseClient';

type PrivateRouteProps = {
  children: ReactNode;
};

const PrivateRoute = ({ children }: PrivateRouteProps): React.ReactElement => {
  const { session } = useUser();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.hash);
  const accessToken = urlParams.get('#access_token');
  const refreshToken = urlParams.get('refresh_token');

  useEffect(() => {
    if (accessToken && refreshToken) {
      supabaseClient.auth.setSession({
        access_token: accessToken,
        refresh_token: refreshToken
      });

      navigate(ROUTE_PATHS.AUTH);
    }
  }, [accessToken, refreshToken, navigate]);

  if (!session) return <Navigate to={ROUTE_PATHS.AUTH} replace />;

  return <>{children}</>;
};

export default PrivateRoute;
