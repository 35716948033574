import {
  ConfirmationDialogContext,
  ConfirmationDialogContextType
} from 'contexts/ConfirmationDialogContext';
import { useContext } from 'react';

const useConfirmationDialog = (): ConfirmationDialogContextType =>
  useContext(ConfirmationDialogContext);

export default useConfirmationDialog;
