import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { useCategoriesService } from 'hooks';
import { useUser } from 'hooks/reducers';
import { useQuery } from 'react-query';
import { forwardRef, useEffect, useState } from 'react';
import { Category } from 'types/models';

type CategorySelectProps = SelectProps & {
  allowEmptyValue: boolean;
  emptyValue?: string;
  showLabel?: boolean;
};

const CategorySelect = forwardRef<HTMLSelectElement, CategorySelectProps>(
  ({ allowEmptyValue, emptyValue, showLabel, ...rest }: CategorySelectProps) => {
    const { user } = useUser();
    const { getMany: getCategories } = useCategoriesService();
    const [categories, setCategories] = useState<Category[]>([]);

    const query = useQuery(['categories', user?.user_metadata.organizationId], getCategories);

    useEffect(() => {
      if (!query.isLoading && query.data?.length) {
        setCategories(query.data);
      }
    }, [query]);

    if (!categories)
      return (
        <Select disabled fullWidth>
          <MenuItem>Loading...</MenuItem>;
        </Select>
      );

    const defaultValue = emptyValue ?? 'all';

    return (
      <>
        {categories.length > 0 && (
          <FormControl fullWidth>
            {showLabel && <InputLabel className="top-[-4px]">Category</InputLabel>}
            <Select
              label={showLabel ? 'Category' : undefined}
              defaultValue={allowEmptyValue ? defaultValue : categories[0].id}
              {...rest}
            >
              {allowEmptyValue && (
                <MenuItem value={defaultValue} className="capitalize">
                  {defaultValue}
                </MenuItem>
              )}
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id} className="capitalize">
                  {category.parentName && `${category.parentName} - ${category.category}`}
                  {!category.parentName && (
                    <span className="font-bold text-md">{category.category}</span>
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </>
    );
  }
);

export default CategorySelect;
