import { KBB_SCHEMA, supabaseClient } from 'api/supabaseClient';
import { useCallback } from 'react';
import { AuthUser } from 'types/models';
import { Tables } from 'types/models/db.type';
import { APIService } from 'types/services';
import { BaseAdapter } from '../adapters';

type UsersService = Omit<APIService<AuthUser>, 'getMany' | 'post' | 'put'>;

const useUsersService = (): UsersService => {
  const { adapt } = BaseAdapter<Tables<'profiles'>, AuthUser>();
  const { PROFILES_TABLE } = KBB_SCHEMA;

  const get = useCallback(
    async ({ userId }: { userId: string }) => {
      let query = supabaseClient
        .from(PROFILES_TABLE)
        .select('*, organizations(*)')
        .eq('id', userId);

      const { data, error } = await query.single<Tables<'profiles'>>();

      if (error) console.error(error);

      if (!data) return;

      return adapt(data);
    },
    [adapt, PROFILES_TABLE]
  );

  return { get };
};

export default useUsersService;
