import { FilterList } from '@mui/icons-material';
import {
  Badge,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select
} from '@mui/material';
import { DateRangeField } from 'components/molecules';
import { useUser } from 'hooks/reducers';
import useEvaluationsService from 'hooks/services/evaluations/use-evaluations.service';
import useTagsService from 'hooks/services/tagger/tags.service';
import { useState } from 'react';
import { RangeKeyDict } from 'react-date-range';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { EvaluationsFilter } from 'types';
import { Score, Sort, Status } from 'types/services/evaluations-filter.type';

type EvaluationsFilterOptionsProps = {
  handleFilterChange: (field: keyof EvaluationsFilter, value: string | string[]) => void;
  handleDateChange: (range: RangeKeyDict | undefined) => void;
  handlePeriodChange: (value: string | undefined) => void;
  filter: EvaluationsFilter;
};

const EvaluationsFilterOptions = ({
  handleFilterChange,
  handleDateChange,
  filter,
  handlePeriodChange
}: EvaluationsFilterOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const location = useLocation();
  const { user } = useUser();
  const { getMany, deleteItem, put } = useTagsService();
  const { getInboxes } = useEvaluationsService();

  const { data: tags, refetch } = useQuery({
    queryKey: ['tags', { filter: '', organizationId: user?.user_metadata.organizationId }],
    queryFn: getMany,
    enabled: !!user?.user_metadata.organizationId
  });

  const inboxQuery = useQuery({
    queryKey: ['inboxes', { organizationId: user?.user_metadata.organizationId }],
    queryFn: getInboxes,
    enabled: !!user?.user_metadata.organizationId
  });

  const searchParams = new URLSearchParams(location.search);
  const ignoredParams = ['id', 'sort'];
  const filteredParams = Array.from(searchParams.keys()).filter(
    (key) => !ignoredParams.includes(key)
  );
  const numFilters = filteredParams.length;

  return (
    <>
      <div className="flex items-center">
        <Badge
          badgeContent={numFilters}
          color="primary"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <FilterList
            className="h-6 w-6 hover:text-orange cursor-pointer"
            onClick={(e) => setAnchorEl(e?.currentTarget)}
          />
        </Badge>
      </div>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={() => setAnchorEl(null)}
      >
        <div className="flex flex-col p-4">
          <h3 className="text-xs pb-2">Filter by</h3>
          <div className="flex gap-4 flex-col">
            <FormControl className="w-full">
              <InputLabel id="status-filter">Inbox</InputLabel>
              <Select
                labelId="inbox-filter"
                label="inbox"
                className="w-full"
                onChange={(e) => handleFilterChange('inbox', e.target.value as string)}
                value={filter.inbox}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {inboxQuery?.data?.data?.map((value: any) => (
                  <MenuItem key={value.inbox} value={value.inbox}>
                    {value.inbox}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="w-full">
              <InputLabel id="similarity-filter">Score</InputLabel>
              <Select
                labelId="similarity-filter"
                label="score"
                className="w-full"
                onChange={(e) => handleFilterChange('score', e.target.value as string)}
                value={filter.score}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {Object.keys(Score).map((key) => (
                  <MenuItem key={key} value={Score[key as keyof typeof Score]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DateRangeField
              periodValue={filter.period}
              rangeValue={
                filter.startDate && filter.endDate
                  ? {
                      selection: {
                        startDate: filter.startDate,
                        endDate: filter.endDate,
                        key: 'selection'
                      } as RangeKeyDict
                    }
                  : undefined
              }
              onPeriodChange={handlePeriodChange}
              onRangeChange={handleDateChange}
            />
            <FormControl className="w-full">
              <InputLabel id="status-filter">Status</InputLabel>
              <Select
                labelId="status-filter"
                label="status"
                className="w-full"
                onChange={(e) => handleFilterChange('reviewed', e.target.value as string)}
                value={filter.reviewed}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {Object.keys(Status).map((key) => (
                  <MenuItem key={key} value={Status[key as keyof typeof Status]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* TODO: Implement tags filter */}
            {tags && (
              <FormControl className="max-w-[14rem]">
                <InputLabel id="tags-filter">Tags</InputLabel>
                <Select
                  labelId="tags-filter"
                  label="tags"
                  className="max-w-full w-full"
                  multiple
                  onChange={(e) => handleFilterChange('tags', e.target.value as string[])}
                  value={filter.tags}
                >
                  {tags?.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id.toString()}>
                      {tag.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          <h3 className="text-xs">Sort by</h3>
          <FormControl>
            <RadioGroup
              row
              aria-label="sort-by"
              name="sort-by"
              onChange={(e) => handleFilterChange('sort', e.target.value as string)}
              defaultValue={'desc'}
            >
              {Object.keys(Sort).map((key) => (
                <FormControlLabel
                  key={key}
                  value={Sort[key as keyof typeof Sort]}
                  control={<Radio />}
                  label={<span className="text-xs">{key}</span>}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </Popover>
    </>
  );
};

export default EvaluationsFilterOptions;
