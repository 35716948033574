import { Drawer, Select, MenuItem, Button, TextField, Divider, Grid } from '@mui/material';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useUser } from 'hooks/reducers';
import useActionsService from 'hooks/services/actions/actions.service';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Action } from 'types/models';
import { ActionRequest } from 'types/models/action.model';
import { ActionsModalProps } from './ActionsModal.props';

const ActionsModal = ({ isOpen, action, onModalClose }: ActionsModalProps) => {
  const parameterModel = `{
  "type": "function",
  "function": {},
}`;

  const [actionData, setActionData] = useState<Action | null>();
  const [swaggerUrl, setSwaggerUrl] = useState('');

  const { get, post, put, postWithSwagger } = useActionsService();

  const { user } = useUser();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Action>({
    reValidateMode: 'onChange',
    defaultValues: {
      id: action ?? undefined,
      actionName: actionData?.actionName ?? '',
      actionDescription: actionData?.actionDescription ?? '',
      requestMethod: actionData?.requestMethod ?? 'get',
      requestEndpoint: actionData?.requestEndpoint ?? '',
      requestParameters: actionData?.requestParameters ?? parameterModel,
      organizationId: user?.user_metadata.organizationId
    }
  });

  const {
    control: swaggerControl,
    handleSubmit: handleSwaggerSubmit,
    reset: resetSwagger,
    formState: { errors: swaggerURLError }
  } = useForm<{ swaggerUrl: string }>({
    reValidateMode: 'onChange',
    defaultValues: { swaggerUrl: '' }
  });

  const fetchData = useCallback(async () => {
    if (action) {
      const data = await get({ id: action });
      data && setActionData(data);
      reset({
        id: action ?? undefined,
        actionName: data?.actionName ?? '',
        actionDescription: data?.actionDescription ?? '',
        requestMethod: data?.requestMethod ?? 'get',
        requestEndpoint: data?.requestEndpoint ?? '',
        requestParameters: data?.requestParameters ?? parameterModel,
        organizationId: user?.user_metadata.organizationId
      });
    } else {
      reset({
        id: action ?? undefined,
        actionName: '',
        actionDescription: '',
        requestMethod: 'get',
        requestEndpoint: '',
        requestParameters: parameterModel,
        organizationId: user?.user_metadata.organizationId
      });
    }
  }, [action, get, parameterModel, reset, setActionData, user?.user_metadata.organizationId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleModalClose = useCallback(() => {
    setActionData(null);
    setSwaggerUrl('');
    onModalClose();
    reset();
    resetSwagger();
  }, [onModalClose, reset, resetSwagger]);

  const postMutation = useMutation({
    mutationKey: 'postAction',
    mutationFn: (action: ActionRequest) => post(action),
    onSuccess: (data: void | Action[] | undefined) => {
      if (data) {
        handleModalClose();
      }
    }
  });

  const editMutation = useMutation({
    mutationKey: 'editFAQ',
    mutationFn: (action: Action) => put(action),
    onSuccess: (data: void | Action[] | undefined) => {
      if (data) {
        handleModalClose();
      }
    }
  });

  const onSubmit: SubmitHandler<Action> = useCallback(
    (data) => {
      if (action) {
        editMutation.mutate(data);
      } else {
        postMutation.mutate(data);
      }
    },
    [action, editMutation, postMutation]
  );

  const onSwaggerImport: SubmitHandler<{ swaggerUrl: string }> = useCallback(
    async (data) => {
      console.log(data);
      await postWithSwagger(data.swaggerUrl, user?.user_metadata.organizationId);
      setTimeout(handleModalClose, 2000);
    },
    [handleModalClose, postWithSwagger, user?.user_metadata.organizationId]
  );

  return (
    <Drawer
      className="z-[1000] flex"
      anchor="right"
      open={isOpen}
      onClose={handleModalClose}
      PaperProps={{ className: 'w-[30rem] p-4', style: { maxWidth: '20%' } }}
      sx={{ img: { maxWidth: '100%' } }}
    >
      <div className="flex flex-col gap-4">
        <h1 className="text-xl font-bold">Add Action</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <Grid container className="gap-4 flex flex-row">
            <Grid item xs={12} className="flex flex-col">
              <Controller
                control={control}
                name="actionName"
                rules={{ required: true }}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    className="flex-1"
                    name={name}
                    value={value}
                    onChange={onChange}
                    label="Action Name"
                    ref={ref}
                  />
                )}
              />
              {errors.actionName && <span className="text-red2">Action name is required</span>}
            </Grid>
            <Grid item xs={12} className="flex flex-col">
              <Controller
                control={control}
                name="actionDescription"
                rules={{ required: true }}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label="Description"
                    name={name}
                    fullWidth
                    value={value || ''}
                    onChange={onChange}
                    ref={ref}
                    multiline
                  />
                )}
              />
              {errors.actionDescription && (
                <span className="text-red2">Description is required</span>
              )}
            </Grid>
            <div className="flex gap-2">
              <Grid item className="flex flex-col">
                <Controller
                  control={control}
                  name="requestMethod"
                  render={({ field }) => (
                    <Select {...field} value={field.value.toLowerCase()}>
                      <MenuItem value="get">GET</MenuItem>
                      <MenuItem value="post">POST</MenuItem>
                    </Select>
                  )}
                />
              </Grid>
              <Grid item className="flex flex-col">
                <Controller
                  control={control}
                  name="requestEndpoint"
                  rules={{ required: true }}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <TextField
                      name={name}
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      label="Endpoint"
                      className="flex-1"
                    />
                  )}
                />
                {errors.requestEndpoint && <span className="text-red2">Endpoint is required</span>}
              </Grid>
            </div>
            <Grid item xs={12} className="flex flex-col">
              <Controller
                control={control}
                name="requestParameters"
                rules={{ required: true }}
                render={({ field }) => (
                  <CodeEditor
                    {...field}
                    language="js"
                    placeholder="Please enter JSON code."
                    padding={15}
                    data-color-mode="light"
                    className="bg-gray3 text-black min-h-40 w-full rounded-md mb-4"
                  />
                )}
              />
              {errors.requestParameters && (
                <span className="text-red2">Request parameters are required</span>
              )}
            </Grid>
            <Grid item xs={12} className="flex">
              <Button className="flex-1" type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <Divider className="mt-4">OR</Divider>
        <div className="flex flex-col gap-4">
          <h3 className="text-sm font-bold">Swagger</h3>
          <form onSubmit={handleSwaggerSubmit(onSwaggerImport)} className="flex flex-col gap-4">
            <Controller
              control={swaggerControl}
              name="swaggerUrl"
              rules={{
                pattern: {
                  value: /^(http|https):\/\//,
                  message: 'URL must start with http:// or https://'
                }
              }}
              render={({ field: { name, value, onChange, ref } }) => (
                <TextField
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  label="Swagger URL"
                />
              )}
            />
            {swaggerURLError.swaggerUrl && (
              <span className="text-red2">{swaggerURLError.swaggerUrl.message?.toString()}</span>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="bg-white text-black gap-4 mt-4"
            >
              <>
                <img src={'/swagger192.png'} alt="Jira" className="w-4 h-4" />
                Import Swagger
              </>
            </Button>
          </form>
        </div>
      </div>
    </Drawer>
  );
};

export default ActionsModal;
