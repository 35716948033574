import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useCallback, useState } from 'react';

interface GeneralInstructionsModalProps {
  value: string;
  open: boolean;
  onClose: (value: string) => void;
}

const GeneralInstructionsModal = ({ value, open, onClose }: GeneralInstructionsModalProps) => {
  const [instructions, setInstructions] = useState<string>(value);

  const handleClose = useCallback(() => {
    onClose(instructions);
  }, [instructions, onClose]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>General Instructions</DialogTitle>
      <DialogContent>
        <Box className="w-full mt-4">
          <form className="flex flex-col gap-4">
            <Grid container className="gap-4 flex flex-col">
              <Grid item xs={12} className="flex flex-1">
                <Alert severity="info">
                  These instructions will be added to the beginning of all nodes. This serves as
                  general context for the agent in generating the dialogue, or guidance for the type
                  of speech to generate.
                </Alert>
              </Grid>
              <Grid item xs={12} className="flex flex-1">
                <TextField
                  className="flex-1"
                  multiline
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                  label={'Instructions Body'}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GeneralInstructionsModal;
