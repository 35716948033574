const ROUTE_PATHS = {
  AUTH: '/auth',
  EMAIL_CONFIRMATION: '/email-confirmation',
  PASSWORD_RESET: '/password-reset',
  CONVERSATIONS_PAGE: '/conversations',
  OPPORTUNITIES_PAGE: '/opportunities',
  OPPORTUNITY_DETAILS_PAGE: (questionId: string | undefined = ':questionId'): string =>
    `/opportunities/${questionId}`,
  FAQ_ARTICLE_PAGE: (faqId: string | undefined = ':faqId'): string => `/faqs/${faqId}/snippet`,
  JOURNEY_LIST_PAGE: '/journeys',
  FAQ_LIST_PAGE: '/content/faqs',
  FAQ_DETAILS_PAGE: (faqId: string | undefined = ':faqId'): string => `/content/faqs/${faqId}`,
  EVALUATOR: '/evaluator',
  EVALUATOR_DETAILS: (id: string | undefined = ':id'): string => `/evaluator?id=${id}`,
  SOURCES: '/',
  SOURCES_CALLBACK: '/sources/callback',
  // TODO: implement suggested articles
  // SUGGESTED_ARTICLES: '/suggested-articles',
  TAGS: '/tags',
  ACTIONS: '/actions',
  EVALUATIONS: '/evaluations',
  ANALYTICS: '/analytics',
  SETTINGS: '/settings',
  CATEGORIES: '/categories',
  SLACK_AUTHORIZED: '/slack-authorized',
  PHONE_NUMBERS: '/phone-numbers',
  AGENTS: '/agents',
  PATHWAY: '/pathway',
  CALL_LOGS: '/call-logs',
  AGENT_PERFORMANCE: (agentId: string | undefined = ':agentId'): string =>
    `/agents/${agentId}/performance`
};

export default ROUTE_PATHS;
