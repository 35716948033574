import { Theme } from '@mui/material/styles';
import { baseColors } from 'theme/palette';

export default function Tabs(theme: Theme): object {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          color: baseColors.gray8,
          '& .MuiTabs-indicator': {
            backgroundColor: `${baseColors.black} !important`
          }
        },
        vertical: {}
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: baseColors.black,
            borderBottom: `1px solid ${baseColors.black}`
          }
        },
        labelIcon: {},
        wrapper: {}
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {}
      }
    },
    MuiTabScrollButton: {}
  };
}
