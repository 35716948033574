import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Check } from '@mui/icons-material';
import useSourcesService from 'hooks/services/sources-service/sources.service';
import { useToast } from 'contexts/ToastContext';
import { useUser } from 'hooks/reducers';

const GoogleDriveForm = ({ handleClose }: { handleClose: () => void }) => {
  const [token, setToken] = useState({
    connectionId: '',
    providerConfigKey: ''
  });
  const [folders, setFolders] = useState<string[]>(['']);
  const [error, setError] = useState(false);

  const { showToast } = useToast();

  const { authWithSSO: authWithGoogle, submitSource } = useSourcesService();

  const { user } = useUser();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (token.connectionId === '') {
      setError(true);
      return;
    }
    const folderIds = folders
      .filter((folder) => folder.trim() !== '')
      .map((folder) => {
        const folderUrlParts = folder.split('/');
        return folderUrlParts[folderUrlParts.length - 1];
      });
    try {
      const metadata = {
        folders: [folderIds]
      };
      submitSource(metadata, token.connectionId, token.providerConfigKey);
      setToken((prevState) => ({
        ...prevState,
        providerConfigKey: '',
        connectionId: ''
      }));
      showToast('Source successfully added', 'success');
      handleClose();
    } catch (error) {
      showToast('Error adding source', 'error');
    }
  };

  const handleAddFolder = () => {
    setFolders((prevFolders) => [...prevFolders, '']);
  };

  const handleFolderChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
    const { value } = event.target;
    setFolders((prevFolders) => {
      const updatedFolders = [...prevFolders];
      updatedFolders[index] = value;
      return updatedFolders;
    });
  };

  return (
    <div>
      <h3 className="text-base font-bold">Google Drive</h3>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8">
          <Button
            variant="contained"
            className="bg-white text-black gap-4"
            onClick={() =>
              authWithGoogle('google-drive', `${user?.user_metadata.organizationId!}`).then(
                (response) => {
                  if (response) setToken(response!);
                }
              )
            }
            disabled={token.connectionId !== '' ? true : false}
          >
            {token.connectionId === '' ? (
              <>
                <img src={'/google192.png'} alt="Google Drive" className="w-4 h-4" />
                Connect Google Drive
              </>
            ) : (
              <>
                <img src={'/google192.png'} alt="Google Drive" className="w-4 h-4" />
                Connected
                <Check className="text-sm text-orange" />
              </>
            )}
          </Button>
          <div>
            {folders.map((folder, index) => (
              <div key={index} className="flex items-center mb-4">
                <TextField
                  id={`driveUrl-${index}`}
                  label="Folder URL"
                  variant="outlined"
                  required={index === 0}
                  value={folder}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleFolderChange(event, index)
                  }
                  className="w-full"
                />
              </div>
            ))}
            <span className="text-xs text-gray cursor-pointer" onClick={handleAddFolder}>
              Add new folder
            </span>
          </div>

          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          {error && <p className="text-orange text-sm m-0">Please connect Google Drive first</p>}
        </div>
      </form>
      <div className="pt-8">
        <h3 className="text-base text-orange font-bold">Instructions</h3>
        <ol>
          <li>
            Authenticate your Google Drive account clicking on the button "Connect Google Drive".
          </li>
          <li>
            Create a new folder or select an existing folder where you want to add as a source.
          </li>
          <li>Copy the URL of the folder.</li>
          <li>Paste the URL into the "Folder URL" field above.</li>
          <li>Click the "Submit" button to add Google Drive as a source.</li>
        </ol>
      </div>
    </div>
  );
};

export default GoogleDriveForm;
