import { useCallback } from 'react';

const useDebounce = () => {
  const debounce = useCallback((fn: Function, ms = 300) => {
    let timeoutId: NodeJS.Timeout;
    return function (this: any, ...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
  }, []);

  return { debounce };
};

export default useDebounce;
