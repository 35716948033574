import { useCallback, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { useUser } from 'hooks/reducers';
import { supabaseClient } from 'api/supabaseClient';

const SlackSource = () => {
  const { user } = useUser();
  const [userAlredySignUp, setUserAlreadySignUp] = useState<boolean>(false);
  const [authUrl, setAuthUrl] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SLACK_HANDLER_URL}/authorize`);
        const { authorization_url } = await response.json();
        setAuthUrl(authorization_url);
      } catch (error) {
        console.error('Error fetching authorize URL:', error);
      }
    };

    fetchData();
  }, []);

  const checkSlackAuth = useCallback(async () => {
    const { data } = await supabaseClient
      .from('slack_organizations')
      .select()
      .eq('organization_id', user?.user_metadata.organizationId)
      .single();
    return Boolean(data);
  }, [user?.user_metadata.organizationId]);

  const handleInstallClick = async () => {
    authUrl && window.open(authUrl, '_blank');
  };

  useEffect(() => {
    const checkSlackConnection = async () => {
      const hasSlack = await checkSlackAuth();
      setUserAlreadySignUp(hasSlack);
    };

    checkSlackConnection();
  }, [checkSlackAuth]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bgcolor="rgba(255, 255, 255, 0.8)"
    >
      <div className="flex flex-col text-gray">
        <h3>Welcome to the Prosper Platform:</h3>
        <ol className="w-[32rem] px-4 text-gray">
          <li> Install the Prosper App in your Slack Workspace by clicking on the button below</li>
          <li>
            Add the Prosper Bot to the channels you want Prosper to capture Q&A’s for future
            reference
          </li>
        </ol>
        <Button
          variant="contained"
          className="bg-white text-black gap-4 w-64 self-center mt-8"
          onClick={handleInstallClick}
          disabled={userAlredySignUp === true}
        >
          <>
            <img src={'/slack192.png'} alt="Slack" className="w-4 h-4" />
            {userAlredySignUp ? 'Connected' : 'Connect Slack'}
          </>
        </Button>
      </div>
    </Box>
  );
};

export default SlackSource;
