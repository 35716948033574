import { Box, Container, Grid } from '@mui/material';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { Session } from '@supabase/supabase-js';
import { supabaseClient } from 'api/supabaseClient';
import { LogoIcon } from 'components/atoms';
import { UserActionTypes } from 'contexts/reducers';
import { useUserService } from 'hooks';
import { useUserDispatch } from 'hooks/reducers';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATHS from 'routes/paths';
import { baseColors } from 'theme/palette';
import { AuthUser } from 'types/models';

const AuthPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const { get } = useUserService();
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabaseClient.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription }
    } = supabaseClient.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const checkSessionAndRedirect = async () => {
      if (!session) return;
      const userData: AuthUser | undefined = await get({ userId: session.user?.id });

      if (session && userData) {
        const userSession = {
          session: session,
          user: { ...session.user, user_metadata: userData }
        };
        dispatch({
          type: UserActionTypes.UpdateUserSession,
          payload: userSession
        });

        localStorage.setItem('kbbSession', JSON.stringify(userSession));

        if (userSession.user.user_metadata.organizations.voiceEnabled) {
          navigate(ROUTE_PATHS.AGENTS);
        } else {
          navigate(ROUTE_PATHS.SOURCES);
        }
      }
    };

    session && checkSessionAndRedirect();
  }, [session, dispatch, navigate, get]);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <Box className="w-full text-center mt-[25vh]">
            <LogoIcon sx={{ width: '10rem', height: '3rem' }} />
          </Box>
          <Auth
            supabaseClient={supabaseClient}
            appearance={{
              theme: ThemeSupa,
              className: {
                label: 'font-manrope text-black',
                anchor: 'font-manrope text-black',
                button: 'font-manrope text-black uppercase'
              },
              variables: {
                default: {
                  colors: {
                    brand: baseColors.orange,
                    brandAccent: baseColors.orange2
                  }
                }
              }
            }}
            providers={[]}
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </Container>
  );
};

export default AuthPage;
