import { Chip } from '@mui/material';
import { NodeWrapper } from 'components/organisms';
import { NodeProps } from 'reactflow';
import { FunctionCallNodeProps } from './FunctionCallNode.props';

export function FunctionCallNode(props: NodeProps<FunctionCallNodeProps>) {
  return (
    <NodeWrapper nodeProps={props}>
      {props.data.functionName && <Chip label={props.data.functionName}></Chip>}
    </NodeWrapper>
  );
}
