import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import 'react-quill/dist/quill.snow.css';

const BoxQuillEditorStyle = styled(Box)(({ theme }) => ({
  '& .quill > .ql-container.ql-snow': {
    border: 'none'
  },
  '& .ql-snow': {
    wordBreak: 'break-word',
    '& .ql-editor': {
      minHeight: '100%',
      ...theme.typography.body1,
      '&::before': {
        ...theme.typography.body1,
        color: theme.palette.grey[500],
        fontStyle: 'normal'
      }
    },
    '& .ql-tooltip': {
      left: 'unset !important',
      zIndex: theme.zIndex.tooltip
    }
  }
}));

export default BoxQuillEditorStyle;
