import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import { useAgentsService } from 'hooks';
import { useCallback, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from 'reactflow';

const CustomEdge = ({
  id,
  label,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  source,
  target,
  style = {},
  markerEnd,
  selected
}: EdgeProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [state, setState] = useState<string>(label as string);
  const initialState = useRef(state);
  const flow = useReactFlow();
  const [searchParams] = useSearchParams();

  const { deleteNodesAndEdges, updateEdge } = useAgentsService();

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  const handleSubmit = useCallback(async () => {
    const pathwayId = searchParams.get('versionId');

    if (!pathwayId || !id || state?.length === 0) return;

    initialState.current = state;

    flow.setEdges((edges) =>
      edges.map((edge) => {
        if (edge.id !== id) {
          return {
            ...edge,
            label: state
          };
        } else {
          return edge;
        }
      })
    );
    await updateEdge({ pathwayId, edge: { id, source, target, label: state } });
    setEditing(false);
    console.log('edge edited', { id: parseInt(id), source, target, label: state });
  }, [flow, id, source, target, state, searchParams, updateEdge]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
      if (e.key === 'Escape') {
        e.stopPropagation();
        setState(initialState.current);
        setEditing(false);
      }
    },
    [handleSubmit]
  );

  const handleDelete = useCallback(async () => {
    const pathwayId = searchParams.get('versionId');

    if (!pathwayId) return;

    const edgesToDelete = flow
      .getEdges()
      ?.filter((e) => e.source === source && e.target === target);

    flow.deleteElements({ edges: edgesToDelete });

    await deleteNodesAndEdges({
      pathwayId,
      edgeIds: edgesToDelete?.map((e) => parseInt(e.id))
    });
  }, [deleteNodesAndEdges, flow, source, target, searchParams]);

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} label={label} />
      <EdgeLabelRenderer>
        {(state?.length > 0 || initialState.current?.length > 0 || selected) && (
          <Stack
            direction={editing ? 'column' : 'row'}
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: 'all'
            }}
            className={clsx(
              'absolute flex items-center justify-around gap-2 bg-sky-blue2 rounded-md',
              editing && 'p-1'
            )}
          >
            {editing ? (
              <>
                <TextField
                  multiline
                  className="bg-white rounded-md"
                  value={state}
                  inputProps={{ className: '!min-h-[1.25rem]' }}
                  onChange={(e) => setState(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                  Save
                </Button>
              </>
            ) : (
              <>
                <Typography className="ml-1">{state}</Typography>
                <IconButton onClick={() => setEditing(true)} className="!p-1">
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={handleDelete}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </Stack>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
