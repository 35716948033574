import { outlinedInputClasses, Theme } from '@mui/material';

export default function TextField(theme: Theme): Object {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fieldset: {
            borderRadius: '.5rem'
          },
          [`&.${outlinedInputClasses.disabled}`]: {
            opacity: 0.5,
            [`& .${outlinedInputClasses.input}`]: {
              // color: theme.palette.grey[900],
              // WebkitTextFillColor: theme.palette.grey[900]
            },
            '& svg': {
              color: theme.palette.grey[900]
            }
          }
        }
      }
    }
  };
}
