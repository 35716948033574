import { Typography } from '@mui/material';
import PageHeaderProps from './PageHeader.props';

const PageHeader = ({ title, ...other }: PageHeaderProps) => (
  <Typography
    variant="h5"
    fontSize="1.25rem"
    lineHeight="1.875rem"
    fontWeight="bold"
    marginTop=".5rem"
    marginBottom=".5rem"
    {...other}
  >
    {title}
  </Typography>
);

export default PageHeader;
